.popup_container {
	position: fixed;
	bottom: 20px;
	right: 20px;
	width: 500px;
	z-index: 2000;
	height: 120px;
	background-color: #37d7a0;
	border-radius: 20px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	color: #fff;
	text-align: center;
	padding: 30px 0 0;
	cursor: pointer;
	transition: all 0.5s;
}
.popup_container > * {
	margin: 0;
	padding: 0;
}
.hide_popup_container {
	right: -530px;
	opacity: 0;
}
.popup_arrow_icon {
	position: absolute;
	right: 20px;
	top: 20px;
	width: 15px;
}
.popup_title {
	font-family: "Roboto Geo Caps";
	font-size: 16px;
	margin-bottom: 10px;
}
.popup_text {
	font-family: "Roboto Geo Nus";
	font-size: 13px;
	width: 86%;
	text-align: center;
	margin: 0 auto;
}
@media screen and (max-width: 500px) {
	.popup_container {
		bottom: 10px;
		right: 10px;
		width: 300px;
		height: 100px;
		text-align: center;
		padding: 25px 0 0;
	}
	.hide_popup_container {
		right: -320px;
	}
	.popup_arrow_icon {
		right: 10px;
		top: 10px;
		width: 12px;
	}
	.popup_title {
		font-size: 14px;
		margin-bottom: 1px;
	}
	.popup_text {
		font-size: 12px;
		width: 91%;
		line-height: 14px;
	}
}
