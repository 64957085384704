.LogOut_wrapper {
	width: 42px;
	height: 42px;
	border-radius: 50%;
	background-color: #fff;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	& > img {
		width: 24px;
	}

	@media screen and (max-width: 1024px) {
		width: 35px;
		height: 35px;

		& > img {
			width: 22px;
		}
	}
}
