.icon {
	width: 26px;
	height: 26px;
	background: no-repeat;
	background-size: contain;
	margin-right: 5px;
	color: #5272e4;
}
@media screen and (max-width: 1200px) {
	.icon {
		font-size: 26px;
	}
}
.childSwitchButton {
	font-family: "Roboto Geo Caps";
	border: 1px solid;
	border-radius: 12px;
	padding: 5px 15px;
	margin-left: 10px;
	transform: scaleY(1.1);
}
.childSwitchButton:focus {
	outline: none;
}
.schoolContainer {
	border-radius: 4px;
	padding: 0 5px;
	font-family: "Tatishvili Normal";
	font-size: 30px;
	height: 34px;
	line-height: 34px;
}
.popup {
	padding: 0px 40px;
}
.popup h1 {
	font-family: "Roboto Geo Caps";
	font-weight: bold;
	font-size: 18px;
	color: #626a84;
}
.popup .childrenContainer {
	margin: 30px 0px;
	text-align: left;
	display: inline-block;
}
.popup .childDiv {
	font-size: 18px;
	font-family: "Roboto Geo Caps";
	color: #5273e6;
	font-weight: 700;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	margin: 10px 0px;
	cursor: pointer;
	padding: 10px;
}
.popup .childDiv .icon {
	display: inline-block;
	width: 80px;
	height: 80px;
}
.popup button {
	width: 100%;
	height: 40px;
	background: #5273e6;
	border-radius: 10px;
	color: white;
	font-family: "Roboto Geo Caps";
	margin-top: 20px;
}
.popup button:hover {
	background: #344da7;
}

