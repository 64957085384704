.countDown {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #626a84;
	font-family: "Roboto Geo MT Bold";
}
.countDown .group {
	display: flex;
	align-items: center;
}
.countDown .group .number {
	font-size: 32px;
	font-weight: bold;
}
.countDown .separator {
	font-size: 32px;
	font-weight: bold;
}
