.certificateContainer {
	margin-top: 50px;
	display: inline-flex;
	justify-content: center;
	vertical-align: middle;
	cursor: pointer;
}
.certificateBox {
	display: inline-flex;
	vertical-align: top;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 261px;
	height: 188px;
	border-radius: 16px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	background-color: white;
}
.openCertificate,
.openCertificateStudent {
	margin-top: 20px;
	font-family: "Roboto Geo Caps";
	font-size: 18px;
	font-weight: bold;
	color: #5273e6;
}
.openCertificateStudent {
	margin-top: 16px;
}
