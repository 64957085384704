$bar-width: 320px;
$right-p: 10px;
$delay: 0.6s;
$green-color: #37d7a0;
$dark-gray: #626a84;
$light-gray: #d9dfef;
$blue-color: #5273e6;
$body-color: #eff1f7;
$thin-sidebar: 60px;
$extra-thin-sidebar: 40px;
$hover-color: rgba(0, 166, 255, 0.07);

.sidebar-placeholder {
	margin-right: 2px;
	width: $bar-width + 7px;
	border-right: 5px solid #d9dfef;
	transition: 0.5s;
	display: flex;
	justify-content: start;
	position: relative;
	top: 60px;
	bottom: 0;
	background-color: $body-color;
	flex-shrink: 0;

	@media screen and (max-width: 1500px) {
		width: $bar-width - 35px;
	}
	@media screen and (max-width: 800px) {
		width: $thin-sidebar;
		min-width: $thin-sidebar;
		border-right: 3px solid $light-gray;
	}
	@media screen and (max-width: 360px) {
		width: $extra-thin-sidebar;
		min-width: $extra-thin-sidebar;
	}

	&.collapsed {
		width: 94px;
		min-width: 94px;

		@media screen and (max-width: 800px) {
			width: $thin-sidebar;
			min-width: $thin-sidebar;
		}
		@media screen and (max-width: 360px) {
			width: $extra-thin-sidebar;
			min-width: $extra-thin-sidebar;
		}
	}
}

.responsive_sidebar_placeholder {
	width: 60px;
}

.collapserExpander {
	display: flex;
	position: fixed;
	top: 90px;
	left: 242px;
	z-index: 800;
	padding: 4px;
	border-radius: 5px;
	font-family: "Roboto Geo Caps";
	font-size: 16px;
	color: #aeb5c9; //$light-gray;
	background-color: transparent;
	height: 31px;
	font-weight: 400;
	transition: all 0.5s;
	svg {
		display: inline-block;
	}

	.arrow {
		vertical-align: middle;
		color: #d9dfef;
		transition: transform 0.5s;
		width: 15px;
		transform: rotateZ(-90deg) rotateX(0deg);
	}

	&:hover {
		color: $dark-gray;

		.cls-1 {
			stroke: $dark-gray !important;
		}

		.arrow {
			color: $dark-gray;
		}

		cursor: pointer;
		background-color: $hover-color;
	}

	&.collapsed {
		left: 23px;
		padding: 0;
		.arrow {
			transform: rotateZ(-90deg) rotateX(180deg);
		}
	}
	@media screen and (max-width: 1500px) {
		left: 200px;
	}

	@media screen and (max-width: 800px) {
		transition: left 0.7s;
		left: 260px;
	}
}
.side_burger {
	position: fixed;
	z-index: 600;
	left: 10px;
	top: 90px;
	width: 40px;
	height: 40px;
	padding: 9px;
	background-color: transparent;
	border-radius: 7px;
	cursor: pointer;
	transition:
		left 0.7s,
		box-shadow 0.5s,
		background-color 0.7s;

	@media screen and (max-width: 360px) {
		left: 0;
	}
}
.show_bar {
	background-color: #eff1f7;
	box-shadow: rgba(0, 0, 0, 0.16);
	left: 240px;

	@media screen and (max-width: 360px) {
		left: 270px;
	}
}
.teacher-sidebar-container {
	z-index: 600;
	width: $bar-width;
	background-color: $body-color;
	padding-bottom: 10px;
	border-radius: 0 10px 10px 0;
	font-family: "Roboto Geo Caps";
	font-size: 16px;
	transition:
		right 0.5s,
		left 0.5s;
	position: fixed;
	left: 0;
	top: 130px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	transition: all 0.7s;
	-webkit-transition: all 0.7s;
	-moz-transition: all 0.7s;

	&.collapsed {
		// display: none;
		opacity: 0;
		left: -330px;
	}
	@media screen and (max-width: 1500px) {
		width: $bar-width - 40px;
	}
	@media screen and (max-width: 800px) {
		box-shadow: 0px 0px 5px 0 rgb(139, 139, 139);
		height: 100%;
	}
	@media screen and (max-width: 360px) {
		width: 310px;
	}

	.sidebar-item {
		position: relative;
		letter-spacing: 0.5px;
		padding: 15px 8px;
		color: $dark-gray;
		margin: 5px;

		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		overflow: hidden;
		font-weight: bold;

		border-radius: 80px 0 0 80px;
		transition: all 0.2s;

		&:hover {
			// color: $hover-color;
			cursor: pointer;
			// box-shadow: 0px 0px 4px 2px $hover-color;
			background-color: $hover-color;
		}

		&.currentPath {
			color: #5273e6;
			// font-size: 1.2em;
		}

		@media screen and (max-width: 1500px) {
			font-size: 14px;
			padding: 10px 8px;
		}

		@media screen and (max-width: 480px) {
			font-size: 14px;
			margin: 0;
			padding: 10px 15px;

			&:first-child {
				padding-top: 20px;
			}
		}
	}

	.video-link-container {
		text-align: right;
		width: 100%;
		margin-top: 30px;
		padding-right: $right-p;

		.video-link-button {
			color: $green-color;
			cursor: pointer;
			font-weight: bold;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.video_icon {
				width: 30px;
				margin-right: 10px;
			}

			.cls-4 {
				fill: $green-color;
			}

			.cls-2 {
				stroke: $green-color;
			}

			span {
				vertical-align: middle;
			}
		}

		.instruction {
			font-family: "Roboto Geo Nus";
			font-size: 12px;
			color: $dark-gray;
			margin: 12px 0px;
			padding: 0 0 0 60px;
			display: inline-block;
			text-align: right;
		}

		.video-link-not-working {
			font-size: 14px;
			margin-top: 20px;
			color: $blue-color;
			cursor: pointer;
			display: inline-block;
		}
		@media screen and (max-width: 1500px) {
			width: 88%;
			padding-right: initial;

			.video-link-button {
				justify-content: space-between;
				.video_icon {
					width: 25px;
				}

				span {
					letter-spacing: 0.5px;
					font-size: 14px;
				}
			}

			.instruction {
				padding: 0 0 0 40px;
				font-size: 11px;
			}
		}
		@media screen and (max-width: 360px) {
			.video-link-button {
				justify-content: flex-start;

				.video_icon {
					width: 25px;
					margin-right: 15px;
				}
			}
		}
	}
}
.video_link__wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 20px;
	@media screen and (max-width: 800px) {
		display: block;
	}
}
.miniSidebar {
	z-index: 600; // very important for child divs
	left: 0;
	top: 130px;
	position: fixed;
	width: 94px;

	@media screen and (max-width: 800px) {
		width: $thin-sidebar;
		min-width: $thin-sidebar;
	}

	@media screen and (max-width: 360px) {
		width: $extra-thin-sidebar;
		min-width: $extra-thin-sidebar;
	}

	font-size: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	opacity: 0;
	transition: right 0.2s;
}
.open_expanded_sidebar {
	animation: openExpandedMenu 0.3s;
	opacity: 1;
}
@keyframes openExpandedMenu {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.miniSidebar__icon_container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60px;
	width: 60px;
	border-radius: 200px;
	margin-bottom: 7px;
	margin-top: 7px;

	&:hover {
		cursor: pointer;
		// box-shadow: 0px 0px 4px 2px $hover-color;
		background-color: $hover-color;
	}

	.mSHover {
		white-space: nowrap;
		padding: 7px;
		font-family: "Roboto Geo Nus";
		// border: 1.5px solid rgba(98, 106, 132, 0.452);
		font-size: 14px;
		line-height: 14px;
		color: #aeb5c9;
		box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px;
		border-radius: 50px;
		position: absolute;
		opacity: 1;
		background-color: rgb(255, 255, 255);
		display: none;
		z-index: 1000000;
		left: 110%;
		&.visible {
			display: flex;
		}
	}

	@media screen and (max-width: 800px) {
		width: 32px;
		height: 32px;
		padding: 5px;
	}

	.miniSidebar__icon {
		height: 25px;

		* {
			fill: #626a84;
		}

		&.current_miniSidebar__icon {
			* {
				fill: #5273e6;
			}
		}
	}

	.miniSidebar__video_icon {
		height: 20px;
	}
}

.popup-buttons {
	button {
		width: 100px;
		height: 40px;
		border-radius: 5px;
		font-size: 20px;
		font-family: "Roboto Geo Caps";
		outline: none;
		cursor: pointer;
		margin: 0px 20px;
		margin-top: 40px;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
	}

	.confirm {
		background-color: white;
		color: $blue-color;
	}

	.reject {
		background-color: $blue-color;
		color: white;
	}
}
.copy_link__wrapper {
	font-family: "Roboto Geo MT Bold";
	cursor: pointer;
}
.show_link_wrapper {
	color: #9298ab;
	word-wrap: break-word;
	width: 100%;
	padding: 10px 0 10px 40px;
	line-height: 17px;
	font-size: 12px;

	@media screen and (max-width: 360px) {
		padding: 5px 0 5px 40px;
		line-height: 14px;
		font-size: 11px;
	}
}
.show_link_text {
	margin-top: 20px;
	color: #37d7a0;
	font-size: 12px;
	text-align: right;

	@media screen and (max-width: 360px) {
		margin-top: 10px;
		padding-right: 12px;
		font-size: 11px;
	}
}

// ============= Responsive side bar ================== \\
.responsive_sidebar {
	position: fixed;
	top: 70px;
	left: -260px;
	height: 100%;
	width: 320px;
	z-index: 1000;
	background-color: #eff1f7;
	transition: left 0.5s;
	text-align: right;

	&__border {
		position: absolute;
		top: 20px;
		right: -10px;
		fill: #eff1f7;
	}

	&__burger {
		height: 16px;
		width: 22px;
		position: absolute;
		top: 23px;
		right: 20px;
		cursor: pointer;
	}

	@media screen and (max-width: 360px) {
		left: -230px;
		width: 280px;

		&__burger {
			height: 16px;
			width: 20px;
			right: 15px;
		}
	}
}
.toggle {
	left: 0;
}
.icons_bar {
	position: absolute;
	top: 70px;
	right: 3px;
	z-index: 10;
	width: 57px;
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: opacity 0.3s;

	&__item {
		margin-bottom: 30px;

		&_svg {
			width: 20px;

			&.current_miniSidebar__icon {
				* {
					fill: #5273e6;
				}
			}
		}
	}

	@media screen and (max-width: 360px) {
		width: 47px;
	}
}
.hide_icons_bar {
	opacity: 0;
	z-index: -10;
}
.menu_bar {
	position: absolute;
	top: 60px;
	width: 100%;
	height: 100%;
	right: 3px;
	opacity: 0;
	transition: opacity 0.3s;

	&__item {
		font-family: "Roboto Geo Caps";
		font-size: 14px;
		text-align: right;
		letter-spacing: 0.5px;
		padding: 10px $right-p 10px 0;
		margin-bottom: 5px;
		color: $dark-gray;
		font-weight: bold;
		transition: all 0.2s;

		&.currentPath {
			color: #5273e6;
			padding-right: 30px;
		}
	}

	.video-link-container {
		width: 100%;
		margin-top: 30px;
		padding: 0 $right-p;

		.video-link-button {
			font-family: "Roboto Geo Caps";
			letter-spacing: 1px;
			color: $green-color;
			cursor: pointer;
			font-weight: bold;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.video_icon {
				width: 30px;
				margin-right: 10px;
			}

			.cls-4 {
				fill: $green-color;
			}

			.cls-2 {
				stroke: $green-color;
			}

			span {
				vertical-align: middle;
			}
		}

		.instruction {
			font-family: "Roboto Geo Nus";
			font-size: 12px;
			color: $dark-gray;
			margin: 10px 0px;
			padding: 0 0 0 42px;
			display: inline-block;
			text-align: right;
		}

		.video-link-not-working {
			font-size: 14px;
			margin-top: 20px;
			color: $blue-color;
			cursor: pointer;
			display: inline-block;
		}
	}

	@media screen and (max-width: 500px) {
		&__item {
			font-size: 12px;
		}
	}
	@media screen and (max-width: 360px) {
		top: 50px;
		&__item {
			font-size: 11px;
			padding: 7px 12px 7px 0;

			&.currentPath {
				padding-right: 20px;
			}
		}

		& > .video-link-container {
			margin-top: 15px;
			padding: 0 12px;

			.video-link-button {
				font-size: 14px;
				justify-content: flex-end;

				.video_icon {
					width: 20px;
				}
			}
			.instruction {
				font-size: 11px;
				margin: 6px 0px 10px;
				padding: 0 0 0 42px;
			}
			.video-link-not-working {
				margin-top: 15px;
			}
		}
	}
}
.video_lessons_btn__wrapper {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	padding: 0 $right-p;

	& > button {
		width: 100% !important;
		margin: 20px 0 0 0 !important;
	}

	@media screen and (max-width: 360px) {
		padding: 0 12px;

		& > button {
			font-size: 12px;
			height: 35px;
			border-radius: 14px;
		}
	}
}
.show_menu_bar {
	opacity: 1;
	z-index: 10;
}
