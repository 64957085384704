.statsContainer {
	margin-bottom: 15px;
	display: none;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	position: relative;
	color: blue;
}
@media screen and (min-width: 1451px) {
	.statsContainer {
		padding-right: 150px;
	}
}
@media screen and (min-width: 901px) {
	.statsContainer.upperStats {
		display: inline-flex;
		float: right;
	}
}
@media screen and (max-width: 1201px) {
	.statsContainer.upperStats .statBox {
		width: 150px;
		height: 150px;
	}
}
@media screen and (max-width: 900px) {
	.statsContainer.belowStats {
		display: flex;
	}
}
.statsContainer .statBox {
	background-color: #eff1f7;
	width: 170px;
	height: 170px;
	border-radius: 30px;
	margin: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
@media screen and (max-width: 600px) {
	.statsContainer .statBox {
		width: calc(50% - 22px);
		height: calc(50% - 22px);
	}
}
.statsContainer .statBox .statNumber {
	width: 170px;
	height: 80px;
	color: #5273e6;
	font-family: "GL Kirovi Bold";
	font-style: normal;
	font-weight: bold;
	font-size: 50px;
	line-height: 50px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	text-align: center;
}
@media screen and (max-width: 600px) {
	.statsContainer .statBox .statNumber {
		font-size: 42px;
	}
}
.statsContainer .statBox .statText {
	width: 170px;
	height: 80px;
	color: #3a3d47;
	font-family: "Roboto Geo Nus";
	font-size: 18px;
	padding-top: 10px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	text-align: center;
	overflow-wrap: anywhere;
}
@media screen and (max-width: 600px) {
	.statsContainer .statBox .statText {
		font-size: 16px;
	}
}
.statsContainer .statBox.sentAssignmentsCount {
	color: inherit;
	animation: fadeIn1 ease 2s;
}
.statsContainer .statBox.studentsCount {
	color: inherit;
	animation: fadeIn2 ease 2.5s;
}
.statsContainer .statBox.teachersCount {
	color: inherit;
	animation: fadeIn3 ease 3.5s;
}
.statsContainer .statBox.schoolsCount {
	color: inherit;
	animation: fadeIn4 ease 4s;
}
:global(.withBulterAd) .statsContainer.upperStats {
	margin-bottom: 0;
}
:global(.withBulterAd) .statBox {
	height: 140px;
}
:global(.withBulterAd) .statBox .statNumber {
	height: 62px;
}
:global(.withBulterAd) .statBox .statText {
	height: 70px;
}
@keyframes fadeIn1 {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeIn2 {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeIn3 {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeIn4 {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
