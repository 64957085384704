.tooltip {
	position: relative;
	display: inline-block;
}
.tooltip .tooltiptext {
	visibility: hidden;
	background-color: white;
	width: 100%;
	min-width: 100px;
	color: black;
	text-align: center;
	border-radius: 6px;
	padding: 7px 13px;
	position: absolute;
	z-index: 1;
	bottom: 0%;
	left: 50%;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
	margin-bottom: 8px;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.tooltip:hover .tooltiptext {
	visibility: visible;
}
