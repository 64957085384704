.header {
	height: 55px;
	background-color: red;
}
.container {
	max-width: 90%;
	margin: 0 auto;
}
.container * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Roboto Geo Nus";
	color: #626a84;
}
.container p {
	line-height: 24px;
	text-align: justify;
}
.title,
.dutchTitle,
.DutchContitle {
	font-family: "Roboto Geo Caps";
	font-size: 20px;
	color: #5273e6;
	text-align: center;
	padding: 50px 0;
}
.DutchContitle {
	font-size: 18px;
}
.termHeader {
	color: #626a84;
	font-weight: bold;
}
.dutchTitle {
	font-size: 18px;
	text-align: left;
}
.primary {
	font-weight: bold;
	margin-bottom: 20px;
}
.secondary {
	font-weight: bold;
	margin: 20px 0;
}
.marginTB {
	margin: 20px 0;
}
.termText {
	padding-left: 20px;
	margin: 0 0 20px;
}
.termText__sub {
	padding-left: 20px;
}
.linkToMail {
	margin-left: 20px;
	color: #5273e6;
	font-weight: bold;
	letter-spacing: 0.5px;
}
.linkToMail:hover {
	color: #626a84;
}
@media screen and (max-width: 768px) {
	.container * {
		font-size: 13px;
	}
	.container p {
		line-height: 20px;
	}
	.title {
		font-size: 16px;
		padding: 30px 0;
	}
}
