$grey: #626a84;
$blue: #5273e6;

.mainContainer {
	margin: 0;
	padding: 0;

	@media screen and (max-width: 600px) {
		margin-top: 70px;
	}
}

@mixin centerFlex {
	display: flex;
	justify-content: center;
	align-items: center;
}

.centerFlex {
	@include centerFlex;
}

.heading {
	@include centerFlex;
	margin: 20px 0px;
	font-family: "Roboto Geo MT Bold";
	font-size: 28px;
	font-weight: bold;
	color: $blue;
}

.boxesWrapperGrid {
	display: grid;
	flex-flow: wrap;
	width: 92%;
	margin: 30px auto;
	justify-content: flex-start;
	grid-template-columns: repeat(auto-fill, minmax(286px, 1fr));
	column-gap: 30px;
	row-gap: 30px;
	grid-auto-rows: minmax(286px, auto);
}

.boxesWrapper {
	display: flex;
	flex-flow: wrap;
	width: 92%;
	margin: 0 auto;
	margin-top: 30px;
	justify-content: flex-start;

	@media screen and (max-width: 1000px) {
		justify-content: center;
	}
}

.boxContainer {
	border-radius: 20px;
	background-color: white;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	position: relative;
}

.box3Elements {
	height: 320px;
}

@media screen and (max-width: 1600px) {
	.box3Elements {
		height: 280px;
	}
}

.twoPartBox {
	cursor: pointer;
	padding: 20px;
	justify-content: center;
	color: #5273e6;
	.imgContainer {
		height: 170px;
		@include centerFlex;
		svg,
		img {
			height: 90%;
		}
	}
	.title {
		font-family: "Roboto Geo Caps";
		font-size: 26px;
		height: 50px;
		text-align: center;
	}
	.innerContainer {
		@include centerFlex;
		flex-direction: column;
	}
}

.conferenceBox {
	h1 {
		font-family: "Roboto Geo Nus";
		color: $grey;
		margin: 15px 0px;
	}

	.changeLink {
		color: $blue;
		cursor: pointer;
		position: relative;
		font-family: "Roboto Geo Caps";
		font-size: 13px;
	}

	.changeLinkPopup {
		position: absolute;
		bottom: 100%;
		box-shadow: 0px 0px 10px 0px #a1a1a1;
		background-color: #eff1f7;
		border-radius: 10px;
		cursor: auto;
		padding-bottom: 12px;
		z-index: 20;
	}
}

.groupName {
	margin-top: 15px;
	font-family: "Roboto Geo MT Bold";
	font-size: 24px;
	font-weight: bold;
	text-align: center;
	color: $grey;
}

.enterButton {
	@include centerFlex;
	width: 100%;
	height: 60px;
	border-radius: 20px;
	background-color: #b4c0e9;
	font-family: "Roboto Geo Mt Bold";
	font-size: 21px;
	font-weight: bold;
	color: $grey;
	cursor: pointer;
	@media screen and (max-width: 1600px) {
		height: 45px;
		font-size: 19px;
	}
}

.groupInfoContainer {
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
}
.avatarContainer {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: flex-end;
	width: 90px;
	background-color: #e8ebf3;
	border-radius: 0 20px 20px 0;
	margin-right: 15px;
	height: 100%;
	@media screen and (max-width: 1400px) {
		height: 85%;
	}
}

.avatar {
	width: 28.5px;
	height: 25px;
	margin-right: 15px;
}

.details {
	font-family: "Roboto Geo Nus";
	font-size: 18px;
	color: $grey;
}
