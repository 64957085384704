.modal_container {
	position: fixed;
	width: 340px;
	height: 256px;
	z-index: 2000;
	background-color: white;
	border-radius: 20px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	color: #fff;
	text-align: center;
	padding: 30px 0 0;
	transition: all 0.1s;
}
.modal_container > * {
	margin: 0;
	padding: 0;
}
.modal_conent {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.modal_title {
	font-family: "Roboto Geo Nus";
	font-size: 14px;
	color: #626a84;
	text-align: left;
	padding-left: 13px;
	margin-bottom: 10px;
}
::placeholder {
	font-size: 12px;
	color: #b7b7b7;
}
:-ms-input-placeholder {
	font-size: 12px;
	color: #b7b7b7;
}
::-ms-input-placeholder {
	font-size: 12px;
	color: #b7b7b7;
}
.modal_input {
	width: 300px;
	height: 45px;
	border-radius: 10px;
	border: solid 1px #626a84;
	background-color: white;
	font-family: "Roboto Geo Nus";
	font-size: 16px;
	margin-bottom: 10px;
	color: #626a84;
	outline: none;
}
.modal_input :-ms-input-placeholder {
	font-size: 10px;
	color: #b7b7b7;
}
.modal_button {
	width: 300px;
	height: 45px;
	border-radius: 10px;
	background-color: #5273e6;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-family: "Roboto Geo MT Bold";
	border: none;
	outline: none;
	cursor: pointer;
}
