.popupBlackDiv {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.8);
	z-index: 1000;
	overflow-y: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}
.popup-content {
	background: white;
	border-radius: 20px;
	padding: 20px;
	box-shadow: rgba(0, 0, 0, 0.5) 0 5px 22px;
	position: absolute;
	max-width: 90%;
}
.popup-content:not(.scrollable) {
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	max-height: 80vh;
	max-width: 80vw;
}
.popup-content.scrollable {
	margin: 0 auto;
	margin-top: 10vh;
	margin-bottom: 25px;
	display: block;
	width: min-content;
	position: relative;
}
.popup-content-no-padding {
	padding: 0px;
	border-radius: 6px;
}
@media only screen and (max-height: 400px), (max-width: 720px) {
	.popup-content-no-padding .popup-header {
		padding-right: 35px;
	}
}
.loginPopupContent {
	font-family: "Roboto Geo Nus";
	font-size: 24px;
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px solid transparent;
	border-radius: 30px;
	overflow: hidden;
	width: 350px;
	max-width: 90%;
	margin: 10vh auto 25px;
}
@media screen and (max-width: 550px) {
	.loginPopupContent {
		width: 95%;
	}
}
.loginPopupContent .Header {
	width: 100%;
	padding: 30px 30px;
	background: #303345;
	text-align: center;
}
.loginPopupContent .Body {
	padding: 20px 35px;
	background: linear-gradient(30deg, #9e2ca6 0%, #ec5b6b 80%);
	display: flex;
	width: 100%;
	flex-direction: column;
}
.loginPopupContent .Body .Input {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.loginPopupContent .Body .Input input {
	font-family: "Roboto Geo Nus";
	font-size: 24px;
	color: white;
	width: 100%;
	background: transparent;
	border: 0;
	border-bottom: 1px solid white;
	display: flex;
	margin: 15px;
	font-size: 1em;
	outline: none;
}
.loginPopupContent .Body .Input input::placeholder {
	font-family: "Roboto Geo Nus";
	font-size: 24px;
	color: white;
	color: rgba(255, 255, 255, 0.5);
	font-size: 1em;
}
.loginPopupContent .Body .Input input.incorrect {
	border-bottom-color: #252423;
}
.loginPopupContent .Body .Buttons {
	margin: 20px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.loginPopupContent .Body .Buttons button {
	font-family: "Roboto Geo Nus";
	font-size: 24px;
	color: white;
	transition: all 0.2s;
	box-shadow:
		rgba(255, 255, 255, 0.3) 0 0 5px,
		rgba(255, 255, 255, 0.3) 0 0 5px;
	font-size: 20px;
	margin: 5px 0;
	width: 100%;
	padding: 10px;
	background: transparent;
	border: 1px solid white;
	border-radius: 30px;
	cursor: pointer;
	outline: none;
}
.loginPopupContent .Body .Buttons button:hover {
	background: rgba(255, 255, 255, 0.2);
}
.loginPopupContent .Body .Buttons button > * {
	vertical-align: middle;
}
