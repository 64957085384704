@secondary-color: #278ea7;
@light-main-color: #63bed9;
@dark-main-color: #023e5a;
@dark-border-color: #23263e;
@main-color1: #8b2db2;
@main-color2: #d54077;
@main-color3: #fa7765;
@main-grey: #4b4f66;
@light-grey: #d0d0d0;
@dark-text-color: #343434;
@mainWidth: 1200px;
@main-font: "BPGNinoMtavruli";
@main-font2: "BPGNinoMtavruli";
@main-font2-bold: "BPGNinoMtavruliBOLD";
@main-font-numbers: "roboto";
@admin-primary-color: #ffa115;
@admin-secondary-bg: linear-gradient(30deg, #f6750b, #ffa115);
@admin-dark-bg-color: #0f0e17;
@popup-border-radius: 20px;
@event-popup-main-color: #333647;
@main-button-color: #fe2f73;
@event-pupup-gradient: linear-gradient(
		60deg,
		rgba(255, 255, 255, 0),
		rgba(255, 255, 255, 0.08) 50%,
		rgba(255, 255, 255, 0.25) 50%,
		rgba(255, 255, 255, 0) 70%
	),
	linear-gradient(90deg, #7c169c, #f14391);
@cu-slider-color: #1876c5;
@cu-slider-pale: #ccdbe8;
@cu-slider-gradient: linear-gradient(to right, #1a4eb8, #1a84c2);
@cu-scholarship-gradient: linear-gradient(to right, #8b2db2, #aa3efe);
