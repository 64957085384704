.input-container {
	width: 92%;
	height: 50px;
	background-color: white;
	outline: none;
	font-size: 16px;
	font-family: "Roboto Geo Nus";
	border: none;
	border-radius: 20px;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding: 0 15px;
}
.input-container input {
	height: 100%;
	width: calc(100% - 35px);
	border: none;
	outline: none;
	border-radius: 20px 0 0 20px;
}
.input-container .icon {
	cursor: pointer;
	width: 25px;
}
.input-container .hover-text {
	font-size: 10px;
	position: absolute;
	width: 80px;
	height: 20px;
	text-align: center;
	border-radius: 20px;
	top: 12px;
	color: #aeb5c9;
	background-color: white;
	right: -75px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	line-height: 2;
}
@media screen and (max-width: 1500px) {
	.input-container {
		width: 100%;
	}
}
@media screen and (max-width: 360px) {
	.input-container {
		height: 40px;
	}
	.input-container input {
		height: 100%;
		width: calc(100% - 35px);
		border-radius: 20px 0 0 20px;
		font-size: 14px;
	}
	.input-container .icon {
		width: 20px;
	}
}

