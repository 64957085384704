.faqContainer {
	width: 80%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	margin: 15px auto;
	flex-direction: column;
}
.questionWithAnswerContainer {
	border-radius: 20px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	background-color: white;
	margin: 10px 0;
	width: 100%;
	cursor: pointer;
	padding: 28px 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	transition: box-shadow 0.2s;
}
.questionWithAnswerContainer:hover {
	box-shadow: 0 0 15px 0 rgba(82, 115, 230, 0.6);
}
.faqWithMoon {
	min-height: calc(100vh - 120px);
}
.questionWithArrowIcon {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #58607c;
	font-size: 16px;
	font-family: "Roboto Geo Caps";
}
.arrow {
	color: #5273e6;
}
.line {
	height: 1px;
	width: 100px;
	background-color: transparent;
	margin: 27px 0 25px 50px;
	text-size-adjust: none;
	-moz-text-size-adjust: none;
	border-bottom: 1.5px solid #ced1df;
	color-adjust: none;
	-webkit-text-size-adjust: none;
}
@media screen and (max-width: 500px) {
	.line {
		margin: 27px 0 25px 10px;
	}
}
.question {
	margin: 0 50px;
}
@media screen and (max-width: 500px) {
	.question {
		margin: 0 10px;
	}
}
.faqTitle {
	color: #5273e6;
	font-size: 20px;
	font-family: "Roboto Geo Caps";
	width: 100%;
	justify-content: center;
	text-align: center;
	display: flex;
	margin: 41px 0;
}
@media screen and (max-width: 800px) {
	.faqTitle {
		font-size: 14px;
	}
}
.answer_wrapper {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.5s ease;
}
.show_answer {
	max-height: 555px;
}
.answer {
	font-size: 14px;
	color: #58607c;
	font-family: "Roboto Geo Nus";
	margin: 30px 50px;
}
@media screen and (max-width: 500px) {
	.answer {
		margin: 30px 10px;
	}
}
.videoIcon {
	width: 20px;
	margin-right: 5px;
}
.videoAnswer {
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #5273e6;
	cursor: pointer;
	font-family: "Roboto Geo Caps";
	font-size: 15px;
	margin-top: 10px;
}
.topHalf {
	width: 100%;
}
