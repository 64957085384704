.mainButtonsWidgetDiv {
	overflow: hidden;
	height: 50px;
	width: 90%;
	border-radius: 15px;
	margin: 0 auto;
	color: #5273e6;
	background-color: white;
	box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
	margin: 0px auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.buttons {
	font-family: "Roboto Geo Nus";
	color: #5273e6;
	outline: none;
	border: none;
	background: none;
	cursor: pointer;
	width: 25%;
	height: 100%;
	border-left: 1px solid #5273e6;
	display: flex;
	justify-content: center;
}
.buttons:first-of-type {
	border-left: none;
}
@media screen and (max-width: 700px) {
	.buttons {
		width: 150px;
		font-size: 12px;
	}
}
@media screen and (max-width: 400px) {
	.buttons {
		width: 100px;
		font-size: 10px;
	}
}
.buttons:hover {
	background-color: rgba(82, 114, 230, 0.8);
	color: white;
}
.activeButtons {
	color: white;
	background-color: #5273e6;
}
.blueLine {
	position: absolute;
	width: 1px;
	height: 60%;
	top: 11px;
	background-color: #5273e6;
}
.blueLineOff {
	position: absolute;
	width: 1px;
	height: 60%;
	top: 11px;
	background-color: white;
}
.topGeButton {
	cursor: pointer;
}
.topGeContainer,
.topGeContainerWithotMargin {
	width: 80%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin: 0 auto 30px;
}
@media screen and (max-width: 1000px) {
	.topGeContainer,
	.topGeContainerWithotMargin {
		width: 70%;
	}
}
.topGeContainer.hidden,
.topGeContainerWithotMargin.hidden {
	opacity: 0.01;
}
.topGeContainerWithotMargin {
	margin: 0 auto;
}
.messengerChatContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	bottom: 0;
	padding: 0 10px 10px 0;
}
.likeIcon {
	width: 111px;
	margin: 20px auto;
}
.facebookLikePopupMainText {
	font-family: "Roboto Geo Caps";
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.22;
	letter-spacing: normal;
	text-align: center;
	color: #5273e6;
}
.facebookLikePopupSecondaryText {
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: center;
	color: #626a84;
	width: 70%;
	margin: 0 auto 20px auto;
}
.closeIcon {
	width: 13px;
	cursor: pointer;
}
.popup {
	display: flex;
	align-items: center;
	justify-content: center;
}
.PopupContentWithClose {
	margin-top: 0;
	margin-bottom: 0;
}
.buttonsWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}
.buttonsWrapper > button {
	width: auto;
	padding: 0 25px;
	letter-spacing: 0.5px;
}
@media screen and (max-width: 1400px) {
	.buttonsWrapper > button {
		padding: 0 20px;
		height: 35px;
		font-size: 14px;
		line-height: 14px;
	}
}
@media screen and (max-width: 768px) {
	.buttonsWrapper {
		margin-top: 30px;
		justify-content: center;
	}
}
.buttonWrapperFlexEnd {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 92px;
	justify-content: flex-end;
}
.buttonWrapperFlexEnd > button {
	width: auto;
	padding: 0 25px;
	letter-spacing: 0.5px;
}
@media screen and (max-width: 1400px) {
	.buttonWrapperFlexEnd > button {
		padding: 0 20px;
		height: 35px;
		font-size: 14px;
		line-height: 14px;
	}
}
@media screen and (max-width: 768px) {
	.buttonWrapperFlexEnd {
		margin-top: 30px;
		justify-content: center;
	}
}
@media screen and (max-width: 1700px) {
	.buttonWrapperFlexEnd {
		padding: 0 35px;
	}
}
@media screen and (max-width: 800px) {
	.buttonWrapperFlexEnd {
		position: absolute;
		right: 9px;
		top: 0;
		margin-top: 0;
		justify-content: initial;
		padding: 0;
	}
}
.VideoLessonButton {
	width: 200px;
	height: 45px;
	font-family: "Roboto Geo Caps";
	border-radius: 18px;
	font-size: 18px;
}
@media screen and (max-width: 1500px) {
	.VideoLessonButton {
		font-size: 14px;
		letter-spacing: 0.5px;
	}
}
.startVideoLessonButtonAvailable {
	width: 200px;
	height: 45px;
	font-family: "Roboto Geo Caps";
	border-radius: 18px;
	font-size: 18px;
	color: white;
	background-color: #37d7a0;
	cursor: pointer;
}
@media screen and (max-width: 1500px) {
	.startVideoLessonButtonAvailable {
		font-size: 14px;
		letter-spacing: 0.5px;
	}
}
.plus_icon {
	width: 16px;
	height: 16px;
	margin-right: 8px;
}
@media screen and (max-width: 1400px) {
	.plus_icon {
		width: 14px;
		height: 14px;
	}
}
@media screen and (max-width: 800px) {
	.plus_icon {
		width: 16px;
		height: 16px;
		margin-right: initial;
	}
}
.createNewAssignmentbutton {
	width: 200px;
	height: 45px;
	font-family: "Roboto Geo Caps";
	border-radius: 18px;
	font-size: 18px;
	color: white;
	background-color: #37d7a0;
	font-size: 16px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 15px;
	width: 200px !important;
	padding: 0 !important;
}
@media screen and (max-width: 1500px) {
	.createNewAssignmentbutton {
		font-size: 14px;
		letter-spacing: 0.5px;
	}
}
@media screen and (max-width: 1400px) {
	.createNewAssignmentbutton {
		border-radius: 12px;
		font-size: 14px !important;
	}
}
@media screen and (max-width: 800px) {
	.createNewAssignmentbutton {
		width: 30px !important;
		height: 50px !important;
		border-radius: 12px;
		font-size: initial;
	}
}
.addStudentsToClassroomButton {
	width: 260px;
	height: 36px;
	border-radius: 20px;
	background-color: white;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0px 8px;
}
@media screen and (max-width: 1400px) {
	.addStudentsToClassroomButton {
		border-radius: 12px;
		font-size: 14px !important;
	}
}
.addStudentsToClassroomButton img {
	margin: 4px;
}
.addStudentsToClassroomButton span {
	font-family: "Roboto Geo MT Bold";
	font-size: 16px;
	color: #626a84;
	margin: 4px;
}
.startVideoLessonButtonAvailable:hover {
	background-color: #1cb47f;
}
.startVideoLessonButtonNotAvailable {
	width: 200px;
	height: 45px;
	font-family: "Roboto Geo Caps";
	border-radius: 18px;
	font-size: 18px;
	color: #aeb5c9;
	background-color: #e7eaf5;
	cursor: not-allowed;
}
@media screen and (max-width: 1500px) {
	.startVideoLessonButtonNotAvailable {
		font-size: 14px;
		letter-spacing: 0.5px;
	}
}
.startVideoLessonButtonNotActive {
	width: 200px;
	height: 45px;
	font-family: "Roboto Geo Caps";
	border-radius: 18px;
	font-size: 18px;
	background-color: #5273e6;
	color: white;
	cursor: pointer;
}
@media screen and (max-width: 1500px) {
	.startVideoLessonButtonNotActive {
		font-size: 14px;
		letter-spacing: 0.5px;
	}
}
.homeworkNotesBox {
	position: relative;
	display: inline-block;
	width: 400px;
	height: 96px;
	padding: 3px;
	top: 10px;
	left: 20px;
	border: 1px solid #626a84;
	box-sizing: border-box;
	border-radius: 15px;
	cursor: pointer;
	z-index: 99;
}
@media screen and (max-width: 1200px) {
	.homeworkNotesBox {
		position: relative;
		display: block !important;
		width: 80%;
		height: 96px;
		left: 0;
		right: 0;
		top: 20px;
		max-width: 350px;
		margin: 0 auto;
	}
	.notesInner {
		width: 100% !important;
	}
	.popupContainer {
		position: relative;
		width: 100% !important;
		height: 1000% !important;
	}
	.containerWrapper {
		position: relative;
		width: 100%;
		height: 73%;
	}
	.history {
		text-align: center !important;
		margin-left: 0 !important;
		margin-bottom: 10px;
	}
	.notesContainer {
		height: 100% !important;
		max-height: 100% !important;
		display: block;
	}
	.noteInput {
		margin-top: 0px !important;
	}
}
.notesInner {
	width: 392px;
	height: 88px;
	background: #ffffff;
	border-radius: 12px;
	padding: 3px;
	font-size: 14px;
	color: #626a84;
	font-family: "Roboto Geo MT Bold";
}
.noteInput {
	width: 820px;
	height: 44px;
	margin-top: 50px;
	background: #ffffff;
	padding: 8px;
	font-size: 14px;
	border: 1px solid #aeb5c9;
	box-sizing: border-box;
	border-radius: 15px;
	margin-right: 5px;
}
.saveNote {
	background: #37d7a0;
	position: relative;
	border-radius: 15px;
	height: 44px;
	width: 44px;
	display: inline-block;
	cursor: pointer;
	top: 16.5px;
}
.newestNote {
	position: relative;
	top: -16px;
	line-height: 16px;
	line-break: auto;
	overflow: hidden;
	word-break: break-all;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
.contentLabel {
	font-family: "Roboto GEO Nus";
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: #626a84;
}
.timePastLabel {
	font-family: "Roboto GEO Nus";
	position: relative;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	top: -19px;
	float: right;
	padding: 2px;
	color: #aeb5c9;
}
.history {
	text-align: left;
	font-family: "Roboto Geo MT Bold";
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 23px;
	margin-top: 10px;
	color: #626a84;
	margin-left: 47px;
}
.noteBox {
	position: relative;
	width: 90%;
	min-height: 55px;
	height: auto;
	margin-top: 8px;
	word-break: normal;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	font-family: "Roboto GEO MT Bold";
	font-style: normal;
	font-size: 16px;
	text-align: left;
	padding: 8px;
	line-height: 19px;
	color: #626a84;
	background: #eff1f7;
	border-radius: 12px;
}
.notesContainer {
	display: block;
	height: 350px;
	max-width: 100%;
	word-break: break-all;
	overflow-x: auto;
	overflow-y: auto;
}
.notesContainer::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}
.notesContainer::-webkit-scrollbar-track {
	background-color: #fff;
}
.notesContainer::-webkit-scrollbar-thumb {
	background: #626a84;
	border-radius: 10px;
}
@media screen and (max-width: 800px) {
	.noteInput {
		width: 80%;
	}
}
