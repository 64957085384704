.input {
	font-size: 16px;
	padding: 6px 2px;
	border: none;
	outline: none;
	font-family: "Roboto Geo Nus";
	font-weight: bold;
}
.checkBox {
	border-radius: 4px;
	width: 28px;
	height: 28px;
	background-color: #ced2fa;
	margin: 5px;
	cursor: pointer;
}
.openBox {
	width: 13px;
	height: 13px;
	background-size: contain;
	margin: 5px;
	cursor: pointer;
}
.checkBox:active {
	background-image: url("../../styles/img/tick.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 70%;
}
.parentsName {
	margin: 5px;
	flex: 1;
}
.multipleSelectContainer {
	position: relative;
	max-width: 100%;
}
.multipleSelectContainer .allContent {
	background: white;
	width: 691px;
	margin: 0 auto;
	border-radius: 32px;
	flex-direction: column;
	padding: 10px 0;
	margin-top: 19px;
	display: flex;
	max-width: 100%;
	text-align: left;
	box-shadow: 0 0 17px rgba(0, 0, 0, 0.32);
	font-family: "Roboto Geo Nus";
}
.multipleSelectSearchBar {
	display: flex;
	background-color: white;
	border-radius: 8px;
	width: 80%;
}
.topParent {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border-radius: 10px;
	border-bottom: solid #e5e5e5 1.5px;
	cursor: pointer;
}
.parentAndBoxes {
	display: inline-flex;
	align-items: center;
	padding: 2px 12px;
	margin: 1px;
	outline: none;
}
.searchItems {
	border-radius: 8px;
	padding: 2px 4px;
	cursor: text;
	flex: 1;
	font-family: "Roboto Geo Nus";
}
.searchIcon {
	padding: 0 5px;
	align-items: center;
	justify-content: center;
	display: flex;
	border-left: 1px solid #ccc;
	color: #585858;
}
.checkedItems {
	display: inline-flex;
	background-color: #ced2fa;
	color: #252423;
	margin: 3px;
	position: relative;
	font-size: 14px;
	border-radius: 5px;
	font-family: "Roboto Geo Nus";
}
.deleteButton {
	cursor: pointer;
	padding: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	transition: 0.2s;
}
.deleteButton:hover {
	background-color: #ff6b6b;
	color: white;
}
