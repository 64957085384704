.LogOut_wrapper {
	width: 42px;
	height: 42px;
	border-radius: 50%;
	background-color: #fff;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}
.LogOut_wrapper > img {
	width: 24px;
}
@media screen and (max-width: 1024px) {
	.LogOut_wrapper {
		width: 35px;
		height: 35px;
	}
	.LogOut_wrapper > img {
		width: 22px;
	}
}

