.videoPlayerPopup {
	width: 80%;
}
.filePreviewContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.noPreview {
	font-size: 18px;
	color: #626a84;
	font-family: "Roboto Geo Nus";
}
.downloadButton {
	width: 220px;
	height: 46px;
	border-radius: 15px;
	background-color: #5273e6;
	font-family: "Roboto Geo MT Bold";
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	font-weight: normal;
	margin-bottom: 10px;
	cursor: pointer;
}
.downloadButton .icon {
	width: 20px;
	height: 20px;
	margin-right: 11px;
}
