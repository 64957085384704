.header {
	width: 100%;
	height: 62px;
	background-color: #5273e6;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 15px;
}
.mainLogo {
	width: 121px;
	height: 59px;
	cursor: pointer;
}
.blogMainPage {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}
.blogs {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	padding: 5px 20px;
}
.createBlogContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.blogTitle {
	font-family: "Roboto Geo Nus";
}
.startWritingBlog {
	width: 1018px;
	max-width: 100%;
	height: 320px;
	border-radius: 9px;
	border: 1.5px solid #aca6a6;
	margin: 9px 0px;
	font-family: "Roboto Geo Nus";
	cursor: text;
	outline: none;
	display: block;
	resize: none;
	padding: 5px;
}
.actionButton {
	background-color: #5273e6;
	font-family: "Roboto Geo Nus";
	color: white;
	height: 40px;
	width: 163px;
	font-size: 18px;
	border: none;
	outline: none;
	cursor: pointer;
	border-radius: 12px;
	transition: 0.2s;
}
.actionButton:hover {
	background-color: #2a56f3;
}
.actionButtonsContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 341px;
	margin: 55px auto 15px auto;
}
.title {
	margin: 10px 0;
	display: flex;
	width: 100%;
	align-items: center;
	color: #252423;
	font-family: "Roboto Geo Caps";
	justify-content: flex-start;
	height: 65px;
	padding-left: 5px;
}
.littleBlogFrame {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: "Roboto Geo Nus";
	margin: 50px 20px 10px 20px;
	box-shadow: 0 0 31px 3px rgba(0, 0, 0, 0.07);
	cursor: pointer;
	width: 350px;
	height: 350px;
	justify-content: space-between;
}
.blogMainPhoto {
	height: 228px;
	width: 100%;
}
.littleBlogsContainer {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
}
.inputsWithTitlesContainer {
	margin-top: 30px;
}
.addNewBlogButton {
	color: white;
	margin: 30px auto 5px auto;
	background-color: #5273e6;
	padding: 9px 18px;
	border: none;
	outline: none;
	font-size: 16px;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-family: "firago";
}
.addNewBlogButton:hover {
	background-color: #2e57ec;
}
.blogInput {
	width: 1018px;
	min-height: 49px;
	height: max-content;
	border: 1.5px solid #aca6a6;
	margin: 9px 0px;
	font-family: "Roboto Geo Nus";
	cursor: text;
	outline: none;
	display: block;
	resize: none;
	padding: 5px;
	margin-bottom: 35px;
}
@media screen and (max-width: 1018px) {
	.blogInput {
		width: calc(100% - 10px);
	}
}
.blogBody {
	width: 1018px;
	border: 1.5px solid #aca6a6;
	margin: 9px 0px;
	font-family: "Roboto Geo Nus";
	cursor: text;
	outline: none;
}
@media screen and (max-width: 1018px) {
	.blogBody {
		width: calc(100% - 10px);
	}
}
.uploadBlogMainPhotoButton {
	color: #5273e6;
	font-family: "Roboto Geo Caps";
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.2s;
}
.uploadBlogMainPhotoButton:hover {
	color: #2752ec;
}
.redirectToBlogButton {
	outline: none;
	border: none;
	background: transparent;
	color: white;
	font-size: 12px;
	margin-top: 6px;
	font-family: "Roboto Geo Nus";
	cursor: pointer;
}
@media screen and (max-width: 1030px) {
	.redirectToBlogButton {
		margin-top: 0;
		margin-right: 2px;
	}
}
.wholeBlogMainText {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}
.wholeBlogMainText img {
	max-width: 100%;
}
.dateAndButton {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 5px;
}
.date {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	color: #ababab;
	font-family: "Roboto Geo Nus";
}
