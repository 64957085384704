@font-face {
	font-family: "IBM Plex Sans";
	font-style: italic;
	font-weight: 100;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/ibmplexsans/v8/zYX8KVElMYYaJe8bpLHnCwDKhdTmdKZM.ttf)
		format("truetype");
}
@font-face {
	font-family: "IBM Plex Sans";
	font-style: italic;
	font-weight: 200;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/ibmplexsans/v8/zYX7KVElMYYaJe8bpLHnCwDKhdTm2IdscQ.ttf)
		format("truetype");
}
@font-face {
	font-family: "IBM Plex Sans";
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/ibmplexsans/v8/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRscQ.ttf)
		format("truetype");
}
@font-face {
	font-family: "IBM Plex Sans";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/ibmplexsans/v8/zYX-KVElMYYaJe8bpLHnCwDKhdTeEA.ttf)
		format("truetype");
}
@font-face {
	font-family: "IBM Plex Sans";
	font-style: italic;
	font-weight: 500;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/ibmplexsans/v8/zYX7KVElMYYaJe8bpLHnCwDKhdTm5IVscQ.ttf)
		format("truetype");
}
@font-face {
	font-family: "IBM Plex Sans";
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/ibmplexsans/v8/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJscQ.ttf)
		format("truetype");
}
@font-face {
	font-family: "IBM Plex Sans";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/ibmplexsans/v8/zYX7KVElMYYaJe8bpLHnCwDKhdTmrINscQ.ttf)
		format("truetype");
}
@font-face {
	font-family: "IBM Plex Sans";
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/ibmplexsans/v8/zYX-KVElMYYaJe8bpLHnCwDKjbLeEA.ttf)
		format("truetype");
}
@font-face {
	font-family: "IBM Plex Sans";
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/ibmplexsans/v8/zYX9KVElMYYaJe8bpLHnCwDKjR7_MIY.ttf)
		format("truetype");
}
@font-face {
	font-family: "IBM Plex Sans";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/ibmplexsans/v8/zYX9KVElMYYaJe8bpLHnCwDKjXr8MIY.ttf)
		format("truetype");
}
@font-face {
	font-family: "IBM Plex Sans";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/ibmplexsans/v8/zYXgKVElMYYaJe8bpLHnCwDKtdY.ttf)
		format("truetype");
}
@font-face {
	font-family: "IBM Plex Sans";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/ibmplexsans/v8/zYX9KVElMYYaJe8bpLHnCwDKjSL9MIY.ttf)
		format("truetype");
}
@font-face {
	font-family: "IBM Plex Sans";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/ibmplexsans/v8/zYX9KVElMYYaJe8bpLHnCwDKjQ76MIY.ttf)
		format("truetype");
}
@font-face {
	font-family: "IBM Plex Sans";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/ibmplexsans/v8/zYX9KVElMYYaJe8bpLHnCwDKjWr7MIY.ttf)
		format("truetype");
}
body {
	margin: 0px;
	font-size: 16px;
}
body::-webkit-scrollbar {
	width: 10px;
}
body::-webkit-scrollbar-track {
	background-color: lightgrey;
	border-radius: 5px;
}
body::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	border-radius: 5px;
}
* {
	box-sizing: border-box;
}
.VM {
	justify-content: center;
	align-items: center;
}
.main {
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
}
.center {
	text-align: center;
}
@media screen and (max-width: 1200px) {
	.main2 {
		padding-left: 10px;
		padding-right: 10px;
	}
}
@font-face {
	font-family: "myColorEmojis";
	src: url("fonts/semj2.ttf") format("truetype");
}
@font-face {
	font-family: "NotoColorEmoji";
	src: url("fonts/NotoColorEmoji.ttf") format("truetype");
}
@font-face {
	font-family: "BPGNinoMtavruli";
	src: url("fonts/bpg_nino_mtavruli_normal.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "Comfortaa";
	src: url("fonts/comfortaa-bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: "Dosis";
	src: url("fonts/dosis-semibold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: "Roboto Geo MT Bold";
	src: url("fonts/roboto-geo-mt-bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
}
.font-roboto-geo-mt-bold {
	font-family: "Roboto Geo MT Bold";
}
@font-face {
	font-family: "Roboto Geo Caps";
	src: url("fonts/roboto-geo-caps.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
.font-roboto-geo-caps {
	font-family: "Roboto Geo Caps";
}
@font-face {
	font-family: "Roboto Geo Nus";
	src: url("fonts/roboto-geo-nus.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
.font-roboto-geo-nus {
	font-family: "Roboto Geo Nus";
}
@font-face {
	font-family: "Roboto Geo Nus Bold";
	src:
		local("roboto-geo-nus-bold"),
		url(fonts/roboto-geo-nus-bold.ttf) format("truetype");
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: "GL Kirovi Bold";
	src:
		local("gl-kirovi-bold"),
		url(fonts/gl-kirovi-bold.ttf) format("truetype");
	font-weight: bold;
	font-style: normal;
}
.font-roboto-geo-nus-bold {
	font-family: "Roboto Geo Nus Bold";
}
@font-face {
	font-family: "Tatishvili Metal Bold";
	src: url("fonts/gl-tatishvili-metal-bold.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "Tatishvili Normal";
	src: url("fonts/gl-tatishvili-normal.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "FiraGO";
	src: url("fonts/FiraGO-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "FiraGO Bold";
	src: url("fonts/FiraGO-Bold.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "FirGO Book";
	src: url("fonts/FiraGO-Book.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "gadara";
	src: url("fonts/GADara1981-Bold.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
.loaderDiv {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background:
		url(img/loading-white.svg) center center no-repeat,
		rgba(0, 0, 0, 0.5);
	background-size: 20vmin;
	z-index: 10000;
}
.smallLoading {
	width: 100px;
	height: 100px;
	background: url(img/loading.svg) center center no-repeat;
	background-size: contain;
	margin: 0 auto;
}
.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.no-arrows {
	-moz-appearance: textfield;
}
.mur-paginator {
	text-align: center;
}
.mur-paginator .paginatorPageNum,
.mur-paginator .fakePaginatorPageNum {
	width: 30px;
	height: 30px;
	display: inline-block;
	margin: 5px;
}
.mur-paginator .paginatorPageNum {
	border: 1px solid;
	border-color: #4b4f66;
	font-family: "BPGNinoMtavruli";
	line-height: 30px;
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.2s;
}
.mur-paginator .paginatorPageNum:hover {
	background: rgba(75, 79, 102, 0.3);
	color: #4b4f66;
}
.mur-paginator .paginatorPageNum.paginatorPageNumCurrent {
	background: #4b4f66;
	color: white;
}
.htmlContentText p,
.htmlContentText ol,
.htmlContentText ul,
.htmlContentText pre,
.htmlContentText blockquote,
.htmlContentText h1,
.htmlContentText h2,
.htmlContentText h3,
.htmlContentText h4,
.htmlContentText h5,
.htmlContentText h6 {
	margin: 0;
	padding: 0;
}
.adminPrimaryButton {
	width: 250px;
	height: 53px;
	background: linear-gradient(30deg, #f6750b, #ffa115);
	box-shadow: 0 0 20px rgba(255, 161, 21, 0.3);
	color: white;
	font-family: "BPGNinoMtavruli";
	font-size: 23px;
	font-weight: bold;
	border-radius: 30px;
	border: none;
	outline: none;
	cursor: pointer;
	margin: 20px 0px;
	transition: 0.3s;
	transition-timing-function: cubic-bezier(0.04, 1.47, 0.57, 0.93);
}
.adminPrimaryButton:hover {
	transform: scale(1.1);
	color: white;
}
.adminPrimaryButton > * {
	vertical-align: middle;
}
.main-button {
	width: 200px;
	height: 40px;
	border-radius: 20px;
	border: 1px solid #fe2f73;
	color: #fe2f73;
	font-family: "BPGNinoMtavruli";
	font-weight: bold;
	font-size: 18px;
	cursor: pointer;
	outline: none;
	background-color: transparent;
	transition: all 0.3s;
}
.main-button:hover,
.main-button:focus,
.main-button.active {
	background-color: #fe2f73;
	color: white;
}
.main-button:focus {
	box-shadow: 0 0 5px #fe2f73;
}
.contentLoading {
	background: transparent;
	overflow: hidden;
	position: relative;
}
.contentLoading::after {
	content: "";
	animation: contentLoadingAnimation 3s infinite;
	position: absolute;
	background: linear-gradient(
		to right,
		#f0f0f0 33%,
		#e0e0e0 50%,
		#f0f0f0 66%
	);
	height: 100%;
	width: 300%;
	top: 0;
	left: 0;
}
@keyframes contentLoadingAnimation {
	0% {
		transform: translateX(-66%);
	}
	100% {
		transform: translateX(0%);
	}
}
.tvschool-teacher-page {
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: calc(100vh - 120px);
}
.fb_dialog {
	z-index: 20 !important;
}
@media not screen and (hover: none) and (pointer: coarse) {
	[data-testid="dialog_iframe"]:not(.fb_customer_chat_bounce_in_v2):not(
			.fb_customer_chat_bounce_out_v2
		) {
		max-height: 0 !important;
	}
}
.hidden-number-spinners::-webkit-outer-spin-button,
.hidden-number-spinners::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

