.swithcerContainer {
	display: block;
	position: relative;
	margin-right: auto;
}
.swithcerContainer > .selected {
	color: white;
	padding: 8px 18px;
	border-radius: 8px;
	margin-top: 10px;
	display: flex;
	font-family: "Roboto Geo Nus";
}
.swithcerContainer > .selected .title {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 250px;
}
.listContainer {
	position: absolute;
	padding-top: 6px;
	width: 100%;
}
.listContainer > .list {
	background: white;
	padding: 5px 0;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.2) 0 2px 9px;
	overflow: hidden;
}
.listContainer > .list > .item {
	padding: 10px;
	cursor: pointer;
	transition: 0.1s;
}
.listContainer > .list > .item:hover {
	background: #e7e7e7;
}
