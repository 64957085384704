.modal_container {
	position: fixed;
	width: 262px;
	z-index: 2000;
	background-color: white;
	border-radius: 20px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	text-align: center;
	padding: 7px 0 0;
	transition: all 0.1s;
	font-family: "Roboto Geo Caps";
}
.modal_container > * {
	margin: 0;
	padding: 0;
}
.modal_content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 15px;
}
.preview_row {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.preview_row__date_wrapper .preview_row__hours {
	width: 50px;
	display: inline-block;
}
