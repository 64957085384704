.editor {
	box-sizing: border-box;
	border: 1px solid #ddd;
	cursor: text;
	padding: 16px;
	border-radius: 2px;
	margin-bottom: 2em;
	box-shadow: inset 0px 1px 8px -3px #ababab;
	background: #fefefe;
}
.DraftEditor-editorContainer figure {
	margin: 0;
}
.editor :global(.public-DraftEditor-content) {
	min-height: 140px;
}
.DraftEditor-root .public-DraftEditorPlaceholder-root {
	z-index: auto;
}
.inline-toolbar {
	left: 50%;
	transform: translate(-50%) scale(0);
	position: absolute;
	border: 1px solid #ddd;
	background: #fff;
	border-radius: 2px;
	box-shadow: 0px 1px 3px 0px #dcdcdc;
	z-index: 2;
	box-sizing: border-box;
	display: flex;
}
.inline-toolbar-button {
	background: #fbfbfb;
	color: #888;
	font-size: 18px;
	border: 0;
	padding-top: 5px;
	vertical-align: bottom;
	height: 34px;
	width: 36px;
	cursor: pointer;
}
.inline-toolbar-button-active {
	background: #efefef;
	color: #444;
}
.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
	text-align: inherit;
}
.public-DraftStyleDefault-block {
	text-align: inherit;
}
.contentEditableMathEquationSpan {
	background-color: #cfdeec;
}
.inline-toolbar-button-wrapper {
	display: inline-block;
}
