.header {
	width: 100%;
	height: 55px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 50px;
	position: fixed;
	z-index: 1000;
	top: 0;
	background-color: white;
}
@media screen and (max-width: 600px) {
	.header {
		padding: 0 10px;
	}
}
.header.colored {
	color: white;
	background-color: #5273e6;
}
.header.colored .logo {
	color: white;
}
.header.colored .nllogo {
	color: white;
}
.header.colored .headerButton,
.header.colored .headerRegistrationButton {
	color: white;
}
.containerPlaceholder {
	height: 55px;
}
.logo {
	width: 82px;
	color: #5273e6;
	cursor: pointer;
}
.nllogo {
	width: 123px;
	color: #5273e6;
	cursor: pointer;
}
.logoAndFaq {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #5273e6;
}
.calls {
	color: #5273e6;
	background-color: white;
	margin-left: 20px;
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: "Roboto Geo Caps";
	font-size: 14px;
}
.callsColored {
	color: white;
	background-color: #5273e6;
	margin-left: 10px;
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: "Roboto Geo Caps";
	font-size: 14px;
}
.headerButtonsContainer {
	display: inline-flex;
	align-items: center;
	justify-content: center;
}
.headerButton,
.headerRegistrationButton,
.videoInstructionButton,
.faqButton {
	color: #5273e6;
	font-family: "Roboto Geo Caps";
	margin-left: 20px;
	cursor: pointer;
	padding: 5px 0;
	border-bottom: 1px solid transparent;
	transition: 0.2s;
}
.headerButton:hover,
.headerRegistrationButton:hover,
.videoInstructionButton:hover,
.faqButton:hover {
	border-bottom: 1px solid;
}
@media screen and (max-width: 500px) {
	.headerButton,
	.headerRegistrationButton,
	.videoInstructionButton,
	.faqButton {
		margin-left: 5px;
	}
}
.videoInstructionButton {
	display: flex;
	align-items: center;
	justify-content: center;
}
@media screen and (max-width: 1100px) {
	.videoInstructionButton {
		position: fixed;
		z-index: 100;
		transform: rotate(-90deg);
		bottom: 26vh;
		right: -53px;
		padding: 10px 20px;
		border: 1px solid;
		border-radius: 17px 17px 0 0;
		background-color: white;
	}
	.videoInstructionButton:hover {
		background-color: #5273e6;
		color: white;
	}
}
@media screen and (max-width: 350px) {
	.videoInstructionButton {
		bottom: 30vh;
		right: -34px;
		padding: 6px 5px;
		border-radius: 10px 10px 0 0;
		font-size: 13px;
	}
}
.signUpAndSignInButtons {
	width: max-content;
	display: flex;
	align-items: center;
	justify-content: center;
}
.headerRegistrationButton {
	font-weight: bold;
	font-size: 17px;
}
.focusedHeaderButton {
	border-bottom: 1.5px solid;
}
.roleContainer {
	height: calc(100vh - 112px);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
@media screen and (min-width: 801px) {
	.roleContainer {
		background:
			url("../../../tv-school-landing/styles/imgs/illustration.png") left
				10% bottom 8% no-repeat,
			left;
		background-size: 450px, contain;
	}
}
@media screen and (min-width: 1600px) {
	.roleContainer {
		background:
			url("../../../tv-school-landing/styles/imgs/illustration.png") left
				10% bottom 8% no-repeat,
			left;
		background-size: 450px, contain;
	}
}
@media screen and (min-width: 1900px) {
	.roleContainer {
		background:
			url("../../../tv-school-landing/styles/imgs/illustration.png") left
				10% bottom 8% no-repeat,
			left;
		background-size: 450px, contain;
	}
}
@media screen and (max-width: 800px) {
	.roleContainer {
		height: calc(100vh - 55px);
		margin-top: 55px;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		height: fit-content;
	}
}
@media screen and (max-width: 800px) {
	.roleContainer {
		background-size: 334px auto;
	}
}
@media screen and (max-width: 375px) {
	.roleContainer {
		margin-top: 30px;
	}
}
.roleContainerNL {
	height: calc(100vh - 112px);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
@media screen and (min-width: 801px) {
	.roleContainerNL {
		background:
			url("../../../tv-school-landing/styles/imgs/illustration-nl.png")
				left 10% bottom 8% no-repeat,
			left;
		background-size: 450px, contain;
	}
}
@media screen and (min-width: 1600px) {
	.roleContainerNL {
		background:
			url("../../../tv-school-landing/styles/imgs/illustration-nl.png")
				left 10% bottom 8% no-repeat,
			left;
		background-size: 450px, contain;
	}
}
@media screen and (min-width: 1900px) {
	.roleContainerNL {
		background:
			url("../../../tv-school-landing/styles/imgs/illustration-nl.png")
				left 10% bottom 8% no-repeat,
			left;
		background-size: 450px, contain;
	}
}
@media screen and (max-width: 800px) {
	.roleContainerNL {
		height: calc(100vh - 55px);
		margin-top: 55px;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		height: fit-content;
	}
}
@media screen and (max-width: 800px) {
	.roleContainerNL {
		background-size: 334px auto;
	}
}
@media screen and (max-width: 375px) {
	.roleContainerNL {
		margin-top: 30px;
	}
}
.roleTexts {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-start;
	margin-right: 90px;
	border: 3px solid #5273e6;
	padding: 35px;
	border-radius: 20px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	background-color: white;
}
@media screen and (max-width: 1300px) {
	.roleTexts {
		margin-right: 45px;
	}
}
@media screen and (max-width: 1250px) {
	.roleTexts {
		margin-right: 15px;
	}
}
@media screen and (max-width: 800px) {
	.roleTexts {
		width: 100%;
		margin-right: 0;
		margin-bottom: 20px;
		padding: 10px;
		box-shadow: none;
		border: none;
	}
}
.landingTitle {
	width: 590px;
	max-width: 100%;
	margin-bottom: 20px;
	background: white;
}
@media screen and (max-width: 500px) {
	.landingTitle {
		padding: 10px;
	}
}
@media screen and (max-width: 1250px) {
	.landingTitle {
		width: 470px;
	}
}
.landingTitle__h1 {
	font-size: 18px;
	text-align: center;
	color: #5273e6;
	font-family: "Roboto Geo Caps";
	font-weight: normal;
}
@media screen and (max-width: 1400px) {
	.landingTitle__h1 {
		font-size: 16px;
	}
}
.mainTitle,
.mainTitleDutch {
	font-family: "gadara";
	font-size: 53px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.35;
	letter-spacing: normal;
	background-color: white;
	text-align: left;
	color: #5273e6;
}
@media screen and (min-width: 1600px) {
	.mainTitle,
	.mainTitleDutch {
		font-size: 63px;
	}
}
@media screen and (min-width: 1900px) {
	.mainTitle,
	.mainTitleDutch {
		font-size: 83px;
	}
}
@media screen and (max-width: 1000px) {
	.mainTitle,
	.mainTitleDutch {
		width: 100%;
	}
}
@media screen and (max-width: 800px) {
	.mainTitle,
	.mainTitleDutch {
		text-align: center;
		margin-bottom: 20px;
	}
}
@media screen and (max-width: 600px) {
	.mainTitle,
	.mainTitleDutch {
		font-size: 24px;
	}
}
.mainTitleDutch {
	font-family: "Dosis";
	font-size: 43px;
}
.secondaryTitle {
	width: 496px;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.35;
	letter-spacing: normal;
	text-align: left;
	color: #636363;
	font-family: "Roboto Geo Nus";
	margin-top: 90px;
}
@media screen and (max-width: 1000px) {
	.secondaryTitle {
		width: 100%;
	}
}
@media screen and (max-width: 800px) {
	.secondaryTitle {
		display: none;
	}
}
.choose {
	color: #636363;
	font-family: "Roboto Geo Caps";
	font-weight: bold;
	font-size: 18px;
}
@media screen and (max-width: 800px) {
	.choose {
		margin-top: 35px;
	}
}
@media screen and (max-width: 400px) {
	.choose {
		margin-top: 8px;
	}
}
.roleButtonsContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 496px;
	margin-top: 30px;
}
@media screen and (max-width: 1000px) {
	.roleButtonsContainer {
		width: 100%;
	}
}
@media screen and (max-width: 800px) {
	.roleButtonsContainer {
		justify-content: space-around;
	}
}
.roleButton {
	width: 220px;
	height: 33px;
	border-radius: 7px;
	background-color: #5273e6;
	color: white;
	font-family: "Roboto Geo Caps";
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	cursor: pointer;
}
@media screen and (max-width: 1000px) {
	.roleButton {
		width: calc(50% - 20px);
	}
}
.timetableContainer {
	width: calc(100% - 200px);
	margin: 30px auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
@media screen and (max-width: 1000px) {
	.timetableContainer {
		width: calc(100% - 40px);
	}
}
.daysContainer {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	max-width: 1203px;
}
@media screen and (max-width: 1300px) {
	.daysContainer {
		justify-content: space-around;
	}
}
@media screen and (min-width: 1800px) {
	.daysContainer {
		max-width: 1485px;
	}
}
.day {
	width: 159px;
	height: 40px;
	border-radius: 7px;
	border: solid 1px #636363;
	background-color: white;
	color: #636363;
	text-align: center;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-family: "Roboto Geo Caps";
	transition: 0.2s;
	margin-top: 15px;
}
.day:hover {
	border: solid 1px white;
	background-color: #5273e6;
	color: white;
}
@media screen and (min-width: 1800px) {
	.day {
		width: 197px;
		height: 50px;
		font-size: 18px;
	}
}
.dayFocused {
	border: solid 1px white;
	background-color: #5273e6;
	color: white;
}
.classroomsContainter {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px 20px;
	margin: 30px auto 50px auto;
	max-width: 1203px;
}
@media screen and (max-width: 600px) {
	.classroomsContainter {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
}
@media screen and (min-width: 1800px) {
	.classroomsContainter {
		max-width: 1485px;
	}
}
.classroomsContainterOnlyOneClassroom {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 30px auto 50px auto;
	width: 50%;
}
@media screen and (max-width: 600px) {
	.classroomsContainterOnlyOneClassroom {
		width: 100%;
	}
}
.classroomContainer {
	width: 100%;
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	border-radius: 15px;
	border: solid 1px #5273e6;
	background-color: var(--white);
	font-family: "Roboto Geo Nus";
	padding: 15px 30px;
}
@media screen and (max-width: 1000px) {
	.classroomContainer {
		padding: 10px;
	}
}
@media screen and (max-width: 600px) {
	.classroomContainer {
		margin: 10px auto;
	}
}
.classromName {
	font-size: 21px;
	width: 100%;
	text-align: center;
	font-family: "Roboto Geo Caps";
	color: #5273e6;
	font-weight: bold;
}
.subjectWithTime {
	width: 100%;
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	margin: 10px 0;
}
.subject {
	margin-left: 30px;
	color: #636363;
	width: calc(100% - 130px);
	word-break: break-word;
}
.time {
	color: #5273e6;
	width: fit-content;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
}
.illustration {
	width: 330px;
}
@media screen and (max-width: 800px) {
	.illustration {
		width: 250px;
	}
}
.loginInput {
	width: 250px;
	height: 40px;
	border-radius: 14px;
	border: solid 1px #707070;
	padding-left: 20px;
	font-family: "Roboto Geo Nus";
	margin: 10px 0;
	outline: none;
}
.loginInput[type="number"] {
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	appearance: textfield;
}
.loginInput[type="number"]::-webkit-inner-spin-button,
.loginInput[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
}
@media screen and (min-width: 1800px) {
	.loginInput {
		width: 350px;
		height: 60px;
		font-size: 20px;
	}
}
@media screen and (max-width: 800px) {
	.loginInput {
		border-radius: 10px;
		border: solid 1px #8a8a8a;
		margin: 5px 0;
		height: 50px;
	}
}
.forgetPassword {
	width: 100%;
	color: #707070;
	text-align: left;
	font-size: 12px;
	font-family: "Roboto Geo Nus";
	padding-left: 20px;
	border-bottom: 1px solid transparent;
	cursor: pointer;
	transition: 0.2s;
}
@media screen and (max-width: 800px) {
	.forgetPassword {
		padding-left: 0;
	}
}
@media screen and (min-width: 1800px) {
	.forgetPassword {
		font-size: 14px;
	}
}
.signInButton {
	border-radius: 14px;
	background-color: #5273e6;
	color: white;
	font-family: "Roboto Geo Caps";
	text-align: center;
	width: 250px;
	height: 40px;
	border: none;
	font-size: 17px;
	font-weight: bold;
	margin: 20px 0;
	outline: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}
@media screen and (min-width: 1800px) {
	.signInButton {
		width: 350px;
		height: 60px;
		font-size: 20px;
	}
}
@media screen and (max-width: 800px) {
	.signInButton {
		border-radius: 10px;
		margin: 5px 0 15px;
		height: 50px;
	}
}
.registerButtonClassname {
	color: #5273e6;
	font-family: "Roboto Geo Caps";
	cursor: pointer;
	transition: 0.2s;
	border: none;
	border-bottom: 1px solid transparent;
	padding-bottom: 2px;
	background: transparent;
	background-color: white;
	outline: none;
}
.registerButtonClassname:hover {
	border-bottom: 1px solid;
}
@media screen and (min-width: 1800px) {
	.registerButtonClassname {
		font-size: 19px;
	}
}
.scroollArrowContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px 0;
}
@media screen and (max-width: 800px) {
	.scroollArrowContainer {
		display: none;
	}
}
.videoIcon {
	width: 20px;
	margin-right: 4px;
}
.videoContainer {
	width: calc(60vw);
	height: calc(60vw / 1.8);
	max-height: 70vh;
}
@media screen and (max-width: 801px) {
	.videoContainer {
		width: calc(80vw);
		height: calc(80vw / 1.8);
	}
}
.closeIcon {
	width: 12px;
	cursor: pointer;
}
.popupBodyClassname {
	padding: 16px 23px;
	padding-top: 0;
}
.logonl {
	width: 125px;
	cursor: pointer;
}
.areYouRegisteredNotifier {
	font-family: "Roboto Geo Nus";
	font-size: 16px;
	line-height: 1.35;
	text-align: center;
	color: #626a84;
	background: white;
}
.authButtonsContainer {
	text-align: center;
	margin-top: 10px;
}
.authButtonsContainer > .authButton {
	margin: 15px;
}
@media screen and (max-width: 470px) {
	.authButtonsContainer {
		margin-top: 20px;
	}
	.authButtonsContainer > .authButton {
		margin: 10px 0;
	}
}
.authButton {
	width: 213px;
	height: 43px;
	border-radius: 16px;
	border: solid 1px #5273e6;
	background-color: white;
	font-family: "Roboto Geo MT Bold";
	font-size: 19px;
	font-weight: bold;
	line-height: 1.21;
	color: #5273e6;
	cursor: pointer;
	outline: none;
}
.authButton:hover {
	color: white;
	background-color: #5273e6;
}
.exclamation {
	font-family: Dosis;
	font-size: 40px;
	font-weight: 800;
	line-height: 1.26;
	text-align: center;
	color: #5273e6;
	vertical-align: middle;
	margin-right: 10px;
}

