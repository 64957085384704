.optionsButtonContainer {
	position: absolute;
	right: 27px;
	width: max-content;
	border: 1px rgba(0, 0, 0, 0.05) solid;
	border-radius: 5px;
	padding: 10px 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	font-family: "Roboto Geo Nus";
	font-weight: bold;
	display: flex;
	flex-direction: column;
	z-index: 1000;
	background-color: white;
}
.optionsButtonRightIcon,
.optionsButtonLeftIcon {
	display: flex;
	align-items: center;
	cursor: pointer;
	transition: 0.2s;
	padding: 10px 15px;
	width: 100%;
	color: #424242;
}
@media screen and (max-width: 400px) {
	.optionsButtonRightIcon,
	.optionsButtonLeftIcon {
		font-size: 14px;
	}
}
.optionsButtonRightIcon:hover,
.optionsButtonLeftIcon:hover {
	background-color: rgba(0, 0, 0, 0.1);
}
.optionsButtonLeftIcon {
	justify-content: flex-start;
}
.optionsButtonRightIcon {
	justify-content: flex-end;
}
.iconContainer {
	position: relative;
	cursor: pointer;
}
