.box_container {
	max-width: 1350px;
	width: 100%;
	margin: -130px auto 0;
	text-align: center;
}
@media screen and (max-width: 1000px) {
	.box_container {
		margin: -80px auto 0;
	}
}
.certificate_box {
	width: 395px;
	background-color: #fff;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	margin: 20px;
	border-radius: 20px;
	display: inline-flex;
	position: relative;
	flex-direction: column;
	font-family: "Roboto Geo Caps";
	cursor: pointer;
	overflow: hidden;
}
.certificate_box__img {
	width: 100%;
	height: auto;
}
.certificate_box__img__cont {
	width: 100%;
	height: 279px;
	border-radius: 20px;
	overflow: hidden;
}
.certificate_box__title {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 70px;
	font-size: 20px;
	color: #626a84;
}
@media screen and (max-width: 1400px) {
	.certificate_box {
		width: 300px;
	}
	.certificate_box__title {
		height: 50px;
		font-size: 16px;
	}
}
@media screen and (max-width: 1400px) {
	.certificate_box__img__cont {
		height: 212px;
	}
}
@media screen and (max-width: 400px) {
	.certificate_box {
		margin: 10px 0;
	}
	.certificate_box__title {
		font-size: 12px;
	}
}
