.sendTestToNewClass {
	width: 312px;
	margin: 10px;
	height: 280px;
	border-radius: 20px;
	box-shadow: 0 0 6px 0 #00000029;
	background-color: #5273e6;
	padding: 35px;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	font-family: "Roboto Geo Caps";
	cursor: pointer;
	transition: all 0.1s;
	vertical-align: middle;
}
.sendTestToNewClass:hover {
	box-shadow: 0 0 10px 0 rgba(82, 115, 230, 0.5);
}
.sendTestToNewClass .sendTestToNewClassBg {
	background-image: url(./imgs/NewHomework.svg);
	background-repeat: no-repeat;
	background-size: 80px 80px;
	background-position: center;
	width: 100px;
	height: 100px;
}
.sendTestToNewClass .sendCognitiveAssignmentBuld {
	background-repeat: no-repeat;
	background-size: 80px 80px;
	background-position: center;
	height: 160px;
}
.sendTestToNewClass .title {
	color: #fff;
}
.sendTestToNewClass .sendCognitiveTitle {
	color: #5273e6;
	font-weight: bold;
	font-size: 20px;
}
@media screen and (max-width: 1600px) {
	.sendTestToNewClass {
		width: 280px;
		height: 204px;
		border-radius: 16px;
		padding: 20px;
	}
	.sendTestToNewClass .sendTestToNewClassBg {
		background-size: 80px 80px;
		width: 100px;
		height: 100px;
	}
	.sendTestToNewClass .sendCognitiveAssignmentBuld {
		background-size: 80px 80px;
		width: 120px;
		height: 120px;
	}
	.sendTestToNewClass .sendCognitiveTitle {
		font-size: 16px;
	}
}
.conferenceBox {
	width: 312px;
	height: 204px;
	margin: 10px;
	border-radius: 20px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	background-color: white;
	padding: 20px;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	font-family: "Roboto Geo Caps";
	vertical-align: middle;
}
.conferenceBox h1 {
	margin: 5px;
	font-size: 14px;
	color: #626a84;
	font-family: "Roboto Geo Nus";
}
@media screen and (max-width: 1600px) {
	.conferenceBox {
		width: 280px;
		height: 204px;
		border-radius: 16px;
	}
}
.classroomBox {
	width: 312px;
	height: 280px;
	margin: 10px;
	border-radius: 20px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	background-color: white;
	padding: 40px 20px 20px;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	font-family: "Roboto Geo Caps";
	vertical-align: middle;
}
.classroomBox:hover {
	cursor: pointer;
	box-shadow: 0 0 6px 6px rgba(226, 230, 238, 0.5);
}
@media screen and (max-width: 1600px) {
	.classroomBox {
		width: 280px;
		height: 204px;
		border-radius: 16px;
		padding: 20px;
	}
}
.centerFlex {
	display: flex;
	justify-content: center;
	align-items: center;
}
.groupBox {
	width: 644px;
	height: 280px;
	border-radius: 20px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	background-color: white;
	padding: 20px;
	margin: 10px;
	display: inline-flex;
	align-items: center;
	justify-content: space-around;
	vertical-align: middle;
}
.groupBox .illustration {
	background-repeat: no-repeat;
	height: 215px;
}
.groupBox .leftContainer {
	color: #626a84;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-family: "Roboto Geo Nus";
}
.groupBox .leftContainer .classInfo {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}
.groupBox .leftContainer .classInfo .type {
	font-size: 14px;
}
.groupBox .leftContainer .classInfo .grade {
	margin: 15px 0 40px;
	font-family: "Roboto Geo MT Bold";
	font-size: 30px;
}
@media screen and (max-width: 1600px) {
	.groupBox {
		width: 580px;
		height: 204px;
		border-radius: 16px;
		padding: 20px;
	}
	.groupBox .illustration {
		height: 155px;
	}
}
@media screen and (max-width: 768px) {
	.groupBox {
		width: 280px;
	}
	.groupBox .illustration {
		display: none;
	}
}
.classroomsContainer {
	overflow: hidden;
	padding-bottom: 10px;
	padding-top: 5px;
	display: flex;
	width: 100%;
}
@media screen and (max-width: 768px) {
	.classroomsContainer {
		margin-top: 20px;
		justify-content: center;
	}
}
.marginContainer {
	display: flex;
	justify-content: space-between;
	flex-flow: wrap;
}
@media screen and (max-width: 600px) {
	.marginContainer {
		justify-content: center;
	}
}
.marginContainerStart {
	display: flex;
	justify-content: space-between;
	flex-flow: wrap;
	justify-content: flex-start;
}
@media screen and (max-width: 600px) {
	.marginContainerStart {
		justify-content: center;
	}
}
@media screen and (max-width: 768px) {
	.marginContainerStart {
		justify-content: center;
	}
}
.classname {
	font-size: 24px;
	font-weight: bold;
	text-align: center;
	color: #626a84;
}
@media screen and (max-width: 1600px) {
	.classname {
		font-size: 18px;
	}
}
.subject {
	font-size: 18px;
	text-align: center;
	color: #626a84;
}
@media screen and (max-width: 1600px) {
	.subject {
		font-size: 15px;
	}
}
.newHomework {
	width: 260px;
	height: 50px;
	border-radius: 10px;
	background-color: #5273e6;
	color: white;
	cursor: pointer;
	transition: 0.2s;
	border: none;
	font-family: "Roboto Geo Caps";
	outline: none;
	letter-spacing: 1px;
}
.newHomework:hover {
	box-shadow: -3px 5px 10px 0 rgba(82, 115, 230, 0.5);
}
@media screen and (max-width: 1600px) {
	.newHomework {
		width: 220px;
		height: 32px;
		border-radius: 9px;
	}
}
.videolink {
	width: 260px;
	height: 50px;
	border-radius: 15px;
	font-size: 18px;
	background-color: #5273e6;
	color: white;
	cursor: pointer;
	transition: 0.2s;
	border: none;
	font-family: "Roboto Geo Caps";
	outline: none;
	display: flex;
	justify-content: center;
	align-items: center;
}
.videolink .icon {
	margin-left: 10px;
	color: inherit;
}
.videolink:hover {
	box-shadow: -3px 5px 10px 0 rgba(64, 111, 239, 0.5);
}
@media screen and (max-width: 1600px) {
	.videolink {
		width: 220px;
		height: 32px;
		border-radius: 9px;
		font-size: 14px;
	}
	.videolink .icon {
		margin-left: 10px;
		color: inherit;
	}
}
.nolink {
	cursor: not-allowed;
	background-color: #d1ccd1;
}
.nolink:hover {
	box-shadow: unset;
}
.activelink {
	background-color: #37d7a0;
}
.activelink:hover {
	box-shadow: unset;
}
.sentHomeworks {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 32px;
	font-size: 16px;
	background: none;
	border: none;
	outline: none;
	color: #5273e6;
	font-family: "Roboto Geo Caps";
	cursor: pointer;
	transition: 0.2s;
}
.sentHomeworks > svg {
	width: 24px;
}
.sentHomeworks:hover .icon {
	transform: rotate(-25deg);
}
.sentHomeworks:hover .enterIcon {
	transform: none;
}
.sentHomeworks:hover :global(.arrow_6xon4hv10) {
	transform: translate(14%);
}
@media screen and (max-width: 1600px) {
	.sentHomeworks {
		font-size: 14px;
		height: 32px;
	}
	.sentHomeworks > svg {
		width: 24px;
	}
}
.icon {
	width: 18px;
	margin-right: 7px;
	color: #5273e6;
}
.classrooms {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - 120px);
	padding: 0px 30px;
}
.classrooms .myClassrooms {
	font-family: "Roboto Geo MT Bold";
	font-size: 30px;
	font-weight: bold;
	text-align: center;
	color: #5273e6;
}
@media screen and (max-width: 1400px) {
	.classroomBoxsContainers {
		padding: 0 40px;
	}
}
@media screen and (max-width: 1024px) {
	.classroomBoxsContainers {
		padding: 0 32px;
	}
}
@media screen and (max-width: 768px) {
	.classroomBoxsContainers {
		padding: 0;
	}
}
.touBanner {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: flex-start;
	cursor: pointer;
	padding-left: 60px;
	margin-top: 50px;
}
@media screen and (max-width: 900px) {
	.touBanner {
		display: none;
	}
}
.fixedTouBanner {
	position: fixed;
	left: 0;
	bottom: 50px;
}
.touLogo {
	width: 125px;
}
.touText {
	font-size: 16px;
	margin-left: 15px;
	font-family: "Roboto Geo Nus";
	color: #626a84;
}
.tou {
	display: inline-block;
	color: #5273e6;
}
.createClassroom {
	margin: 10px;
	width: 312px;
	height: 280px;
	border-radius: 20px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	background-color: #5273e6;
	padding: 20px;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-family: "Roboto Geo Caps";
	cursor: pointer;
	transition: all 0.1s;
	vertical-align: middle;
	text-align: center;
	color: white;
}
.createClassroom.whiteTheme {
	background-color: white;
	color: #5273e6;
}
.createClassroom:hover {
	box-shadow: 0 0 10px 0 rgba(82, 115, 230, 0.5);
}
@media screen and (max-width: 1600px) {
	.createClassroom {
		width: 280px;
		height: 204px;
		border-radius: 16px;
	}
}
.createClassroom .createClassroomBg {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top;
	width: 150px;
	height: 100px;
	margin-bottom: 40px;
}
@media screen and (max-width: 1600px) {
	.createClassroom .createClassroomBg {
		width: 120px;
		height: 80px;
		margin-bottom: 30px;
	}
}
.createClassroom .title {
	position: relative;
	bottom: 8px;
	font-size: 26px;
}
@media screen and (max-width: 1600px) {
	.createClassroom .title {
		bottom: 8px;
		font-size: 20px;
	}
}
.archivedClassrooms {
	cursor: pointer;
	width: 200px;
	text-align: center;
	display: inline-block;
	position: relative;
	margin-top: 30px;
	margin-bottom: 30px;
	border-radius: 8px;
}
.archivedClassrooms span {
	font-family: "Roboto Geo Caps";
	font-weight: bold;
	position: relative;
	vertical-align: middle;
}
.archivedClassroomsOpen {
	color: #5273e6;
}
.archivedClassroomsClosed {
	color: #58607c;
}
.emptyArchive {
	color: black;
	font-family: "Roboto Geo Caps";
	font-weight: bold;
	margin-top: 50px;
}
.makeClassroomArchive {
	height: 10px;
	width: 200px;
	text-align: center;
}
.makeClassroomArchive span {
	color: #58607c;
	font-size: 10px;
	cursor: pointer;
	position: relative;
	top: 5px;
	padding: 2px 5px;
	border-radius: 6px;
}
.makeClassroomArchive span:hover {
	background-color: #e4e6f1;
	transition-duration: 0.2s;
}

