.profileNameContainer {
	width: 500px;
	max-width: 100%;
	height: 388px;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	box-shadow: 0 0 52px rgba(0, 0, 0, 0.23);
	border-radius: 28px;
	background-color: white;
	position: relative;
}
.userIconAndNameContainer {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	padding: 20px 0px 5px 0px;
	width: 75%;
	border-bottom: 1px solid #37a0f4;
}
.userNameAndSurname {
	color: #252423;
	font-weight: bold;
	font-family: "Roboto Geo Nus";
	margin-left: 23px;
	font-size: 18px;
}
.userIconContainer {
	width: 57px;
	height: 57px;
	cursor: pointer;
	border-radius: 100%;
	background: rgba(255, 255, 255, 0);
	color: #37a0f4;
}
.profileInfosContainer {
	margin: 27px 0 18px 0;
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
}
.profileValuesContainer {
	color: #37a0f4;
	font-size: 16px;
	font-family: "Roboto Geo Nus";
	font-weight: bold;
	text-align: center;
	display: flex;
	width: 53%;
	align-items: center;
	justify-content: center;
}
@media screen and (max-width: 500px) {
	.profileValuesContainer {
		width: 78%;
	}
}
.profileText {
	display: table-cell;
	padding: 10px 20px;
	font-family: "Roboto Geo Nus";
	color: #252423;
	font-weight: lighter;
	width: 50%;
	text-align: left;
}
.profileValue {
	display: table-cell;
	padding: 10px 20px;
	word-break: break-word;
	font-weight: bold;
	font-family: "Roboto Geo Nus";
	text-align: left;
	width: 100%;
	text-align: center;
}
.buttonsContainer {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.changePasswordButton {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 10px;
	background-image: linear-gradient(90deg, #22bfe0, #569ae7);
	color: white;
	outline: none;
	border: none;
	border-radius: 20px;
	font-size: 14px;
	font-family: "Roboto Geo Nus";
	transition: 0.2s;
	cursor: pointer;
	font-weight: bold;
	margin-top: 17px;
}
.changePasswordButton:hover {
	font-weight: lighter;
	background-image: linear-gradient(-90deg, #22bfe0, #569ae7);
}
.exitButton {
	background-color: #5273e6;
	color: white;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #5273e6;
	outline: none;
	font-size: 13px;
	font-family: "Roboto Geo Nus";
	transition: 0.2s;
	font-weight: bold;
	cursor: pointer;
}
.exitButtonContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	left: 0;
	bottom: 0;
	position: fixed;
	font-family: "Roboto Geo Nus";
	font-size: 15px;
	outline: none;
	cursor: pointer;
	margin-left: 29px;
	margin-bottom: 40px;
	color: #4b67d6;
}
.exitButtonContainer:hover {
	color: #4b67d6;
}
@media screen and (max-width: 500px) {
	.exitButtonContainer {
		width: 50px;
	}
	.exitButtonContainer > div {
		display: none;
	}
}
.profileEditButton {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 10px;
	background-image: linear-gradient(90deg, #ff83a9, #ffb993);
	outline: none;
	border: none;
	border-radius: 20px;
	font-size: 14px;
	font-family: "Roboto Geo Nus";
	transition: 0.2s;
	font-weight: bold;
	cursor: pointer;
	color: white;
}
.profileEditButton:hover {
	background-image: linear-gradient(-90deg, #ff83a9, #ffb993);
	font-weight: lighter;
}
.pencilIcon {
	width: 20px;
	height: 20px;
	margin-left: 5px;
}
.input {
	width: 100%;
	padding: 10px 20px;
	word-break: break-word;
	padding: 5px 10px;
	color: #000000;
	border: none;
	outline: none;
	font-size: 14px;
	font-family: "Roboto Geo Nus";
	font-weight: bold;
	border-bottom: 1px #000000 solid;
}
.buttonSaveEditedInfo {
	background-image: linear-gradient(180deg, #35e0c0, #04d1d2);
	color: white;
	border: none;
	outline: none;
	padding: 8px 43px;
	height: 31px;
	border-radius: 15px;
	font-size: 16px;
	font-family: "Roboto Geo Nus";
	transition: 0.2s;
	cursor: pointer;
	margin: 10px auto 20px auto;
	display: flex;
	align-items: center;
	justify-content: center;
}
.buttonSaveEditedInfo:hover {
	background-image: linear-gradient(180deg, #04d1d2, #35e0c0);
}
.containerInput {
	width: 65%;
}
.buttonSaveEditedInfoAndGoBackContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.inputAndIcon {
	display: flex;
	align-items: flex-end;
	width: 61%;
	padding: 10px 20px;
	padding: 5px 10px;
	color: #bfbfbf;
	outline: none;
	border-bottom: 1px #bfbfbf solid;
}
.profileEditButtonsContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 23px;
}
.buttonDimensions {
	margin-top: 0px;
	width: 120px;
	height: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 10px;
	font-weight: lighter;
}
.profileNameContainerFirstShadow {
	position: absolute;
	background: rgba(255, 255, 255, 0.62);
	height: 70px;
	width: 447px;
	max-width: 100%;
	margin: 0 auto;
	border-radius: 46px;
	left: 50%;
	transform: translate(-50%, -13%);
}
.profileWith2wave {
	margin: 95px auto 31px auto;
	max-width: 100%;
}
.profileNameContainerSecondShadow {
	position: absolute;
	background: rgba(255, 255, 255, 0.16);
	height: 70px;
	width: 386px;
	max-width: 100%;
	margin: 0 auto;
	border-radius: 46px;
	left: 50%;
	transform: translate(-50%, -27%);
}
.mobileVerificationContainer {
	width: 514px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	text-align: center;
	font-family: "Roboto Geo Nus";
	font-size: 17px;
	max-width: 100%;
	color: #4d4d4d;
}
.mobileVerificationLogout {
	cursor: pointer;
	background-color: #3939d8;
	color: white;
	padding: 7px 15px;
	border-radius: 7px;
	font-size: 15px;
}
.mobileVerificationLogout:hover {
	background-color: #2222e6;
}
.mobileVerificationLogoutContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.resendVerificationCode {
	font-family: "Roboto Geo Nus";
	margin: -6px 0 10px 0;
	cursor: pointer;
	transition: 0.2s;
	width: 222px;
	display: flex;
	font-size: 13px;
	justify-content: flex-end;
}
.resendVerificationCode:hover {
	text-decoration-line: underline;
}
.resendVerificationCodeReceived {
	font-family: "Roboto Geo Nus";
	margin: -6px 0 10px 0;
	cursor: pointer;
	transition: 0.2s;
	display: flex;
	font-size: 13px;
	padding-right: 25px;
	justify-content: flex-end;
}
.laterVerify {
	text-decoration-line: underline;
	font-size: 14px;
	width: 222px;
	margin-top: 10px;
	cursor: pointer;
	transition: 0.2s;
}
.laterVerify:hover {
	color: #0c0b0b;
}
