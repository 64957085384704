.profile_container {
	position: relative;
	letter-spacing: 0.5px;
}
.brandLogo {
	width: 140px;
	position: absolute;
	top: 30px;
	left: 100px;
}
.brandLogo > img {
	width: 100%;
}
@media screen and (max-width: 1500px) {
	.brandLogo {
		width: 100px;
		left: 50px;
		top: 25px;
	}
}
@media screen and (max-width: 1200px) {
	.brandLogo {
		width: 80px;
	}
}
@media screen and (max-width: 1000px) {
	.brandLogo {
		width: 100px;
		top: 20px;
		left: 20px;
	}
}
.title {
	display: flex;
	align-items: center;
	font-family: "Roboto Geo Caps";
	color: #fff;
	font-size: 18px;
	font-weight: bold;
	padding-left: 10px;
	margin: 20px 0 30px;
}
.title .shiftIcon {
	width: 34px;
	height: 35px;
	cursor: pointer;
}
.title .shiftIcon:last-of-type {
	margin-left: -11px;
}
.title .light {
	color: #dddddd;
	cursor: not-allowed;
}
@media screen and (max-width: 1500px) {
	.title {
		font-size: 16px;
		margin: 0 0 20px;
	}
}
.profile_left__sidebar_container {
	background-color: #fff;
	width: 57%;
}
@media screen and (max-width: 1500px) {
	.profile_left__sidebar_container {
		width: 60%;
	}
}
@media screen and (max-width: 1000px) {
	.profile_left__sidebar_container {
		width: 70%;
		height: auto;
		margin: 0 auto;
	}
}
@media screen and (max-width: 600px) {
	.profile_left__sidebar_container {
		width: 100%;
	}
}
.profile_left__sidebar {
	width: 700px;
	margin: 0 auto;
}
@media screen and (max-width: 1500px) {
	.profile_left__sidebar {
		width: 550px;
	}
}
@media screen and (max-width: 1200px) {
	.profile_left__sidebar {
		width: 460px;
	}
}
@media screen and (max-width: 1000px) {
	.profile_left__sidebar {
		width: 100%;
		margin: 0;
		padding: 0 10px 40px;
	}
}
.ceneterFlex {
	display: flex;
	align-items: center;
	justify-content: center;
}
.profile_avatar__wrapper {
	padding-top: 90px;
}
.profile_avatar__wrapper .profile_avatar {
	width: 140px;
	height: 140px;
	border-radius: 50%;
	margin: 0 auto 15px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	background-color: #b4c0e9;
	display: flex;
	align-items: center;
	justify-content: center;
}
.profile_avatar__wrapper .profile_avatar > img {
	width: 80px;
	height: 80px;
}
.profile_avatar__wrapper .secondaryAvatarsContainer {
	margin: 0 auto 0 60%;
	width: 70px;
	height: 70px;
}
.profile_avatar__wrapper .singleSecondaryAvatarContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 70px;
	height: 70px;
	background-color: #eff1f7;
	border-radius: 50%;
	cursor: pointer;
}
.profile_avatar__wrapper .secondRole {
	width: 41.9px;
	height: 40px;
}
.profile_avatar__wrapper .profile_person {
	text-align: center;
	font-family: "Roboto Geo Caps";
	color: #626a84;
	font-size: 16px;
	margin: 0;
}
@media screen and (max-width: 1500px) {
	.profile_avatar__wrapper .profile_avatar {
		width: 100px;
		height: 100px;
		margin: 0 auto 10px;
	}
	.profile_avatar__wrapper .profile_avatar > img {
		width: 60px;
		height: 60px;
	}
	.profile_avatar__wrapper .profile_person {
		font-size: 14px;
	}
}
@media screen and (max-width: 1200px) {
	.profile_avatar__wrapper {
		padding-top: 65px;
	}
	.profile_avatar__wrapper .profile_avatar {
		width: 80px;
		height: 80px;
	}
	.profile_avatar__wrapper .profile_avatar > img {
		width: 40px;
		height: 40px;
	}
	.profile_avatar__wrapper .second_avatar_container {
		width: 60px;
		height: 60px;
		right: -70px;
		top: -28px;
	}
	.profile_avatar__wrapper .secondRole {
		width: 35px;
		height: 35px;
	}
}
@media screen and (max-width: 1000px) {
	.profile_avatar__wrapper {
		padding-top: 0px;
	}
	.profile_avatar__wrapper .profile_avatar {
		width: 100px;
		height: 100px;
		margin: 0 auto 15px;
	}
	.profile_avatar__wrapper .profile_avatar > img {
		width: 60px;
		height: auto;
	}
}
.personal_info_form {
	margin-top: 10px;
}
.personal_info_form__title {
	font-family: "Roboto Geo Caps";
	color: #5273e6;
	font-size: 16px;
	padding-left: 20px;
	margin: 30px 0 20px;
}
.personal_info_form__title > span {
	font-size: 14px;
	color: #626a84;
	font-family: "Roboto Geo Nus";
	font-weight: normal;
}
.personal_info_form__text {
	font-size: 12px;
	font-family: "Roboto Geo Nus";
	font-style: italic;
	color: #626a84;
	padding-left: 20px;
}
.personal_info_form__input_wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}
.personal_info_form__input_wrapper label {
	font-family: "Roboto Geo Caps";
	color: #626a84;
	font-size: 15px;
	padding: 0 0 8px 20px;
	display: block;
}
.personal_info_form__input_wrapper .personal_info_form__input {
	width: 336px;
	margin-bottom: 12px;
}
.personal_info_form__input_wrapper .personal_info_form__input input {
	padding-left: 20px;
	width: 100%;
	height: 60px;
	border-radius: 20px;
	border: solid 1px #626a84;
	background-color: #fff;
	color: #626a84;
	font-size: 20px;
	font-family: "Roboto Geo Nus";
}
.personal_info_form__input_wrapper .personal_info_form__input input:focus {
	outline: none;
}
.personal_info_form__input_wrapper
	.personal_info_form__input
	input[type="number"] {
	width: 441px;
}
.personal_info_form__input_wrapper .personal_info_form__input_long {
	width: calc(100% - 250px);
}
.personal_info_form__input_wrapper .personal_info_form__select {
	width: 31%;
	margin-bottom: 12px;
}
.personal_info_form__input_wrapper select {
	padding-left: 16px;
	display: block;
	width: 100%;
	height: 60px;
	border-radius: 20px;
	border: solid 1px #626a84;
	background-color: #fff;
	color: #626a84;
	font-size: 20px;
	font-family: "Roboto Geo Nus";
}
.personal_info_form__input_wrapper select:focus {
	outline: none;
}
.personal_info_form__input_wrapper .personal_info_form__code_btn {
	font-family: "Roboto Geo Caps";
	width: 232px;
	height: 60px;
	background-color: #fff;
	color: #5273e6;
	font-size: 20px;
	font-weight: bold;
	border-radius: 20px;
	letter-spacing: 1px;
	border: 1px solid #5273e6;
	margin-bottom: 12px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}
.personal_info_form__input_wrapper .personal_info_form__code_btn:focus {
	outline: none;
}
.arrowSvg {
	display: flex;
	align-items: center;
}
.confirmationInput {
	width: 100%;
	padding-left: 20px;
	height: 60px;
	border-radius: 20px;
	border: solid 1px #626a84;
	background-color: #fff;
	margin-bottom: 12px;
	color: #626a84;
	font-size: 20px;
	font-family: "Roboto Geo Nus";
}
.confirmationInput:focus {
	outline: none;
}
@media screen and (max-width: 1500px) {
	.confirmationInput {
		padding-left: 20px;
		height: 40px;
		border-radius: 15px;
	}
}
@media screen and (max-width: 1000px) {
	.confirmationInput {
		padding-left: 20px;
		height: 50px;
	}
}
.confirm_code {
	width: 381px;
}
@media screen and (max-width: 1920px) {
	.confirm_code {
		width: 413px;
	}
}
@media screen and (max-width: 1500px) {
	.confirm_code {
		width: 280px;
	}
}
@media screen and (max-width: 1200px) {
	.confirm_code {
		width: 220px;
	}
}
@media screen and (max-width: 1000px) {
	.confirm_code {
		width: calc(100% - 70px);
		display: inline-block;
		margin-right: 20px;
	}
}
.change_mobile_number {
	width: calc(100% - 245px);
}
.change_mobile_number .confirmed__input {
	width: 100%;
	padding-left: 20px;
	height: 60px;
	border-radius: 20px;
	border: solid 1px #626a84;
	background-color: #fff;
	margin-bottom: 12px;
	color: #626a84;
	font-size: 20px;
	font-family: "Roboto Geo Nus";
}
.change_mobile_number .confirmed__input:focus {
	outline: none;
}
@media screen and (max-width: 1920px) {
	.change_mobile_number {
		width: calc(100% - 215px);
	}
	.change_mobile_number .confirmed__input {
		padding-left: 15px;
		font-size: 18px;
	}
}
@media screen and (max-width: 1500px) {
	.change_mobile_number {
		width: calc(100% - 215px);
	}
	.change_mobile_number .confirmed__input {
		padding-left: 10px;
		height: 40px;
		border-radius: 15px;
		font-size: 14px;
	}
}
@media screen and (max-width: 1200px) {
	.change_mobile_number {
		width: calc(100% - 190px);
	}
}
@media screen and (max-width: 1000px) {
	.change_mobile_number {
		width: 100%;
	}
	.change_mobile_number .confirmed__input {
		padding-left: 20px;
		height: 50px;
		border-radius: 15px;
		font-size: 20px;
	}
}
.confirmed__input {
	width: 336px;
	padding-left: 20px;
	height: 60px;
	border-radius: 20px;
	border: solid 1px #626a84;
	background-color: #fff;
	margin-bottom: 12px;
	color: #626a84;
	font-size: 20px;
	font-family: "Roboto Geo Nus";
}
.confirmed__input:focus {
	outline: none;
}
@media screen and (max-width: 1920px) {
	.confirmed__input {
		width: 100%;
		padding-left: 15px;
		height: 50px;
		border-radius: 15px;
		margin-bottom: 12px;
		font-size: 18px;
	}
}
@media screen and (max-width: 1500px) {
	.confirmed__input {
		width: 270px;
		padding-left: 10px;
		height: 40px;
		border-radius: 10px;
		margin-bottom: 12px;
		font-size: 14px;
	}
}
@media screen and (max-width: 1200px) {
	.confirmed__input {
		width: 100%;
		padding-left: 10px;
		height: 40px;
		border-radius: 10px;
		margin-bottom: 12px;
		font-size: 14px;
	}
}
@media screen and (max-width: 1000px) {
	.confirmed__input {
		width: 100%;
		padding-left: 20px;
		height: 50px;
		border-radius: 15px;
		font-size: 20px;
	}
}
.arrow_loader {
	width: 60px;
	height: 60px;
	border-radius: 20px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	background-color: #fff;
	margin: 0 auto 12px;
	display: flex;
	align-items: center;
	justify-content: center;
}
::placeholder {
	font-size: 14px;
}
.profile_right__sidebar {
	position: fixed;
	top: 0;
	right: 0;
	width: 43%;
	height: 100vh;
	background-color: #b4c0e9;
	border-radius: 30px 0 0 30px;
	overflow-y: auto;
}
@media screen and (max-width: 1920px) {
	.profile_right__sidebar {
		border-radius: 25px 0 0 25px;
	}
}
@media screen and (max-width: 1500px) {
	.profile_right__sidebar {
		width: 40%;
		border-radius: 20px 0 0 20px;
	}
}
@media screen and (max-width: 1400px) {
	.profile_right__sidebar {
		width: 43%;
		border-radius: 25px 0 0 25px;
	}
}
@media screen and (max-width: 1200px) {
	.profile_right__sidebar {
		width: 40%;
		border-radius: 20px 0 0 20px;
	}
}
@media screen and (max-width: 1000px) {
	.profile_right__sidebar {
		display: none;
	}
}
.teacher_right__sidebar_content_wrapper {
	width: 540px;
	margin: 0px auto 0;
	padding-top: 80px;
}
.teacher_right__sidebar_content_wrapper .title {
	display: flex;
	align-items: center;
	font-family: "Roboto Geo Caps";
	color: #fff;
	font-size: 18px;
	font-weight: bold;
	padding-left: 20px;
	margin-top: 20px;
	margin-bottom: 7px;
}
.teacher_right__sidebar_content_wrapper .title .shiftIcon {
	width: 34px;
	height: 35px;
	cursor: pointer;
}
.teacher_right__sidebar_content_wrapper .title .shiftIcon:last-of-type {
	margin-left: -11px;
}
.teacher_right__sidebar_content_wrapper .title .light {
	color: #dddddd;
	cursor: not-allowed;
}
@media screen and (max-width: 1500px) {
	.teacher_right__sidebar_content_wrapper {
		width: 370px;
		padding-top: 70px;
	}
	.teacher_right__sidebar_content_wrapper > h3 {
		font-size: 16px;
		padding-left: 20px;
		margin-top: 20px;
	}
}
@media screen and (max-width: 1200px) {
	.teacher_right__sidebar_content_wrapper {
		padding-top: 45px;
	}
}
.student_right__sidebar_content_wrapper {
	width: 540px;
	margin: 0 auto;
	padding-top: 80px;
}
.student_right__sidebar_content_wrapper > h3 {
	font-family: "Roboto Geo Caps";
	color: #fff;
	font-size: 20px;
	font-weight: bold;
	padding-left: 20px;
	margin-top: 50px;
}
@media screen and (max-width: 1500px) {
	.student_right__sidebar_content_wrapper {
		width: 370px;
		padding-top: 78px;
	}
	.student_right__sidebar_content_wrapper > h3 {
		font-size: 16px;
		padding-left: 20px;
		margin-top: 20px;
	}
}
@media screen and (max-width: 1200px) {
	.student_right__sidebar_content_wrapper {
		padding-top: 60px;
	}
}
@media screen and (max-width: 1000px) {
	.student_right__sidebar_content_wrapper {
		width: 354px;
	}
	.student_right__sidebar_content_wrapper > h3 {
		font-size: 16px;
		padding-left: 10px;
		margin-top: 20px;
	}
}
@media screen and (max-width: 500px) {
	.student_right__sidebar_content_wrapper {
		width: 310px;
	}
	.student_right__sidebar_content_wrapper > h3 {
		font-size: 14px;
		margin-top: 20px;
	}
}
.right__sidebar_content_wrapper {
	width: 540px;
	margin: 100px auto 0;
}
.right__sidebar_content_wrapper > h3 {
	font-family: "Roboto Geo Caps";
	color: #fff;
	font-size: 20px;
	font-weight: bold;
	padding-left: 20px;
	margin-top: 50px;
}
.divider {
	width: 100%;
	height: 2px;
	background-color: #fff;
	margin: 30px 0 30px;
}
.right__sidebar_box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	align-content: flex-start;
	border-radius: 20px;
}
.right__sidebar_box .right__sidebar_box__item {
	width: 260px;
	height: 200px;
	border-radius: 20px;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
}
.right__sidebar_box .right__sidebar_box__item > img {
	height: 78px;
	width: auto;
	margin: 40px 0 10px;
}
.right__sidebar_box .right__sidebar_box__item > p {
	font-family: "Roboto Geo Caps";
	font-size: 20px;
	font-weight: bold;
	color: #626a84;
}
.right__sidebar_box .right__sidebar_box__item .sertification {
	font-size: 15px;
	font-weight: bold;
	text-align: center;
	color: #5273e6;
}
.right__sidebar_box .right__sidebar_box__item .secondary_text {
	margin-top: 15px;
	font-family: "Roboto Geo Nus";
	font-size: 14px;
	width: 70%;
	text-align: center;
	color: #626a84;
}
.right__sidebar_box .right__sidebar_box__item span {
	font-family: "Roboto Geo Caps";
	font-size: 50px;
	font-weight: bold;
	color: #5273e6;
}
.right__sidebar_box .right__sidebar_box__item .blue_text {
	font-family: "Roboto Geo Caps";
	font-size: 22px;
	font-weight: bold;
	text-align: center;
	color: #5273e6;
	margin-top: 0;
}
.right__sidebar_box .teacher_subject_box__item {
	width: 260px;
	height: 200px;
	border-radius: 20px;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
}
.right__sidebar_box .teacher_subject_box__item > img {
	height: 78px;
	width: auto;
	margin: 40px 0 10px;
}
.right__sidebar_box .teacher_subject_box__item > p {
	font-family: "Roboto Geo Caps";
	font-size: 20px;
	font-weight: bold;
	color: #626a84;
	text-align: center;
}
.right__sidebar_box .teacher_subject_box__item .sertification {
	font-size: 15px;
	font-weight: bold;
	text-align: center;
	color: #5273e6;
}
.right__sidebar_box .teacher_subject_box__item .secondary_text {
	margin-top: 15px;
	font-family: "Roboto Geo Nus";
	font-size: 14px;
	width: 70%;
	text-align: center;
	color: #626a84;
}
.right__sidebar_box .teacher_subject_box__item span {
	font-family: "Roboto Geo Caps";
	font-size: 50px;
	font-weight: bold;
	color: #5273e6;
}
.right__sidebar_box .teacher_subject_box__item .blue_text {
	font-family: "Roboto Geo Caps";
	font-size: 22px;
	font-weight: bold;
	text-align: center;
	color: #5273e6;
	margin-top: 0;
}
.right__sidebar_box .student_subject_box__item {
	box-sizing: border-box;
	width: 260px;
	height: 80px;
	border-radius: 20px;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
	padding: 0 0 0 20px;
}
.right__sidebar_box .student_subject_box__item > img {
	height: 60px;
	margin: 0 20px 0 0;
}
.right__sidebar_box .student_subject_box__item > p {
	font-family: "Roboto Geo Caps";
	font-size: 18px;
	font-weight: bold;
	color: #626a84;
	text-align: center;
	width: 100%;
}
.right__sidebar_box .sertificate_box__item {
	width: 260px;
	height: 200px;
	border-radius: 20px;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.right__sidebar_box .sertificate_box__item > img {
	height: 78px;
	width: auto;
	margin: 40px 0 10px;
}
.right__sidebar_box .sertificate_box__item > p {
	font-family: "Roboto Geo Caps";
	font-size: 20px;
	font-weight: bold;
	color: #626a84;
}
.right__sidebar_box .sertificate_box__item .sertification {
	font-size: 15px;
	font-weight: bold;
	text-align: center;
	color: #5273e6;
}
.right__sidebar_box .sertificate_box__item .secondary_text {
	margin-top: 15px;
	font-family: "Roboto Geo Nus";
	font-size: 14px;
	width: 70%;
	text-align: center;
	color: #626a84;
}
.right__sidebar_box .sertificate_box__item span {
	font-family: "Roboto Geo Caps";
	font-size: 50px;
	font-weight: bold;
	color: #5273e6;
}
.right__sidebar_box .sertificate_box__item .blue_text {
	font-family: "Roboto Geo Caps";
	font-size: 22px;
	font-weight: bold;
	text-align: center;
	color: #5273e6;
	margin-top: 0;
}
.right__sidebar_box .vertical_line {
	width: 1px;
	height: 130px;
	background-color: #b4c0e9;
}
@media screen and (max-width: 1920px) {
	.right__sidebar_box .right__sidebar_box__item > img {
		margin: 30px 0 0px;
	}
	.right__sidebar_box .right__sidebar_box__item > p {
		font-size: 18px;
	}
	.right__sidebar_box .right__sidebar_box__item .sertification {
		font-size: 15px;
	}
	.right__sidebar_box .right__sidebar_box__item .secondary_text {
		margin-top: 30px;
		font-size: 14px;
		width: 70%;
	}
	.right__sidebar_box .right__sidebar_box__item span {
		font-size: 45px;
	}
	.right__sidebar_box .right__sidebar_box__item .blue_text {
		font-size: 20px;
	}
	.right__sidebar_box .teacher_subject_box__item > img {
		margin: 30px 0 0px;
	}
	.right__sidebar_box .teacher_subject_box__item > p {
		font-size: 18px;
	}
	.right__sidebar_box .teacher_subject_box__item .sertification {
		font-size: 15px;
	}
	.right__sidebar_box .teacher_subject_box__item .secondary_text {
		margin-top: 30px;
		font-size: 14px;
		width: 70%;
	}
	.right__sidebar_box .teacher_subject_box__item span {
		font-size: 45px;
	}
	.right__sidebar_box .teacher_subject_box__item .blue_text {
		font-size: 20px;
	}
	.right__sidebar_box .student_subject_box__item {
		width: 260px;
		height: 80px;
		border-radius: 20px;
		margin-bottom: 16px;
		padding: 0 0 0 20px;
	}
	.right__sidebar_box .student_subject_box__item > img {
		margin: 0 20px 0 0;
	}
	.right__sidebar_box .student_subject_box__item > p {
		font-size: 18px;
		width: 100%;
	}
	.right__sidebar_box .sertificate_box__item > img {
		margin: 30px 0 0px;
	}
	.right__sidebar_box .sertificate_box__item > p {
		font-size: 18px;
	}
	.right__sidebar_box .sertificate_box__item .sertification {
		font-size: 15px;
	}
	.right__sidebar_box .sertificate_box__item .secondary_text {
		margin-top: 30px;
		font-size: 14px;
		width: 70%;
	}
	.right__sidebar_box .sertificate_box__item span {
		font-size: 45px;
	}
	.right__sidebar_box .sertificate_box__item .blue_text {
		font-size: 20px;
	}
	.right__sidebar_box .vertical_line {
		width: 1px;
		height: 110px;
		background-color: #b4c0e9;
	}
}
@media screen and (max-width: 1500px) {
	.right__sidebar_box {
		border-radius: 10px;
	}
	.right__sidebar_box .sertificate_box__item {
		width: 180px;
		height: 120px;
		border-radius: 10px;
	}
	.right__sidebar_box .sertificate_box__item > img {
		height: 40%;
		margin: 20px 0 10px;
	}
	.right__sidebar_box .sertificate_box__item > p {
		font-size: 16px;
	}
	.right__sidebar_box .sertificate_box__item .sertification {
		font-size: 13px;
	}
	.right__sidebar_box .sertificate_box__item .secondary_text {
		margin-top: 15px;
		font-size: 12px;
		margin-bottom: 0;
		width: 80%;
	}
	.right__sidebar_box .sertificate_box__item span {
		font-size: 30px;
	}
	.right__sidebar_box .sertificate_box__item .blue_text {
		font-size: 16px;
	}
	.right__sidebar_box .right__sidebar_box__item {
		width: 180px;
		height: 120px;
		border-radius: 10px;
		margin-bottom: 10px;
	}
	.right__sidebar_box .right__sidebar_box__item > img {
		height: 40%;
		margin: 20px 0 10px;
	}
	.right__sidebar_box .right__sidebar_box__item > p {
		font-size: 16px;
	}
	.right__sidebar_box .right__sidebar_box__item .sertification {
		font-size: 13px;
	}
	.right__sidebar_box .right__sidebar_box__item .secondary_text {
		margin-top: 15px;
		font-size: 12px;
		margin-bottom: 0;
		width: 80%;
	}
	.right__sidebar_box .right__sidebar_box__item span {
		font-size: 30px;
	}
	.right__sidebar_box .right__sidebar_box__item .blue_text {
		font-size: 16px;
	}
	.right__sidebar_box .teacher_subject_box__item {
		width: 180px;
		height: 120px;
		border-radius: 10px;
		margin-bottom: 10px;
	}
	.right__sidebar_box .teacher_subject_box__item > img {
		height: 40%;
		margin: 20px 0 10px;
	}
	.right__sidebar_box .teacher_subject_box__item > p {
		font-size: 16px;
	}
	.right__sidebar_box .teacher_subject_box__item .sertification {
		font-size: 13px;
	}
	.right__sidebar_box .teacher_subject_box__item .secondary_text {
		margin-top: 15px;
		font-size: 12px;
		margin-bottom: 0;
		width: 80%;
	}
	.right__sidebar_box .teacher_subject_box__item span {
		font-size: 30px;
	}
	.right__sidebar_box .teacher_subject_box__item .blue_text {
		font-size: 16px;
	}
	.right__sidebar_box .student_subject_box__item {
		width: 180px;
		height: 60px;
		border-radius: 15px;
		margin-bottom: 10px;
		padding: 0 0 0 10px;
	}
	.right__sidebar_box .student_subject_box__item > img {
		height: 40px;
		margin: 0;
	}
	.right__sidebar_box .student_subject_box__item > p {
		font-size: 14px;
	}
	.right__sidebar_box .vertical_line {
		height: 100px;
	}
}
@media screen and (max-width: 1200px) {
	.right__sidebar_box .right__sidebar_box__item {
		width: 180px;
		height: 120px;
		border-radius: 10px;
		margin-bottom: 10px;
	}
	.right__sidebar_box .right__sidebar_box__item > img {
		height: 40%;
		margin: 20px 0 10px;
	}
	.right__sidebar_box .right__sidebar_box__item > p {
		font-size: 16px;
	}
	.right__sidebar_box .right__sidebar_box__item .sertification {
		font-size: 13px;
	}
	.right__sidebar_box .right__sidebar_box__item .secondary_text {
		margin-top: 15px;
		font-size: 12px;
	}
	.right__sidebar_box .right__sidebar_box__item span {
		font-size: 30px;
	}
	.right__sidebar_box .right__sidebar_box__item .blue_text {
		font-size: 16px;
	}
	.right__sidebar_box .teacher_subject_box__item {
		width: 180px;
		height: 120px;
		border-radius: 10px;
		margin-bottom: 10px;
	}
	.right__sidebar_box .teacher_subject_box__item > img {
		height: 40%;
		margin: 20px 0 10px;
	}
	.right__sidebar_box .teacher_subject_box__item > p {
		font-size: 16px;
	}
	.right__sidebar_box .teacher_subject_box__item .sertification {
		font-size: 13px;
	}
	.right__sidebar_box .teacher_subject_box__item .secondary_text {
		margin-top: 15px;
		font-size: 12px;
	}
	.right__sidebar_box .teacher_subject_box__item span {
		font-size: 30px;
	}
	.right__sidebar_box .teacher_subject_box__item .blue_text {
		font-size: 16px;
	}
	.right__sidebar_box .sertificate_box__item {
		width: 180px;
		height: 120px;
		border-radius: 10px;
	}
	.right__sidebar_box .sertificate_box__item > img {
		height: 40%;
		margin: 20px 0 10px;
	}
	.right__sidebar_box .sertificate_box__item > p {
		font-size: 16px;
	}
	.right__sidebar_box .sertificate_box__item .sertification {
		font-size: 13px;
	}
	.right__sidebar_box .sertificate_box__item .secondary_text {
		margin-top: 15px;
		font-size: 12px;
	}
	.right__sidebar_box .sertificate_box__item span {
		font-size: 30px;
	}
	.right__sidebar_box .sertificate_box__item .blue_text {
		font-size: 16px;
	}
	.right__sidebar_box .vertical_line {
		height: 100px;
	}
}
@media screen and (max-width: 1000px) {
	.right__sidebar_box {
		border-radius: 15px;
	}
	.right__sidebar_box .right__sidebar_box__item {
		width: 172px;
		height: 100px;
		border-radius: 15px;
		margin-bottom: 10px;
	}
	.right__sidebar_box .right__sidebar_box__item > img {
		height: 50px;
		width: 50px;
		margin: 6px 0 8px;
	}
	.right__sidebar_box .right__sidebar_box__item > p {
		font-size: 16px;
		font-weight: normal;
		margin: 0;
	}
	.right__sidebar_box .right__sidebar_box__item .sertification {
		font-size: 12px;
	}
	.right__sidebar_box .right__sidebar_box__item .sertification_img {
		height: 80px;
		width: auto;
		margin: 0 0 10px;
	}
	.right__sidebar_box .right__sidebar_box__item .secondary_text {
		margin-top: 10px;
		font-size: 12px;
	}
	.right__sidebar_box .right__sidebar_box__item span {
		font-size: 35px;
	}
	.right__sidebar_box .right__sidebar_box__item .blue_text {
		font-size: 18px;
		margin-top: 0;
	}
	.right__sidebar_box .teacher_subject_box__item {
		width: 172px;
		height: 100px;
		border-radius: 15px;
		margin-bottom: 10px;
	}
	.right__sidebar_box .teacher_subject_box__item > img {
		height: 50px;
		width: 50px;
		margin: 6px 0 8px;
	}
	.right__sidebar_box .teacher_subject_box__item > p {
		font-size: 16px;
		font-weight: normal;
		margin: 0;
	}
	.right__sidebar_box .teacher_subject_box__item .sertification {
		font-size: 12px;
	}
	.right__sidebar_box .teacher_subject_box__item .sertification_img {
		height: 80px;
		width: auto;
		margin: 0 0 10px;
	}
	.right__sidebar_box .teacher_subject_box__item .secondary_text {
		margin-top: 10px;
		font-size: 12px;
	}
	.right__sidebar_box .teacher_subject_box__item span {
		font-size: 35px;
	}
	.right__sidebar_box .teacher_subject_box__item .blue_text {
		font-size: 18px;
		margin-top: 0;
	}
	.right__sidebar_box .student_subject_box__item {
		width: 172px;
		border-radius: 15px;
		margin-bottom: 10px;
		padding: 0 0 0 10px;
	}
	.right__sidebar_box .student_subject_box__item > p {
		font-size: 14px;
	}
	.right__sidebar_box .sertificate_box__item {
		width: 172px;
		height: 100px;
		border-radius: 15px;
	}
	.right__sidebar_box .sertificate_box__item > img {
		height: 50px;
		width: 50px;
		margin: 6px 0 8px;
	}
	.right__sidebar_box .sertificate_box__item > p {
		font-size: 16px;
		font-weight: normal;
		margin: 0;
	}
	.right__sidebar_box .sertificate_box__item .sertification {
		font-size: 12px;
	}
	.right__sidebar_box .sertificate_box__item .sertification_img {
		height: 80px;
		width: auto;
		margin: 0 0 10px;
	}
	.right__sidebar_box .sertificate_box__item .secondary_text {
		margin-top: 10px;
		font-size: 12px;
	}
	.right__sidebar_box .sertificate_box__item span {
		font-size: 35px;
	}
	.right__sidebar_box .sertificate_box__item .blue_text {
		font-size: 18px;
		margin-top: 0;
	}
	.right__sidebar_box .vertical_line {
		width: 1px;
		height: 90px;
	}
}
@media screen and (max-width: 500px) {
	.right__sidebar_box .clickable {
		cursor: pointer;
	}
	.right__sidebar_box .box_height {
		height: 110px !important;
	}
	.right__sidebar_box .right__sidebar_box__item {
		width: 150px;
		height: 90px;
	}
	.right__sidebar_box .right__sidebar_box__item > img {
		height: 40px;
		margin: 6px 0 8px;
	}
	.right__sidebar_box .right__sidebar_box__item > p {
		font-size: 14px;
	}
	.right__sidebar_box .right__sidebar_box__item .sertification {
		font-size: 10px;
	}
	.right__sidebar_box .right__sidebar_box__item .sertification_img {
		height: 60px;
		margin: 0 0 10px;
	}
	.right__sidebar_box .right__sidebar_box__item .secondary_text {
		margin-top: 10px;
		font-size: 10px;
		width: 80%;
	}
	.right__sidebar_box .right__sidebar_box__item span {
		font-size: 30px;
	}
	.right__sidebar_box .right__sidebar_box__item .blue_text {
		font-size: 16px;
		margin-top: 0;
	}
	.right__sidebar_box .teacher_subject_box__item {
		width: 150px;
		height: 90px;
	}
	.right__sidebar_box .teacher_subject_box__item > img {
		height: 40px;
		margin: 6px 0 8px;
	}
	.right__sidebar_box .teacher_subject_box__item > p {
		font-size: 14px;
	}
	.right__sidebar_box .teacher_subject_box__item .sertification {
		font-size: 10px;
	}
	.right__sidebar_box .teacher_subject_box__item .sertification_img {
		height: 60px;
		margin: 0 0 10px;
	}
	.right__sidebar_box .teacher_subject_box__item .secondary_text {
		margin-top: 10px;
		font-size: 10px;
		width: 80%;
	}
	.right__sidebar_box .teacher_subject_box__item span {
		font-size: 30px;
	}
	.right__sidebar_box .teacher_subject_box__item .blue_text {
		font-size: 16px;
		margin-top: 0;
	}
	.right__sidebar_box .student_subject_box__item {
		width: 48%;
		height: 60px;
		border-radius: 13px;
		margin-bottom: 8px;
		padding: 0 0 0 10px;
	}
	.right__sidebar_box .student_subject_box__item > img {
		width: 35px;
	}
	.right__sidebar_box .student_subject_box__item > p {
		font-size: 11px;
	}
	.right__sidebar_box .sertificate_box__item {
		width: 150px;
		height: 90px;
	}
	.right__sidebar_box .sertificate_box__item > img {
		height: 40px;
		margin: 6px 0 8px;
	}
	.right__sidebar_box .sertificate_box__item > p {
		font-size: 14px;
	}
	.right__sidebar_box .sertificate_box__item .sertification {
		font-size: 10px;
	}
	.right__sidebar_box .sertificate_box__item .sertification_img {
		height: 60px;
		margin: 0 0 10px;
	}
	.right__sidebar_box .sertificate_box__item .secondary_text {
		margin-top: 10px;
		font-size: 10px;
		width: 80%;
	}
	.right__sidebar_box .sertificate_box__item span {
		font-size: 30px;
	}
	.right__sidebar_box .sertificate_box__item .blue_text {
		font-size: 16px;
		margin-top: 0;
	}
	.right__sidebar_box .vertical_line {
		width: 1px;
		height: 80px;
	}
}
.profile_bottom__sidebar {
	display: none;
}
.box_height {
	height: 150px !important;
}
.show_info_text {
	font-family: "Roboto Geo Caps";
	color: #fff;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	margin: 10px 0 0;
}
.saveButtonContainer {
	margin-top: 20px;
	width: 100%;
	display: flex;
	justify-content: center;
}
@media screen and (max-width: 1500px) {
	.saveButtonContainer {
		margin-top: 10px;
	}
}
.saveButtonAfterConfirmed,
.saveButtonBeforeConfirmed {
	width: 200px;
	height: 40px;
	border-radius: 15px;
	background-color: #5273e6;
	color: white;
	font-family: "Roboto Geo Caps";
	border: none;
	outline: none;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.35;
	letter-spacing: normal;
	text-align: center;
	margin: -4px auto;
}
.saveButtonAfterConfirmed {
	cursor: pointer;
}
.saveButtonBeforeConfirmed {
	background-color: #e8e8e8;
}
.input,
.inputPassw {
	padding-right: 8px;
	padding-left: 20px;
	width: 100%;
	height: 60px;
	border-radius: 20px;
	border: solid 1px #626a84;
	background-color: #fff;
	color: #626a84;
	font-size: 18px;
	font-family: "Roboto Geo Nus";
}
.input:focus,
.inputPassw:focus {
	outline: none;
}
@media screen and (max-width: 1500px) {
	.input,
	.inputPassw {
		height: 40px;
		border-radius: 15px;
		font-size: 14px;
	}
}
@media screen and (max-width: 1000px) {
	.input,
	.inputPassw {
		height: 50px;
		border-radius: 15px;
	}
}
@media screen and (max-width: 500px) {
	.input,
	.inputPassw {
		padding-right: 17px;
	}
}
.inputPassw {
	font-weight: lighter !important;
}
.resetPasswordContainer {
	outline: none;
}
.fullWidth {
	align-self: baseline;
}
.emptyContainer {
	height: 72px;
}
@media screen and (max-width: 1500px) {
	.emptyContainer {
		height: 52px;
	}
}
@media screen and (max-width: 1000px) {
	.emptyContainer {
		height: 107px;
	}
}
@media screen and (max-width: 500px) {
	.emptyContainer {
		height: 112px;
	}
}
.successMsg {
	font-size: 14px;
	font-family: "Roboto Geo Nus";
	font-style: italic;
	color: #626a84;
	padding: 10px;
}
@media screen and (max-width: 1000px) {
	.successMsg {
		text-align: center;
		padding: 10px 0 0;
	}
}
.desktop_log_out_container {
	width: 150px;
	position: absolute;
	top: 50px;
	right: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	text-decoration: none;
}
.desktop_log_out_container .log_out_icon {
	height: 32px;
}
.desktop_log_out_container .log_out_text {
	letter-spacing: 0.5px;
	margin: 0;
	font-family: "Roboto Geo Caps";
	font-size: 22px;
	color: #fff;
}
@media screen and (max-width: 1500px) {
	.desktop_log_out_container {
		width: 120px;
		top: 36px;
	}
	.desktop_log_out_container .log_out_icon {
		height: 28px;
	}
	.desktop_log_out_container .log_out_text {
		font-size: 18px;
	}
}
@media screen and (max-width: 1200px) {
	.desktop_log_out_container {
		width: 110px;
		top: 34px;
	}
	.desktop_log_out_container .log_out_icon {
		height: 25px;
	}
	.desktop_log_out_container .log_out_text {
		font-size: 16px;
	}
}
.mobile_log_out_container {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 40px;
	top: 26px;
	cursor: pointer;
}
.mobile_log_out_container .user_log_out__img {
	width: 40px;
	height: 40px;
}
@media screen and (max-width: 1920px) {
	.personal_info_form {
		margin-top: 10px;
	}
	.personal_info_form__title {
		font-size: 14px;
		padding-left: 15px;
		margin: 15px 0;
	}
	.personal_info_form__title > span {
		font-size: 12px;
	}
	.personal_info_form__input_wrapper label {
		font-size: 13px;
		padding: 0 0 8px 15px;
	}
	.personal_info_form__input_wrapper .personal_info_form__input {
		width: 49%;
		margin-bottom: 12px;
	}
	.personal_info_form__input_wrapper .personal_info_form__input > input {
		padding-left: 15px;
		height: 50px;
		border-radius: 15px;
		font-size: 18px;
	}
	.personal_info_form__input_wrapper
		.personal_info_form__input
		> input[type="number"] {
		width: 100%;
	}
	.personal_info_form__input_wrapper .personal_info_form__input_long {
		width: calc(100% - 210px);
	}
	.personal_info_form__input_wrapper select {
		display: block;
		width: 100%;
		height: 50px;
		border-radius: 15px;
		font-size: 18px;
	}
	.personal_info_form__input_wrapper .personal_info_form__code_btn {
		width: 200px;
		font-size: 18px;
	}
	.personal_info_form__input_wrapper .confirmed_code {
		width: 49%;
	}
	.personal_info_form__text {
		font-size: 12px;
		padding-left: 15px;
	}
	.middle_width {
		width: 65% !important;
	}
	.arrow_loader {
		width: 50px;
		height: 50px;
		border-radius: 15px;
		margin: 0 auto 12px;
	}
	/* .profile_right__sidebar {
		width: 43%;
		height: calc(100vh - 70px);
		border-radius: 25px 0 0 25px;
	} */
	.right__sidebar_content_wrapper {
		margin: 100px auto 0;
	}
	.right__sidebar_content_wrapper > h3 {
		font-size: 20px;
		padding-left: 15px;
		margin-top: 30px;
		margin-bottom: 10px;
	}
}
@media screen and (max-width: 1500px) {
	.personal_info_form {
		margin-top: 10px;
	}
	.personal_info_form__title {
		font-size: 12px;
		padding-left: 20px;
		margin: 10px 0;
	}
	.personal_info_form__title > span {
		font-size: 10px;
	}
	.personal_info_form__input_wrapper label {
		font-size: 12px;
		padding: 0 0 8px 20px;
	}
	.personal_info_form__input_wrapper .personal_info_form__input {
		width: 270px;
		margin-bottom: 12px;
	}
	.personal_info_form__input_wrapper .personal_info_form__input input {
		padding-left: 20px;
		height: 40px;
		border-radius: 15px;
		font-size: 16px;
	}
	.personal_info_form__input_wrapper
		.personal_info_form__input
		input[type="number"] {
		width: 320px;
	}
	.personal_info_form__input_wrapper .personal_info_form__input_long {
		width: calc(100% - 205px);
	}
	.personal_info_form__input_wrapper select {
		height: 40px;
		border-radius: 10px;
		font-size: 16px;
	}
	.personal_info_form__input_wrapper .personal_info_form__code_btn {
		height: 40px;
		border-radius: 15px;
		font-size: 14px;
	}
	.personal_info_form__text {
		font-size: 10px;
		padding-left: 20px;
	}
	.middle_width {
		width: 60% !important;
	}
	.arrow_loader {
		width: 40px;
		height: 40px;
		border-radius: 10px;
		margin: 0 auto 12px;
	}
	::placeholder {
		font-size: 12px;
	}
	.right__sidebar_content_wrapper {
		width: 370px;
		margin: 40px auto 0;
	}
	.right__sidebar_content_wrapper > h3 {
		font-size: 16px;
		padding-left: 20px;
		margin-top: 20px;
	}
}
@media screen and (max-width: 1200px) {
	.personal_info_form {
		margin-top: 10px;
	}
	.personal_info_form__title {
		font-size: 10px;
		padding-left: 10px;
		margin: 10px 0;
	}
	.personal_info_form__title > span {
		font-size: 10px;
	}
	.personal_info_form__input_wrapper label {
		font-size: 10px;
		padding: 0 0 8px 10px;
	}
	.personal_info_form__input_wrapper .personal_info_form__input {
		width: 225px;
		margin-bottom: 12px;
	}
	.personal_info_form__input_wrapper .personal_info_form__input > input {
		padding-left: 10px;
	}
	.personal_info_form__input_wrapper
		.personal_info_form__input
		> input[type="number"] {
		width: 320px;
	}
	.personal_info_form__input_wrapper .personal_info_form__input_long {
		width: calc(100% - 180px);
	}
	.personal_info_form__input_wrapper .personal_info_form__code_btn {
		width: 175px;
	}
	.personal_info_form__input_wrapper .middle_width {
		width: 60% !important;
	}
	.personal_info_form__input_wrapper .middle_width > input[type="number"] {
		width: 100%;
	}
	.personal_info_form__text {
		font-size: 10px;
		padding-left: 20px;
	}
	.arrow_loader {
		width: 40px;
		height: 40px;
		border-radius: 10px;
		margin: 0 auto 12px;
	}
	::placeholder {
		font-size: 12px;
	}
	.right__sidebar_content_wrapper {
		width: 370px;
	}
	.right__sidebar_content_wrapper > h3 {
		font-size: 16px;
		padding-left: 20px;
		margin-top: 20px;
	}
}
@media screen and (max-width: 1000px) {
	.personal_info_form {
		margin-top: -5px;
	}
	.personal_info_form__title {
		font-size: 16px;
		padding-left: 20px;
		margin: 30px auto 20px;
		width: 60%;
	}
	.personal_info_form__title > span {
		font-size: 14px;
	}
	.personal_info_form__input_wrapper {
		display: block;
		align-items: initial;
		justify-content: initial;
		flex-wrap: initial;
		width: 60%;
		margin: 0 auto;
	}
	.personal_info_form__input_wrapper .middle_width {
		width: 100% !important;
	}
	.personal_info_form__input_wrapper label {
		font-size: 12px;
		padding: 0 0 8px 20px;
	}
	.personal_info_form__input_wrapper .personal_info_form__input {
		width: 100%;
	}
	.personal_info_form__input_wrapper .personal_info_form__input input {
		padding-left: 20px;
		width: 100%;
		height: 50px;
		border-radius: 15px;
		font-size: 16px;
	}
	.personal_info_form__input_wrapper
		.personal_info_form__input
		input[type="number"] {
		width: 100%;
	}
	.personal_info_form__input_wrapper .personal_info_form__select {
		width: 100%;
	}
	.personal_info_form__input_wrapper select {
		height: 50px;
		border-radius: 15px;
	}
	.personal_info_form__input_wrapper .personal_info_form__code_btn {
		width: 100%;
		height: 50px;
		font-size: 16px;
		border-radius: 15px;
	}
	.personal_info_form__text {
		font-size: 12px;
		padding-left: 20px;
		width: 60%;
		margin: 0 auto;
	}
	.arrow_loader {
		display: inline-flex;
		vertical-align: middle;
		width: 50px;
		height: 50px;
		border-radius: 15px;
	}
	::placeholder {
		font-size: 14px;
	}
	.account_icon {
		color: #5273e6;
		height: 38px;
		width: 38px;
		position: absolute;
		right: 30px;
		top: 23px;
		cursor: pointer;
	}
	.profile_bottom__sidebar {
		display: block;
		position: fixed;
		bottom: -80px;
		left: 0;
		width: 100%;
		height: 100vh;
		background-color: #b4c0e9;
		border-radius: 30px 30px 0 0;
		overflow: visible;
		transition: all 0.2s;
	}
	.profile_bottom__sidebar .blue_arc {
		background-image: url("../general_imgs/Up.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: absolute;
		width: 100px;
		height: 13px;
		top: -11px;
		left: 50%;
		transform: translateX(-50%);
	}
	.profile_bottom__sidebar .white_arc {
		background-image: url("../general_imgs/Down.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: absolute;
		width: 100px;
		height: 13px;
		top: -1px;
		left: 50%;
		transform: translateX(-50%);
	}
	.profile_bottom__sidebar .scroll_up {
		position: absolute;
		top: -2px;
		left: 50%;
		transform: translateX(-50%) rotate(180deg);
		width: 10px;
		height: 10px;
	}
	.profile_bottom__sidebar .scroll_down {
		position: absolute;
		top: -5px;
		left: 50%;
		transform: translateX(-50%);
		width: 10px;
		height: 10px;
		z-index: 10;
	}
	.show_additional_info {
		bottom: calc(-100vh + 40px);
	}
	.right__sidebar_content_wrapper {
		width: 354px;
	}
	.right__sidebar_content_wrapper > h3 {
		font-size: 16px;
		padding-left: 10px;
		margin-top: 20px;
	}
}
@media screen and (max-width: 500px) {
	.personal_info_form {
		margin-top: 10px;
	}
	.personal_info_form__title {
		width: 100%;
	}
	.personal_info_form__title > span {
		font-size: 14px;
	}
	.personal_info_form__input_wrapper {
		width: 100%;
	}
	.personal_info_form__input_wrapper label {
		font-size: 12px;
		padding: 0 0 8px 20px;
	}
	.personal_info_form__input_wrapper .personal_info_form__input {
		width: 100%;
	}
	.personal_info_form__input_wrapper
		.personal_info_form__input
		> input[type="number"] {
		width: 100%;
	}
	.personal_info_form__text {
		font-size: 12px;
		padding-left: 20px;
		width: 100%;
		margin: 0 auto;
	}
	.account_icon {
		right: 20px !important;
	}
	.show_info_text {
		font-size: 16px;
		margin-top: 13px;
	}
	.profile_bottom__sidebar {
		border-radius: 20px 20px 0 0;
	}
	.right__sidebar_content_wrapper {
		width: 310px;
	}
	.right__sidebar_content_wrapper > h3 {
		font-size: 14px;
		margin-top: 20px;
	}
	.divider {
		margin: 30px 0;
	}
	.studentRegisterWrapper {
		width: 102%;
	}
	.fullWidth {
		width: 100%;
	}
	.fullWidth .stretch {
		align-self: baseline;
	}
}
@media screen and (max-width: 374px) {
	.show_info_text {
		font-size: 16px;
		margin-top: 13px;
	}
}
.certificateContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 260px;
	height: 200px;
	border-radius: 20px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	background-color: white;
}
@media screen and (max-width: 1500px) {
	.certificateContainer {
		width: 180px;
		height: 120px;
	}
}
@media screen and (max-width: 500px) {
	.certificateContainer {
		width: 150px;
		height: 110px;
	}
}
.textContaier {
	margin-top: 20px;
	font-family: "Roboto Geo Caps";
	font-size: 18px;
	font-weight: bold;
	color: #5273e6;
	text-align: center;
}
@media screen and (max-width: 1000px) {
	.textContaier {
		font-size: 13px;
		margin-top: 10px;
		text-align: center;
	}
}

