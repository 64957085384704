.container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.player {
	width: 800px;
}
@media screen and (max-width: 1400px) {
	.player {
		width: 600px;
	}
}
@media screen and (max-width: 800px) {
	.player {
		width: 100%;
	}
}
