.assignmentContentWrapper {
	font-family: "Roboto Geo Nus";
	margin: 0 auto;
	width: 80%;
	position: relative;
}
.assignmentContentWrapper .assignmentMandatoryContentWrapper {
	width: 100%;
	background: #b4c0e9;
	border-radius: 20px;
}
.assignmentContentWrapper
	.assignmentMandatoryContentWrapper
	.assignmentStatement {
	margin-bottom: 50px;
}
.assignmentContentWrapper
	.assignmentMandatoryContentWrapper
	.assignmentStatement
	.inputContainer {
	margin: 0 auto 0 10%;
}
.assignmentContentWrapper .assignmentStatementTitle,
.assignmentContentWrapper .assignmentCoreTasksTitle,
.assignmentContentWrapper .assignmentPracticalSuggestionsTitle {
	font-family: "Roboto Geo MT Bold";
	display: block;
	color: #3a3d47;
	font-size: 15px;
	font-weight: bold;
	padding: 15px 0 15px calc(10% + 40px);
}
.assignmentContentWrapper .assignmentPracticalSuggestions {
	background-color: #e0e5f9;
	margin-top: 50px;
	width: 100%;
	border-radius: 20px;
}
.assignmentContentWrapper
	.assignmentPracticalSuggestions
	.assignmentContentInputWrapper
	.contentInput
	.inputContainer {
	border: solid 1px #b7b7b7;
}
.assignmentContentWrapper
	.assignmentPracticalSuggestions
	.assignmentContentInputWrapper
	.contentInput
	.addAssignmentContentButton,
.assignmentContentWrapper
	.assignmentPracticalSuggestions
	.assignmentContentInputWrapper
	.contentInput
	.deleteAssignmentContentButton {
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
.assignmentContentWrapper
	.assignmentPracticalSuggestions
	.assignmentContentInputWrapper
	.attachFile {
	color: #626a84;
}
.assignmentContentInputWrapper {
	padding-bottom: 20px;
}
.assignmentContentInputWrapper .contentInput {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
	position: relative;
}
.assignmentContentInputWrapper .contentInput .inputContainer {
	background: white;
	border: none;
	border-radius: 20px;
	width: 80%;
	margin: 0 auto;
}
.assignmentContentInputWrapper .contentInput .inputContainer .attachLink {
	cursor: pointer;
	position: absolute;
	bottom: 0;
	right: calc(10% + 9px);
}
.assignmentContentInputWrapper .contentInput .inputContainer .attachLink span,
.assignmentContentInputWrapper .contentInput .inputContainer .attachLink svg {
	display: inline-block;
	vertical-align: middle;
}
.assignmentContentInputWrapper .contentInput .inputContainer .attachLink span {
	font-size: 13px;
	color: #626a84;
}
.assignmentContentInputWrapper .contentInput .inputContainer .attachLink svg {
	width: 12px;
	height: 15px;
	color: #626a84;
	transform: rotate(180deg);
	margin-right: 5px;
}
.assignmentContentInputWrapper .contentInput .addAssignmentContentButton,
.assignmentContentInputWrapper .contentInput .deleteAssignmentContentButton {
	position: absolute;
	right: 8%;
	transform: translateX(100%);
}
@media screen and (max-width: 680px) {
	.assignmentContentInputWrapper .contentInput .addAssignmentContentButton,
	.assignmentContentInputWrapper
		.contentInput
		.deleteAssignmentContentButton {
		width: 40px;
		height: 40px;
	}
}
@media screen and (max-width: 500px) {
	.assignmentContentInputWrapper .contentInput .addAssignmentContentButton,
	.assignmentContentInputWrapper
		.contentInput
		.deleteAssignmentContentButton {
		width: 28px;
		height: 28px;
	}
}
.assignmentContentInputWrapper .attachFile {
	margin: 5px 10% 0 auto;
	text-align: right;
	color: white;
}
.assignmentContentInputWrapper .attachFile span,
.assignmentContentInputWrapper .attachFile svg {
	vertical-align: middle;
	display: inline-block;
}
.assignmentContentInputWrapper .attachFile span {
	font-size: 15px;
}
.assignmentContentInputWrapper .attachFile svg {
	width: 15px;
	height: 18px;
}
.targetConceptsAndTopicsContainer {
	width: 95%;
	margin: 0 auto 30px auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
@media screen and (max-width: 720px) {
	.targetConceptsAndTopicsContainer {
		justify-content: center;
	}
}
.targetConceptsAndTopics {
	display: flex;
	min-height: 180px;
}
@media screen and (max-width: 650px) {
	.targetConceptsAndTopics {
		flex-wrap: wrap;
		justify-content: center;
	}
	.targetConceptsAndTopics > div {
		margin-bottom: 20px;
	}
}
.thirdStepChosenConceptResultsContainer .thirdStepChosenConceptResultsBox {
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
.thirdStepChosenConceptResultsContainer
	.thirdStepChosenConceptResultsBox:hover {
	box-shadow: 0 0 6px 0 rgba(82, 115, 230, 0.16);
	background-color: #b4c0e9;
}
.thirdStepChosenConceptResultsContainer .thirdStepChosenConceptResultsText {
	color: #9a9a9a;
	font-size: 14px;
}
.editAndSaveButtons {
	display: flex;
	justify-content: center;
	margin: 20px auto 0 auto;
}
.editAndSaveButtons button {
	width: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	border-radius: 8px;
	background-color: #5273e6;
	color: white;
	padding: 8px 28px;
	font-size: 16px;
	outline: none;
	font-family: "Roboto Geo Caps";
	cursor: pointer;
	fill: currentColor;
}
.editAndSaveButtons button:first-child {
	margin-right: 5px;
}
.editAndSaveButtons button svg {
	width: 25px;
	height: 25px;
	margin-right: 8px;
}
@media screen and (max-width: 680px) {
	.editAndSaveButtons {
		width: 95%;
	}
	.editAndSaveButtons button {
		width: auto;
	}
}
@media screen and (max-width: 500px) {
	.editAndSaveButtons {
		width: 80%;
	}
	.editAndSaveButtons button {
		font-size: 14px;
		padding: 5px 10px;
	}
}
.popup svg {
	width: 15px;
	height: 15px;
	margin: 5px;
	cursor: pointer;
}
[data-placeholder]:empty:before {
	content: attr(data-placeholder);
	color: #888;
	font-style: italic;
	font-size: 14px;
}
.filesContainer {
	display: flex;
	width: 80%;
	margin: 0 auto 0 10%;
	padding-top: 10px;
	flex-flow: wrap;
	place-content: flex-end;
}
