.right_section {
	width: 45%;
	background: white;
	float: right;
	position: relative;
	border-radius: 20px 0px 0px 20px;
	padding: 50px 0 50px 50px;
	display: flex;

	.inner_container {
		width: 530px;
		display: flex;
		flex-direction: column;
	}

	@media screen and (max-width: 1449px) {
		padding: 29px 0 29px 20px;

		.inner_container {
			width: 400px;
		}
	}
	@media screen and (max-width: 1024px) {
		width: 84%;
		background: white;
		float: initial;
		position: initial;
		border-radius: 20px;
		padding: 20px 3%;
		margin: 0 auto;

		.inner_container {
			width: 100%;
		}
	}
}
.title {
	margin-bottom: 140px !important;
	margin-top: 3rem !important;

	@media screen and (min-width: 1450px) {
		font-size: 36px !important;
	}

	@media screen and (max-width: 1449px) {
		margin-bottom: 100px !important;
	}
	@media screen and (max-width: 1024px) {
		margin-bottom: 10px !important;
	}
}
.img {
	position: absolute;
	right: 10%;
	top: -140px;
	width: 230px;
	height: auto;
	z-index: -1;

	@media screen and (max-width: 1449px) {
		top: -95px;
		width: 170px;
	}
	@media screen and (max-width: 1024px) {
		display: none;
	}
}
.left_section {
	width: 55%;
	background: transparent;
	float: left;
	padding: 45px 5% 0 0;
	display: flex;
	justify-content: flex-end;

	.inner_container {
		width: 670px;
		display: flex;
		flex-direction: column;
	}

	@media screen and (max-width: 1449px) {
		padding: 20px 3% 0 0;

		.inner_container {
			width: 500px;
		}
	}
	@media screen and (max-width: 1024px) {
		width: 100%;
		background: transparent;
		float: initial;
		padding: 20px 8%;
		display: flex;
		justify-content: flex-end;

		.inner_container {
			width: 100%;
		}
	}
}
.clear_fix {
	display: block;
	clear: both;

	@media screen and (max-width: 1024px) {
		display: none;
	}
}

.img_left {
	width: 400px;
	max-width: 50%;
}
.right_container {
	width: 500px;
	margin-left: 50px;
	text-align: left;
}
@media screen and (max-width: 1024px) {
	.img_left {
		width: 50%;
	}
	.right_container {
		width: 300px;
	}
}
@media screen and (max-width: 767px) {
	.img_left {
		display: none;
	}
	.right_container {
		margin: 0 auto;
	}
}

.cube_box {
	padding: 20px 0;
	width: 400px;
	height: 400px;
	background-color: #fff;
	cursor: pointer;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	margin: 20px;
	border-radius: 16px;
	display: inline-flex;
	vertical-align: middle;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	transition: box-shadow 0.2s;

	&:hover {
		box-shadow: 0 0 20px 0 rgba(82, 115, 230, 0.6);
	}

	&__img {
		width: 300px;
		height: 300px;
	}
	&__title {
		color: #5273e6;
		font-size: 20px;
		font-family: "Roboto Geo Caps";
		font-weight: bold;
	}

	@media screen and (max-width: 1400px) {
		width: 300px;
		height: 300px;
		padding: 20px;

		&__img {
			width: 220px;
			height: 220px;
			margin-bottom: 10px;
		}

		&__title {
			font-size: 16px;
		}
	}

	@media screen and (max-width: 400px) {
		margin: 10px;
	}
}
// Choose_child component styles
.box_item {
	position: relative;
	display: inline-flex;
	align-items: center;
	width: 395px;
	height: 120px;
	background-color: #fff;
	border-radius: 20px;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
	border: 1px solid transparent;
	cursor: pointer;
	margin: 10px;
	vertical-align: middle;
	transition: border 0.2s;

	&:hover {
		border-color: #626a84;
	}

	&__img {
		width: 100px;
		height: 100px;
		margin: 0 30px 0 20px;
	}
	&__title {
		color: #5273e6;
		font-family: "Roboto Geo Mt Bold";
		font-size: 20px;
	}

	@media screen and (max-width: 480px) {
		width: 300px;
		height: 100px;
		margin: 10px 0;

		&__img {
			width: 80px;
			height: 80px;
			margin: 0 20px 0 10px;
		}
		&__title {
			font-size: 16px;
		}
	}
}

// Start_subject_activating
.text_content__wrapper {
	width: 100%;
	margin: 0 auto;

	@media screen and (max-width: 1500px) {
		width: 84%;
	}
	@media screen and (max-width: 1280px) {
		width: 77%;
	}
	@media screen and (max-width: 1200px) {
		width: 77%;
	}
	@media screen and (max-width: 1023px) {
		width: 100%;
		padding: 0 10px;
	}
}
.main_title {
	font-family: "Roboto Geo MT Bold";
	font-size: 30px;
	color: #626a84;
	margin-bottom: 1rem;

	@media screen and (max-width: 1280px) {
		font-size: 24px;
	}
	@media screen and (max-width: 768px) {
		font-size: 20px;
	}
	@media screen and (max-width: 480px) {
		font-size: 18px;
	}
}
.text_paragraph {
	font-family: "Roboto Geo Nus";
	color: #3a3d47;
	font-size: 1rem;
	line-height: 26px;

	@media screen and (max-width: 1280px) {
		font-size: 14px;
		line-height: 22px;
	}
	@media screen and (max-width: 768px) {
		font-size: 13px;
	}

	@media screen and (max-width: 480px) {
		font-size: 12px;
		line-height: 18px;
	}
}
.double_blocks {
	width: 100%;
	margin: 3rem 0;
	display: flex;
	justify-content: space-between;

	&__item {
		background: #fff;
		width: 47%;
		display: flex;
		justify-content: space-between;

		.item__img {
			width: 25%;
			max-width: 10rem;
			margin: 0 auto;

			@media screen and (max-width: 1500px) {
				width: 20%;
			}
			@media screen and (max-width: 1023px) {
				display: none;
			}
		}
		.item_text {
			width: 29.3rem;

			&__title {
				font-family: "Roboto Geo Caps";
				font-size: 15px;
				color: #626a84;
				letter-spacing: 0.5px;
				margin-bottom: 1rem;

				@media screen and (max-width: 1500px) {
					font-size: 14px;
				}
				@media screen and (max-width: 1280px) {
					font-size: 13px;
				}
				@media screen and (max-width: 1023px) {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
			}
			&__content {
				font-family: "Roboto Geo Nus";
				font-size: 15px;
				color: #626a84;

				@media screen and (max-width: 1500px) {
					font-size: 14px;
				}
				@media screen and (max-width: 1280px) {
					font-size: 12px;
				}
			}
			&__content:last-child {
				margin-top: 1rem;
			}

			@media screen and (max-width: 1500px) {
				width: 27rem;
			}
			@media screen and (max-width: 1280px) {
				width: 24.5rem;
			}
			@media screen and (max-width: 1200px) {
				width: 72%;
			}
			@media screen and (max-width: 1023px) {
				width: 100%;
			}
		}
		@media screen and (max-width: 1023px) {
			justify-content: initial;
			width: calc(100% - 1.25rem);
		}
	}
	.block_left {
		padding: 3rem 5rem 2.5rem 0;
		border-top-right-radius: 1rem;
		border-bottom-right-radius: 1rem;

		@media screen and (max-width: 1500px) {
			padding: 3rem 2rem 2.5rem 0;
		}
		@media screen and (max-width: 1280px) {
			padding: 2rem 2rem 2rem 0;
		}
		@media screen and (max-width: 1023px) {
			padding: 2rem 1rem 2rem 1.25rem;
		}
	}
	.block_right {
		padding: 3rem 0 2.5rem 5rem;
		border-top-left-radius: 1rem;
		border-bottom-left-radius: 1rem;

		@media screen and (max-width: 1500px) {
			padding: 3rem 0 2.5rem 2rem;
		}
		@media screen and (max-width: 1280px) {
			padding: 2rem 0 2rem 2rem;
		}
		@media screen and (max-width: 1023px) {
			align-self: flex-end;
			margin-top: 1.25rem;
			padding: 2rem 1.25rem 2rem 2rem;
		}
	}

	@media screen and (max-width: 1023px) {
		flex-direction: column;
	}
}
.responsive_img {
	width: 5rem;

	@media screen and (max-width: 768px) {
		width: 4rem;
	}
	@media screen and (max-width: 360px) {
		width: 3rem;
	}
}
.blue_list {
	display: flex;
	align-items: center;

	&__line {
		width: 20px;
		height: 6px;
		margin-right: 10px;
		background: #5273e6;
		border-radius: 20px;
	}
	&__content {
		width: calc(100% - 30px);
		font-family: "Roboto Geo Nus";
		font-size: 15px;
		line-height: 25px;
		color: #626a84;
	}

	@media screen and (max-width: 1500px) {
		&__content {
			font-size: 14px;
			line-height: 22px;
		}
	}
	@media screen and (max-width: 1280px) {
		width: calc(100% - 24px);

		&__line {
			width: 16px;
			height: 4px;
			margin-right: 8px;
		}
		&__content {
			font-size: 12px;
		}
	}
}
