.callsContainer {
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: "Roboto Geo Caps";
	font-size: 14px;

	@media screen and (max-width: 600px) {
		display: none;
	}
}

.numberContainer,
.firsrtNumberContainer {
	padding: 0px 17px;
	border-right: 1px solid;
}

.numberContainer:last-of-type {
	border-right: 0px;
}

.phoneIcon {
	width: 13px;
}

.popupContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-family: "Roboto Geo Caps";
	font-size: 18px;
	color: #5273e6;
}

.numberInPopup {
	margin: 10px 0;
	cursor: pointer;
}

.line {
	height: 2px;
	width: 37px;
	border-bottom: 1.2px solid #bcbcbc;
}

.title {
	font-weight: bold;
	margin-bottom: 25px;
}

.callButton {
	font-size: 15px !important;
	background-color: #5273e6;
	width: 200px;
	height: 40px;
	border-radius: 10px;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
	cursor: pointer;
	color: white;
	font-weight: lighter;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
}

.closeIcon {
	width: 12px;
}
