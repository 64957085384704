.logInButton {
	color: white;
	background-color: rgba(255, 0, 0, 0);
	border: solid white 1px;
	border-radius: 6px;
	font-size: 12px;
	cursor: pointer;
	outline: none;
	padding: 7px 20px;
	transition: 0.1s;
	font-family: "Roboto Geo Nus";
	margin-right: 20px;
	@media screen and (max-width: 450px) {
		margin-right: 8px;
		padding: 5px 10px;
	}
}

.logInButton:hover {
	color: #5273e6;
	background-color: white;
	transition: 0.1s;
}

.registerButton {
	color: white;
	background-color: #febb31;
	border: none;
	border-radius: 6px;
	font-size: 12px;
	cursor: pointer;
	outline: none;
	padding: 7px 20px;
	transition: 0.1s;
	font-family: "Roboto Geo Nus";
	@media screen and (max-width: 450px) {
		padding: 5px 10px;
	}
}

.registerButton:hover {
	background-color: #e9a825;
	transition: 0.1s;
}
