.centerFlex {
	display: flex;
	justify-content: center;
	align-items: center;
}
.mainWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.mainContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: "Roboto Geo MT Bold";
	width: 100%;
	max-width: 650px;
}
.heading {
	font-size: 22px;
	color: #626a84;
}
@media screen and (max-width: 500px) {
	.heading {
		font-size: 18px;
	}
}
.row {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-family: "Roboto Geo Nus";
	justify-content: space-between;
	background-color: #eff1f7;
	width: 100%;
	margin: 10px;
	border-radius: 5px;
	padding: 5px;
}
.selectedRow {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-family: "Roboto Geo Nus";
	justify-content: space-between;
	background-color: #eff1f7;
	width: 100%;
	margin: 10px;
	border-radius: 5px;
	padding: 5px;
	background-color: #5273e6;
	color: white;
}
.buttonContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	justify-content: space-evenly;
	margin: 15px 0px;
	width: 100%;
}
.greenButton {
	width: 120px;
	height: 30px;
	border-radius: 6px;
	font-family: "Roboto Geo MT Bold";
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background-color: #37d7a0;
	color: white;
}
.grayButton {
	width: 120px;
	height: 30px;
	border-radius: 6px;
	font-family: "Roboto Geo MT Bold";
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background-color: #37d7a0;
	color: white;
	background-color: #b2b4b9;
}
.selectButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 30px;
	font-family: "Roboto Geo MT Bold";
	border-radius: 8px;
	justify-content: space-evenly;
	margin-right: 6px;
	font-size: 16px;
	background-color: #5273e6;
	color: white;
}
.selectButton .icon {
	width: 20px;
}
@media screen and (max-width: 500px) {
	.selectButton {
		width: 80px;
		margin-right: 5px;
		font-size: 13px;
	}
	.selectButton .icon {
		width: 17px;
	}
}
.deleteButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 30px;
	font-family: "Roboto Geo MT Bold";
	border-radius: 8px;
	justify-content: space-evenly;
	margin-right: 6px;
	font-size: 16px;
	background-color: #5273e6;
	color: white;
	background-color: #ef6b85;
}
.deleteButton .icon {
	width: 20px;
}
@media screen and (max-width: 500px) {
	.deleteButton {
		width: 80px;
		margin-right: 5px;
		font-size: 13px;
	}
	.deleteButton .icon {
		width: 17px;
	}
}
