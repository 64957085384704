.text {
	color: #626a84;
	font-family: "Roboto Geo Caps";
	font-size: 20px;
}
@media screen and (max-width: 450px) {
	.text {
		font-size: 16px;
	}
}
.btnWrapper {
	display: flex;
	justify-content: center;
	margin-top: 50px;
}
@media screen and (max-width: 484px) {
	.btnWrapper {
		flex-direction: column;
		align-items: center;
	}
	.btnWrapper > button:first-child {
		margin: 0 0 10px;
	}
}
.totalCredit {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: "Roboto Geo MT Bold";
	font-weight: bold;
}
.totalCredit .text {
	font-size: 20px;
	color: #626a84;
	margin-bottom: 20px;
}
.totalCredit .credit {
	font-size: 32px;
	color: #5273e6;
}
@media screen and (max-width: 400px) {
	.totalCredit .text {
		font-size: 18px;
	}
	.totalCredit .credit {
		font-size: 24px;
	}
}
.feedback {
	margin: 20px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 1120px;
	max-width: 100%;
	border-radius: 20px;
	border: solid 1px #626a84;
}
.feedback .title {
	margin: 10px 0;
	font-family: "Roboto Geo MT Bold";
	font-size: 18px;
	font-weight: bold;
	color: #626a84;
}
.feedback .text {
	padding: 10px 50px;
	font-family: "Robo Geo Nus";
	font-size: 16px;
	line-height: 1.38;
	text-align: justify;
	color: #626a84;
}
@media screen and (max-width: 600px) {
	.feedback .title {
		font-size: 16px;
	}
	.feedback .text {
		font-size: 15px;
		padding: 10px 35px;
	}
}
@media screen and (max-width: 450px) {
	.feedback .title {
		font-size: 15px;
	}
	.feedback .text {
		font-size: 14px;
		padding: 10px 20px;
	}
}
