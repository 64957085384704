.popup_body {
	padding-bottom: 10px;
	display: flex;
	justify-content: center;
}
.popup_text {
	font-family: "Roboto Geo Nus";
	font-size: 20px;
	text-align: justify;
	color: #626a84;
	margin: 0 30px 12px 30px;
}
.popup_send_button_container {
	display: flex;
	justify-content: center;
	align-items: center;
}
.popup_send_button {
	margin-top: 20px;
	width: 300px;
	height: 55px;
	border-radius: 15px;
	background-color: #37d7a0;
	font-family: "Roboto Geo MT Bold";
	font-size: 20px;
	font-weight: bold;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.certificateContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 15px;
}
.certificateBox {
	margin: 6px;
	padding: 10px;
	width: 801.5px;
	border-radius: 10px;
	border: solid 3px #5273e6;
	position: relative;
}
.certifacte {
	padding: 0px;
	margin: 0px;
	border-radius: 20px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
.link_section {
	width: 85%;
	max-width: 1700px;
	margin: 30px auto 0;
}
.link_section__container {
	width: 85%;
}
.student_box_w_icon_container {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}
.student_box_w_icon_container .cls-1 {
	fill: red;
}
.student_box_w_icon_container:last-of-type {
	margin-bottom: 0px;
}
.eye_icon {
	margin-left: 10px;
	cursor: pointer;
}
.eye_icon:hover .cls-1 {
	fill: #fff;
}
.eye_icon:hover .cls-2,
.eye_icon:hover .cls-3,
.eye_icon:hover .cls-6 {
	fill: none;
}
.eye_icon:hover .cls-2,
.eye_icon:hover .cls-3 {
	stroke: #5273e6;
}
.eye_icon:hover .cls-3 {
	stroke-linecap: round;
}
.eye_icon:hover .cls-4,
.eye_icon:hover .cls-5 {
	stroke: none;
}
.eye_icon:hover .cls-5 {
	fill: #5273e6;
}
.link_section__title {
	font-family: "Roboto Geo MT Bold";
	font-size: 20px;
	color: #5273e6;
	padding-left: 20px;
}
.copyToReg {
	width: 100%;
	max-width: 1700px;
	margin: 20px auto 20px;
	border: none;
	background: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.copyToReg_code {
	height: 60px;
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	margin-right: 20px;
	font-size: 16px;
	display: flex;
	align-items: center;
	padding: 0 20px;
	color: #626a84;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	width: calc(100% - 320px) !important;
}
button.copyToReg_copy {
	height: 60px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	border-top-right-radius: 30px;
	border-bottom-right-radius: 30px;
	padding: 0;
	cursor: pointer;
	background-color: #fff;
	color: #5273e6;
	font-family: "Roboto Geo MT Bold";
	font-size: 16px;
	width: 300px;
	outline: none;
	border: none;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	display: flex;
	align-items: center;
	justify-content: center;
}
img.copyToReg_copyIcon {
	height: 30px;
	margin-right: 10px;
}
.text_content {
	display: flex;
	align-items: center;
	padding-left: 20px;
}
.text_content__img {
	margin-right: 20px;
	width: 60px;
	height: 62px;
	display: inline-block;
}
.text_content__text {
	font-family: "Roboto Geo Nus";
	color: #626a84;
	font-size: 14px;
}
.send_certficate_button {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	background-color: #37d7a0;
	font-family: "Roboto Geo MT Bold";
	font-size: 16px;
	padding: 10px 20px;
	cursor: pointer;
	font-weight: bold;
	color: white;
	border: none;
	margin-right: 10px;
	margin-bottom: 10px;
}
.send_certficate_button:focus {
	outline: none;
}
.send_certficate_button.on_all_tab {
	background-color: #5273e6;
	color: white;
}
.filter_students {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-flow: wrap;
	width: 85%;
	max-width: 1700px;
	margin: 30px auto 0;
}
.filter_container {
	display: flex;
}
.filter_button {
	font-family: "Roboto Geo MT Bold";
	font-size: 16px;
	color: #626a84;
	background-color: #fff;
	border: none;
	padding: 10px 20px;
	border-radius: 17px;
	transition: all 0.2s;
	cursor: pointer;
	margin-right: 10px;
	margin-bottom: 10px;
}
.filter_button:focus {
	outline: none;
}
.filter_button:hover {
	background-color: #5273e6;
	color: #fff;
}
.filter_button.active {
	border: solid 1px #5273e6;
	color: #5273e6;
}
.filter_button.active:hover {
	color: #fff;
}
.filtered_students {
	margin: 30px auto 50px;
	width: 85%;
	max-width: 1700px;
}
.filtered_students__grade {
	color: #626a84;
	font-family: "Roboto Geo MT Bold";
	font-size: 18px;
	padding-left: 20px;
}
.registered_students_container {
	background-color: #e4e4e4;
	border-radius: 20px;
	padding: 20px;
	margin-bottom: 20px;
}
.registered_students_box {
	height: 65px;
	border-radius: 20px;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.registered_students_box:last-child {
	margin-bottom: 0;
}
.not_registered_students_container {
	background-color: #fff;
	padding: 0 20px;
	margin-bottom: 40px;
}
.not_registered_students_box {
	height: 65px;
	border-radius: 20px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
	background-color: #fff;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.students_box__student {
	display: flex;
	align-items: center;
}
.students_box__student > span {
	width: 60px !important;
	height: 80px;
	color: #5273e6;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: "Roboto Geo MT Bold";
	font-size: 16px;
}
.students_box__student > p {
	font-family: "Roboto Geo Caps";
	font-size: 16px;
	color: #626a84;
}
.students_box_registered > p {
	width: 340px;
	font-family: "Roboto Geo MT Bold";
	font-size: 16px;
	color: #37d7a0;
	border-left: 1px solid #e4e4e4;
	height: 40px;
	line-height: 40px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}
.students_box_not_registered > p {
	width: 300px;
	font-family: "Roboto Geo MT Bold";
	font-size: 16px;
	color: #ef6b85;
	border-left: 1px solid #e4e4e4;
	height: 40px;
	line-height: 40px;
	text-align: center;
}
@media screen and (max-width: 1000px) {
	.link_section {
		width: 90%;
		max-width: initial;
		margin: 30px auto 0;
	}
	.link_section__container {
		width: 90%;
	}
	.link_section__title {
		font-size: 18px;
		padding-left: 20px;
	}
	.copyToReg {
		width: 100%;
		max-width: initial;
		margin: 20px auto 20px;
	}
	.copyToReg_code {
		height: 60px;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		margin-right: 20px;
		font-size: 16px;
		padding: 0 20px;
		width: calc(100% - 220px) !important;
	}
	button.copyToReg_copy {
		height: 60px;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
		font-size: 14px;
		width: 200px;
		background-color: #5273e6;
		color: white;
	}
	img.copyToReg_copyIcon {
		height: 20px;
	}
	.text_content__img {
		width: 50px;
		height: 52px;
	}
	.text_content__text {
		font-size: 12px;
	}
	.filter_students {
		display: flex;
		justify-content: inherit;
		align-items: center;
		flex-flow: wrap;
		width: 90%;
		max-width: initial;
		margin: 30px auto 0;
	}
	.filter_button {
		font-size: 14px;
		padding: 10px 20px;
		border-radius: 17px;
	}
	.filtered_students {
		margin: 30px auto 50px;
		width: 90%;
		max-width: initial;
	}
	.filtered_students__grade {
		font-size: 16px;
	}
	.registered_students_container {
		border-radius: 20px;
		margin-bottom: 20px;
	}
	.registered_students_box {
		height: 60px;
		border-radius: 20px;
	}
	.not_registered_students_container {
		background-color: #fff;
		padding: 0 20px;
	}
	.not_registered_students_box {
		height: 60px;
		border-radius: 20px;
	}
	.students_box__student > span {
		width: 60px;
		height: 60px;
		font-size: 14px;
	}
	.students_box__student > p {
		font-size: 14px;
	}
	.students_box_registered > p {
		width: 275px;
		font-size: 14px;
		height: 40px;
		line-height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.students_box_not_registered > p {
		width: 200px;
		font-size: 14px;
		height: 40px;
		line-height: 40px;
	}
}
@media screen and (max-width: 700px) {
	.certificateContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 8px;
	}
	.link_section {
		width: 95%;
		margin: 20px auto 0;
	}
	.link_section__container {
		width: 95%;
	}
	.link_section__title {
		font-size: 16px;
		padding-left: 10px;
		text-align: center;
	}
	.copyToReg {
		margin: 20px auto 20px;
	}
	.copyToReg_code {
		height: 60px;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		margin-right: 20px;
		font-size: 16px;
		padding: 0 20px;
		width: calc(100% - 170px) !important;
	}
	button.copyToReg_copy {
		height: 60px;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
		font-size: 14px;
		width: 150px;
	}
	img.copyToReg_copyIcon {
		height: 30px;
		margin: 0;
	}
	.text_content {
		display: block;
		padding: 0 10px;
	}
	.text_content__img {
		display: none;
		width: 50px;
		height: 52px;
	}
	.text_content__text {
		text-align: justify;
		text-indent: 20px;
		font-size: 12px;
	}
	.filter_students {
		display: flex;
		justify-content: inherit;
		align-items: center;
		flex-flow: wrap;
		width: 95%;
		margin: 30px auto 0;
	}
	.filter_button {
		font-size: 12px;
		padding: 8px 16px;
		border-radius: 14px;
	}
	.filtered_students {
		margin: 20px auto 40px;
		width: 95%;
	}
	.filtered_students__grade {
		font-size: 14px;
	}
	.registered_students_container {
		border-radius: 20px;
		margin-bottom: 20px;
	}
	.registered_students_box {
		height: 60px;
		border-radius: 20px;
	}
	.not_registered_students_container {
		padding: 0 20px;
	}
	.not_registered_students_box {
		height: 60px;
		border-radius: 20px;
	}
	.students_box__student > span {
		width: 60px;
		height: 60px;
		font-size: 14px;
	}
	.students_box__student > p {
		font-size: 14px;
	}
	.students_box_registered > p {
		width: 275px;
		font-size: 12px;
		height: 40px;
		line-height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.students_box_not_registered > p {
		width: 200px;
		font-size: 12px;
		height: 40px;
		line-height: 40px;
	}
}
.link_section__container {
	position: sticky;
	top: 65px;
	background: white;
}
@media screen and (max-width: 500px) {
	.popup_send_button {
		margin-top: 20px;
		width: 280px;
		height: 55px;
		border-radius: 15px;
		background-color: #37d7a0;
		font-family: "Roboto Geo MT Bold";
		font-size: 16px;
		font-weight: bold;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
	.certificateContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
	}
	.link_section {
		width: 95%;
		margin: 20px auto 0;
	}
	.link_section__title {
		font-size: 14px;
		padding-left: 10px;
	}
	.copyToReg {
		margin: 20px auto 20px;
		width: 100% !important;
	}
	.copyToReg_code {
		height: 45px;
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		margin-right: 8px;
		font-size: 12px;
		padding: 0 0 0 10px;
		width: calc(100% - 68px) !important;
	}
	button.copyToReg_copy {
		height: 45px;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		border-top-right-radius: 30px;
		border-bottom-right-radius: 30px;
		font-size: 14px;
		width: 60px;
	}
	img.copyToReg_copyIcon {
		height: 20px;
		margin: 0;
	}
	.filter_button {
		font-size: 12px;
		padding: 8px 16px;
		border-radius: 14px;
		width: 33.3334%;
		box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
	}
	.filtered_students {
		margin: 20px auto 40px;
		width: 95%;
	}
	.filtered_students__grade {
		font-size: 14px;
	}
	.registered_students_container {
		border-radius: 20px;
		padding: 10px;
		margin-bottom: 10px;
	}
	.registered_students_box {
		height: 60px;
		border-radius: 20px;
		margin-bottom: 10px;
	}
	.not_registered_students_container {
		padding: 0 10px;
	}
	.not_registered_students_box {
		height: 60px;
		border-radius: 20px;
	}
	.students_box__student > span {
		width: 40px !important;
		height: 60px;
		font-size: 14px;
	}
	.students_box__student > p {
		font-size: 12px;
	}
	.students_box_registered > p {
		width: 160px;
		font-size: 10px;
		height: 40px;
		line-height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.students_box_not_registered > p {
		width: 80px;
		font-size: 10px;
		height: 40px;
		line-height: 40px;
	}
}
.input {
	border: 1px solid #5273e6;
	padding: 5px 10px;
	border-radius: 5px;
	outline: none;
	font-size: 20px;
	height: 35px;
}
.nameEditSaveButton {
	border: none;
	background: #5273e6;
	color: white;
	font-size: 20px;
	height: 35px;
	border-radius: 5px;
	margin-left: 5px;
	font-family: "Roboto Geo MT Bold";
	cursor: pointer;
}
