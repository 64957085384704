.footer_container {
	width: 80%;
	margin: 0 auto;
	padding: 30px 0;
	padding-bottom: 0;
}
.bc_footer_container {
	width: 80%;
	margin: 0 auto;
	padding: 0;
	padding-bottom: 0;
}
@media screen and (min-width: 1000px) {
	.bc_footer_container {
		margin-top: -60px;
	}
}
.bc_lineWrapper {
	position: relative;
	width: 110%;
	bottom: 0;
	z-index: -10;
	left: 50%;
	transform: translateX(-50%);
	height: 270px;
	margin-bottom: -100px;
	background-image: url(./img/FooterLine.svg), url(./img/Feather.svg);
	background-repeat: no-repeat, no-repeat;
	background-position:
		calc(100% - 100px) 95px,
		100% 5px;
	background-size:
		91% auto,
		auto 100px;
}
.footer_title {
	font-family: "Roboto Geo Caps";
	font-size: 24px;
	font-weight: normal;
	text-align: center;
	color: #5273e6;
	width: 70%;
	border-top: 1px solid #626a84;
	padding: 60px 0;
	margin: 0 auto;
}
.friendlyPlatforms_container {
	display: flex;
	justify-content: space-between;
	margin: 20px 0 30px;
}
.friendlyPlatforms_item {
	width: 19%;
	height: 130px;
	border-radius: 20px;
	background-color: #f5f5f5;
	display: flex;
	align-items: center;
	justify-content: center;
}
.friendlyPlatforms_item .brand_logo {
	width: 60%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	transition: background-image 0.2s;
}
.friendlyPlatforms_item .brand_logo.brand_lupi {
	height: 67px;
	background-image: url("./img/friendly-platforms/LupiLogoGrey.svg");
}
.friendlyPlatforms_item .brand_logo.brand_prep {
	height: 66px;
	background-image: url("./img/friendly-platforms/PrepLogoGrey.svg");
}
.friendlyPlatforms_item .brand_logo.brand_murtsku {
	height: 87px;
	background-image: url("./img/friendly-platforms/MurtskulogoGrey.svg");
}
.friendlyPlatforms_item .brand_logo.brand_hubu {
	height: 73px;
	background-image: url("./img/friendly-platforms/hubu-logoGrey.svg");
}
.friendlyPlatforms_item .brand_logo.brand_unicatalog {
	height: 45px;
	background-image: url("./img/friendly-platforms/UnicatalogLogoGrey.svg");
}
.friendlyPlatforms_item:hover .brand_lupi {
	background-image: url("./img/friendly-platforms/LupiLogoColor.svg");
}
.friendlyPlatforms_item:hover .brand_prep {
	background-image: url("./img/friendly-platforms/PrepLogoColor.svg");
}
.friendlyPlatforms_item:hover .brand_murtsku {
	background-image: url("./img/friendly-platforms/MurtskulogoColor.svg");
}
.friendlyPlatforms_item:hover .brand_hubu {
	background-image: url("./img/friendly-platforms/hubu-logoColor.svg");
}
.friendlyPlatforms_item:hover .brand_unicatalog {
	background-image: url("./img/friendly-platforms/UnicatalogLogoColor.svg");
}
.lineWrapper {
	position: relative;
	width: 110%;
	bottom: 0;
	z-index: -10;
	left: 50%;
	transform: translateX(-50%);
	height: 250px;
	margin-bottom: -100px;
	background-image: url(./img/FooterLine.svg), url(./img/Feather.svg);
	background-repeat: no-repeat, no-repeat;
	background-position:
		calc(100% - 100px) 95px,
		100% 5px;
	background-size:
		91% auto,
		auto 100px;
}
.terms {
	display: flex;
	align-items: center;
	justify-content: center;
}
.terms a {
	font-family: "Roboto Geo Caps";
	color: #5273e6;
	font-size: 14px;
	text-decoration: none;
}
.terms span {
	height: 20px;
	width: 1px;
	background-color: #58607c;
	margin: 0 20px;
}
.footer_date {
	margin-top: 20px;
	font-family: "Roboto Geo Nus";
	color: #626a84;
	font-size: 14px;
	text-align: center;
	margin-bottom: -16px;
}
@media screen and (max-width: 1400px) {
	.friendlyPlatforms_item {
		height: 110px;
	}
	.bc_lineWrapper {
		width: 110%;
		bottom: 0;
		height: 190px;
		margin-bottom: -100px;
		background-image: url(./img/FooterLine.svg), url(./img/Feather.svg);
		background-repeat: no-repeat, no-repeat;
		background-position:
			calc(100% - 50px) 35px,
			100% 0px;
		background-size:
			91% auto,
			auto 50px;
	}
}
@media screen and (max-width: 1050px) {
	.friendlyPlatforms_item {
		height: 80px;
	}
}
@media screen and (max-width: 1000px) {
	.friendlyPlatforms_container {
		display: flex;
		flex-wrap: wrap;
		margin: 20px 0 -20px;
	}
	.friendlyPlatforms_item {
		width: 49%;
		height: 80px;
		margin-bottom: 20px;
	}
	.friendlyPlatforms_item .brand_logo {
		width: 60%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
	.lineWrapper {
		height: 150px;
		margin-bottom: -40px;
		background-repeat: no-repeat, no-repeat;
		background-position:
			calc(100% - 70px) 70px,
			100% 5px;
		background-size:
			91% auto,
			auto 70px;
	}
	.bc_lineWrapper {
		background-position:
			calc(100% - 50px) 40px,
			100% 0px;
	}
	.footer_title {
		width: 80%;
		font-size: 16px;
	}
	.footer_date {
		width: 70%;
		margin: 0 auto;
		padding-top: 20px;
		margin-bottom: -16px;
	}
}
@media screen and (max-width: 500px) {
	.footer_title {
		width: 100%;
		font-size: 13px;
		padding: 40px 0;
	}
	.friendlyPlatforms_item {
		width: 49%;
		height: 60px;
		margin-bottom: 10px;
		border-radius: 10px;
	}
	.friendlyPlatforms_item .brand_logo {
		width: 70%;
		max-height: 40px;
	}
	.lineWrapper {
		height: 100px;
		margin-bottom: -20px;
		background-position:
			calc(100% - 50px) 50px,
			100% 5px;
		background-size:
			91% auto,
			auto 50px;
	}
	.bc_lineWrapper {
		background-position:
			calc(100% - 50px) 45px,
			100% 0px;
	}
	.terms a {
		font-size: 10px;
	}
	.terms a:first-child {
		margin-right: 10px;
	}
	.terms span {
		display: none;
	}
	.footer_date {
		font-size: 10px;
	}
}
@media screen and (max-width: 400px) {
	.lineWrapper {
		height: 100px;
		margin-bottom: -30px;
		background-position:
			calc(100% - 30px) 30px,
			100% 0;
		background-size:
			91% auto,
			auto 30px;
	}
}

