.heading {
	font-family: "Roboto Geo MT Bold";
	font-size: 20px;
	color: #5273e6;
	padding: 20px 0px;
}
.flexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}
.flexCenterColumn {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.subjectBoxContiner {
	width: 80%;
	padding-bottom: 12px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	color: #5273e6;
	font-family: "Roboto Geo Caps";
}
.subjectBoxContiner .logo {
	width: 80px;
	height: 80px;
	padding-left: 7px;
}
.subjectBoxContiner .subject {
	font-size: 18px;
}
.subjectBoxContiner .topic {
	font-size: 23px;
	font-family: "Roboto Geo MT Bold";
}
.subjectBoxContiner .target {
	font-size: 16px;
}
.send {
	width: 280px;
	height: 50px;
	border-radius: 15px;
	background-color: #5273e6;
	display: flex;
	color: white;
	font-family: "Roboto Geo Caps";
	justify-content: center;
	align-items: center;
	outline: none;
	margin-top: 30px;
	cursor: pointer;
}
.assignmentMandatoryContentWrapper {
	width: 100%;
	background: #b4c0e9;
	border-radius: 20px;
}
.assignmentMandatoryContentWrapper .assignmentStatement {
	margin-bottom: 50px;
}
.assignmentMandatoryContentWrapper .assignmentStatement .inputContainer {
	margin: 0 auto 0 10%;
}
.assignmentPracticalSuggestions {
	margin-top: 50px;
	width: 100%;
	border-radius: 20px;
}
.assignmentPracticalSuggestions
	.assignmentContentInputWrapper
	.contentInput
	.inputContainer {
	border: solid 1px #b7b7b7;
}
.assignmentPracticalSuggestions
	.assignmentContentInputWrapper
	.contentInput
	.addAssignmentContentButton,
.assignmentPracticalSuggestions
	.assignmentContentInputWrapper
	.contentInput
	.deleteAssignmentContentButton {
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
.assignmentPracticalSuggestions .assignmentContentInputWrapper .attachFile {
	color: #626a84;
}
.assignmentPracticalSuggestionsInputWrapper {
	border-radius: 20px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
	background-color: white;
}
.answerInput {
	width: 80%;
	height: 171px;
	border-radius: 20px;
	border: solid 1.5px #626a84;
	background-color: var(--white);
	font-family: "Roboto Geo Nus";
	outline: none;
	padding: 20px;
	font-size: 18px;
	margin-top: 15px;
}
.attachFilescontainer {
	width: 80%;
	display: flex;
	justify-content: flex-end;
}
