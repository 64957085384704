.giveHomework {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: "Roboto Geo Nus";
}
.giveHomeworkToCurrentClass {
	padding: 0 30px;
	width: 270px;
	height: 45px;
	margin: 0 auto;
	border: 1px solid #fff;
	border-width: 2px;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: "Roboto Geo Nus";
	color: white;
	cursor: pointer;
}
@media screen and (max-width: 600px) {
	.giveHomeworkToCurrentClass {
		margin-top: 0px;
		width: 204px;
		padding: 0px;
		font-size: 15px;
		color: white;
		padding: 0px 5px;
		border: none;
	}
}
@media screen and (max-width: 600px) {
	.giveHomeworkToCurrentClass {
		width: 204px;
		border: 1px solid white;
	}
}
.inviteStudentsInHeaderAndUserLogoContainer {
	display: inline-flex;
	align-items: flex-start;
	position: relative;
}
.teacherIcon {
	position: relative;
}
@media screen and (max-width: 1200px) {
	.teacherIcon {
		margin: 0px;
		margin-top: 7px;
	}
}
@media screen and (max-width: 1024px) {
	.teacherIcon {
		margin: 0px;
	}
}
@media screen and (max-width: 500px) {
	.container {
		padding: 0px 2px;
	}
}
@media screen and (max-width: 360px) {
	.container {
		padding: 0px;
	}
}
.containerPlaceholder {
	position: absolute;
	right: 0px;
	top: 50px;
	padding-top: 10px;
	width: 59%;
}
.addHomeworkTvschool {
	border-radius: 9px;
	color: white;
	font-family: "Roboto Geo Caps";
	cursor: pointer;
	transition: 0.2s;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid;
	padding: 4px 6px;
	font-size: 15px;
	margin-top: 13px;
}
.addHomeworkTvschool:hover {
	color: #5273e6;
	background-color: white;
}
@media screen and (max-width: 400px) {
	.addHomeworkTvschool {
		font-size: 13px;
		margin-top: 4px;
	}
}
@media screen and (max-width: 360px) {
	.addHomeworkTvschool {
		font-size: 12px;
		padding: 4px 3px;
	}
}
@media screen and (max-width: 340px) {
	.addHomeworkTvschool {
		font-size: 10px;
		padding: 4px 3px;
	}
}
.tvschoolLogo {
	height: 100%;
	width: 112px;
	margin-top: -2px;
}
@media screen and (max-width: 400px) {
	.tvschoolLogo {
		width: 80px;
	}
}
.videoIcon {
	width: 20px;
	margin-right: 4px;
}
.videoInstructionButton {
	color: white;
	display: flex;
	align-items: center;
	justify-self: center;
	cursor: pointer;
	padding: 4px 6px;
	font-size: 15px;
	margin-top: 13px;
	font-family: "Roboto Geo Caps";
	margin-right: 10px;
	border-bottom: 1px solid transparent;
	transition: 0.2s;
}
.videoInstructionButton:hover {
	border-bottom: 1px solid white;
}
.burgerIconContainer {
	width: 20px;
	margin-left: 15px;
	padding-top: 20px;
	color: white;
}
@media screen and (max-width: 400px) {
	.burgerIconContainer {
		padding-top: 10px;
	}
}
.callsContainer {
	margin-left: 20px;
}
@media screen and (max-width: 930px) {
	.callsContainer {
		display: none;
	}
}
.header {
	background-color: #5273e6;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 70px;
	z-index: 900;
	padding-top: 10px;
	padding-right: 30px;
	padding-bottom: 10px;
	padding-left: 30px;
}
@media screen and (max-width: 544px) {
	.header {
		padding-left: 0px;
		padding-right: 0px;
	}
}
@media screen and (max-width: 400px) {
	.header {
		margin-left: 5px;
	}
}
.logoWithCalls {
	display: flex;
	align-items: center;
	justify-content: center;
}
.mobileVerificationWarning {
	width: 268px;
	border-radius: 20px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	background-color: white;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: center;
	color: #626a84;
	font-family: "Roboto Geo Nus";
	padding: 9px 0px;
	margin-top: 33px;
	position: absolute;
	right: 0;
	top: 49px;
}
@media screen and (max-width: 718px) {
	.mobileVerificationWarning {
		margin-top: 92px;
	}
}
@media screen and (max-width: 600px) {
	.mobileVerificationWarning {
		display: none;
	}
}
.alertIconContainer {
	position: absolute;
	top: -5px;
	right: 0;
	width: 17px;
	height: 17px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	background: #ef6b85;
	justify-content: center;
	color: white;
	cursor: pointer;
}
.schoolselector *,
.schoolselector *::before,
.schoolselector *::after {
	box-sizing: border-box;
}
.singleSchIndicator {
	font-family: "Tatishvili Normal";
	font-size: 30px;
	margin: 0 0 0 2px;
}
.schoolselector {
	margin-left: 8px;
	border-radius: 4px;
	padding: 0 5px;
	font-family: "Tatishvili Normal";
	font-size: 30px;
	height: 34px;
	line-height: 34px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}
@media screen and (max-width: 800px) {
	.schoolselector {
		font-size: 3.75vw;
		margin-left: 0px;
	}
}
.schoolselector.available {
	border: 1px solid;
}
.schoolselector.available:hover {
	cursor: pointer;
	box-shadow: 0 0 10px #719ece;
}
.schoolselectorpopupcontainer {
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.322);
	z-index: 50;
}
.schoolselectorpopupcontainer .popupbox {
	position: relative;
	width: 600px;
	height: 400px;
	border-radius: 20px;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	margin: 0 auto;
	background-color: #ffffff;
}
@media screen and (max-width: 700px) {
	.schoolselectorpopupcontainer .popupbox {
		width: 93%;
		height: 60vh;
	}
}
@media screen and (max-height: 700px) {
}
@media screen and (max-height: 500px) {
	.schoolselectorpopupcontainer .popupbox {
		height: 70vh;
	}
}
@media screen and (max-width: 375px) {
	.schoolselectorpopupcontainer .popupbox {
		height: 70vh;
	}
}
@media screen and (max-width: 360px) {
	.schoolselectorpopupcontainer .popupbox {
		height: 80vh;
	}
}
.schoolselectorpopupcontainer .popupbox .topribbon {
	height: 50px;
}
.schoolselectorpopupcontainer .popupbox .topribbon .closebutton {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 10px !important;
	top: 10px;
	right: 0px;
	width: 30px;
	height: 30px;
	background-color: #f5f5f5;
	border-radius: 30px;
	text-align: center;
}
.schoolselectorpopupcontainer .popupbox .topribbon .closebutton .closeicon {
	position: relative;
}
.schoolselectorpopupcontainer .popupbox .topribbon .closebutton:hover {
	cursor: pointer;
	background-color: #e2e2e2;
}
.schoolselectorpopupcontainer .popupbox .popuptitle {
	font-family: "Roboto Geo MT Bold";
	color: #626a84;
	text-align: center;
	margin-top: 5px;
	margin-bottom: 14px;
	font-size: 18px;
}
@media screen and (max-height: 500px) {
	.schoolselectorpopupcontainer .popupbox .popuptitle {
		margin-top: 1px;
		margin-bottom: 6px;
	}
}
.schoolselectorpopupcontainer .popupbox .schlist {
	width: 80%;
	height: 230px;
	margin: 0 auto;
	box-shadow: 0 0 10px #719ece28;
	border-radius: 5px;
	overflow-y: auto;
	padding-bottom: 24px;
}
@media screen and (max-height: 500px) {
	.schoolselectorpopupcontainer .popupbox .schlist {
		width: 355px;
		height: 140px;
		padding-bottom: 9px;
	}
}
@media screen and (max-width: 700px) {
	.schoolselectorpopupcontainer .popupbox .schlist {
		height: 63%;
		width: 355px;
	}
}
.schoolselectorpopupcontainer .popupbox .schlist .sch {
	border: solid 2px #5272e600;
	width: 200px;
	height: 200px;
	border-radius: 15px;
	background-color: #eff1f7;
	float: left;
	margin: 24px 0 0 24px;
	text-align: center;
	color: #626a84;
	font-family: "Tatishvili Normal";
	font-size: 30px;
	padding: 10px;
	display: table;
}
@media screen and (max-width: 700px) {
	.schoolselectorpopupcontainer .popupbox .schlist .sch {
		font-size: 25px;
		padding: 7px;
		width: 300px;
		height: 150px;
	}
}
@media screen and (max-height: 500px) {
	.schoolselectorpopupcontainer .popupbox .schlist .sch {
		width: 300px;
		height: 100px;
		margin-top: 9px;
		font-size: 23px;
	}
}
@media screen and (max-width: 360px) {
	.schoolselectorpopupcontainer .popupbox .schlist .sch {
		width: 250px;
		height: 100px;
	}
}
.schoolselectorpopupcontainer .popupbox .schlist .sch span {
	display: table-cell;
	vertical-align: middle;
	overflow-wrap: break-word;
	max-width: 180px !important;
}
.schoolselectorpopupcontainer .popupbox .schlist .sch.candidatesch {
	border: solid 2px #5273e6;
}
.schoolselectorpopupcontainer .popupbox .schlist .sch:hover {
	cursor: pointer;
	box-shadow: 0 0 5px #2e8cf0d8;
}
.schoolselectorpopupcontainer .popupbox .schconfirmbutton {
	width: 80%;
	height: 50px;
	margin: auto;
	margin-top: 13px;
	border-radius: 15px;
	color: #5273e6;
	background-color: #eff1f7;
	font-family: "Roboto Geo MT Bold";
	font-size: 18px;
	text-align: center;
	line-height: 50px;
}
@media screen and (max-height: 500px) {
	.schoolselectorpopupcontainer .popupbox .schconfirmbutton {
		height: 30px;
		line-height: 30px;
		width: calc(300px - 4px);
	}
}
@media screen and (max-width: 700px) {
	.schoolselectorpopupcontainer .popupbox .schconfirmbutton {
		width: calc(300px - 4px);
	}
}
@media screen and (max-width: 360px) {
	.schoolselectorpopupcontainer .popupbox .schconfirmbutton {
		width: calc(250px - 4px);
	}
}
.schoolselectorpopupcontainer .popupbox .schconfirmbutton:hover {
	cursor: pointer;
	box-shadow: 0 0 5px #2e8cf0d8;
}
::-webkit-scrollbar {
	background-color: rgba(0, 0, 0, 0.055);
	width: 8px;
	height: 8px;
	border-radius: 50px;
}
::-webkit-scrollbar-track {
	background-color: rgba(255, 255, 255, 0);
}
::-webkit-scrollbar-thumb {
	background-color: #626a8415;
	border-radius: 20px;
	/* border: 1px solid #fff */
}
::-webkit-scrollbar-thumb:hover {
	background-color: rgba(0, 0, 0, 0.13);
	/* border:6px solid #f4f4f4 */
}

