.targetConpetChooserContainer {
	width: 560px;
	margin: 0px 10px;
	border-radius: 20px;
	min-height: 200px;
	margin-top: 18px;
}
.targetConpetChooserContainer,
.thirdStepTargetConpetChooserContainer {
	background-color: #5669a7;
}
.targetConpetChooserHeader {
	margin: 1px 0px 12px 0px;
}
.targetConpetChooserHeader,
.thirdStepTargetConpetChooserHeader {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.targetConpetChooserHeading {
	font-size: 16px;
	margin: 9px 0px 8px 0px;
}
.targetConpetChooserHeading,
.thirdStepTargetConpetChooserHeading {
	text-align: center;
	color: white;
	font-family: "Roboto Geo Nus";
}
.thirdStepTargetConpetChooserContainer {
	width: 300px;
	margin: 0px 10px 0px 0px;
	border-radius: 20px;
	min-height: 100px;
	background-color: #5669a7;
}
.thirdStepTargetConpetChooserContainer .resultsContainer {
	margin: 5px 0px;
}
.thirdStepTargetConpetChooserHeader {
	margin: 9px 0px 8px 0px;
}
.thirdStepTargetConpetChooserHeading {
	margin-bottom: 5px;
}
.addOrDeleteButton {
	width: 35px;
	height: 35px;
}
.topicChooserContainer {
	width: 560px;
	margin: 0px 10px;
	min-height: 200px;
	margin-top: 30px;
}
.topicSelectContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 35px;
	margin-bottom: 15px;
}
.thirdStepTopicChooserContainer,
.topicChooserContainer {
	border-radius: 20px;
	background-color: #5273e6;
}
.thirdStepTopicChooserContainer {
	width: 300px;
}
.thirdStepTopicChooserContainer,
.thirdStepTargetConpetChooserContainer {
	min-height: 93px;
}
