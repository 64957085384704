.itemContainer {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: 2px solid #ccc;
	min-width: 40px;
	height: 40px;
	padding: 5px;
	margin: 8px;
	border-radius: 60px;
	font-size: 17px;
	font-family: "Roboto Geo Nus";
	font-weight: bold;
}
.itemContainer.isAccessible {
	cursor: pointer;
	border-color: #5c5de8;
	color: #5c5de8;
}
.itemContainer.isAccessible:hover {
	box-shadow: rgba(0, 0, 0, 0.2) 0 2px 10px;
}
.itemContainer.hasAnswered {
	background: rgba(92, 93, 232, 0.2);
}
.itemContainer.hasAnsweredFully {
	background: #5c5de8;
	color: white;
}
.itemContainer.isSelected {
	transform: scale(1.2);
	border-width: 2px;
	box-shadow: white 0 0 0 2px inset;
}
.itemContainer.isSelected.isAccessible:hover {
	box-shadow:
		white 0 0 0 2px inset,
		rgba(0, 0, 0, 0.2) 0 2px 10px;
}
.itemContainer.hasAnsweredCorreclty {
	border-color: #50dd39;
	background: #50dd39;
	color: white;
}
.itemContainer.hasAnsweredPartiallyCorreclty {
	border-color: #ffc400;
	background: #ffc400;
	color: white;
}
.itemContainer.hasAnsweredIncorreclty {
	border-color: #f64848;
	background: #f64848;
	color: white;
}
.itemContainer.hasAnswered.isNotAccessible {
	border-color: #5f7a85;
	background: #5f7a85;
	color: white;
}
.itemContainer.containsGradableItemsByEditor.hasAnswered {
	border-color: #b5c017;
	background: #b5c017;
	color: white;
}
.itemContainer.finishItem.isAccessible {
	background: #5c5de8;
	color: white;
}
.itemContainer.finishItem.isNotAccessible {
	color: #ccc;
}
.finishItem > *,
.startItem > * {
	display: inline-flex;
}
.outerContainer.isFinished .finishItem,
.outerContainer.isFinished .startItem {
	color: #00a1ff;
}
:global(.rest-container-of-page) {
	position: relative;
	min-height: calc(100vh - 65px);
}
.coveredLessonsButton {
	background-color: #445eea;
	font-family: "Roboto Geo Caps";
	color: white;
	cursor: pointer;
	outline: none;
	border: none;
	height: 45px;
	width: 132px;
	border-radius: 14px;
	margin: 2px 10px;
}
.coveredLessonsButton:hover {
	background-color: #304cee;
}
.containerCSS {
	font-family: "Roboto Geo Nus";
	padding: 55px 20px 0px 13px;
}
.containerForSmallHotsaleCSS {
	font-family: "Roboto Geo Nus";
	margin: 0 auto;
}
@media screen and (min-width: 1000px) {
	.containerForSmallHotsaleCSS {
		width: calc(100% - 337px);
		margin: 0 0 0 21px;
	}
}
@media screen and (max-width: 1000px) {
	.containerForSmallHotsaleCSS {
		padding: 55px 20px 0px 13px;
	}
}
.containerForPalitraCSS {
	font-family: "Roboto Geo Nus";
	padding-top: 20px;
	position: relative;
	z-index: 1;
	width: calc(100% - 800px);
	top: 150px;
	margin: 0px auto;
}
.containerForPalitraCSS > div {
	-moz-transform: none;
	-webkit-transform: none;
	-o-transform: none;
	-ms-transform: none;
	transform: none;
}
@media screen and (min-width: 1200px) {
	.containerForPalitraCSS {
		width: calc(100% - 450px);
	}
}
@media screen and (min-width: 2200px) {
	.containerForPalitraCSS {
		top: 220px;
	}
}
@media screen and (min-width: 1600px) {
	.containerForPalitraCSS {
		width: calc(100% - 120px);
	}
}
@media screen and (max-width: 1600px) {
	.containerForPalitraCSS {
		width: calc(100% - 240px);
	}
}
@media screen and (max-width: 1200px) {
	.containerForPalitraCSS {
		font-family: "Roboto Geo Nus";
		padding: 50px 20px 0px 13px;
		position: absolute;
		z-index: 1;
		top: 0px;
		width: 100%;
		left: 0;
	}
}
.containerForHotsaleCSS {
	font-family: "Roboto Geo Nus";
}
@media screen and (min-width: 1001px) {
	.containerForHotsaleCSS {
		padding-top: 20px;
		position: absolute;
		z-index: 1;
		background-color: white;
		width: calc(100% - 300px);
		left: 0;
		padding: 55px 20px 20px 53px;
	}
}
@media screen and (min-width: 1100px) {
	.containerForHotsaleCSS {
		width: calc(100% - 320px);
		top: 0px;
	}
}
@media screen and (min-width: 1200px) {
	.containerForHotsaleCSS {
		width: calc(100% - 350px);
		top: 0px;
	}
}
@media screen and (min-width: 1300px) {
	.containerForHotsaleCSS {
		width: calc(100% - 400px);
		top: 0px;
	}
}
@media screen and (min-width: 1400px) {
	.containerForHotsaleCSS {
		width: calc(100% - 420px);
		top: 0px;
	}
}
@media screen and (min-width: 1600px) {
	.containerForHotsaleCSS {
		width: calc(100% - 500px);
		top: 0px;
	}
}
@media screen and (min-width: 1900px) {
	.containerForHotsaleCSS {
		width: calc(100% - 650px);
	}
}
@media screen and (max-width: 1000px) {
	.containerForHotsaleCSS {
		font-family: "Roboto Geo Nus";
		padding: 55px 20px 0px 13px;
	}
}
