.st-assignment-top {
	display: flex;
	color: white;
	justify-content: center;
	align-items: center;
	padding-top: 30px;
	width: 80%;
	margin: 0 auto;

	.subject_icon {
		width: 100px;
		height: 110px;
		margin: 0 30px 0 0;
		filter: brightness(0) invert(1);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	}

	.right {
		display: flex;
		flex-direction: column;
		font-family: "Roboto Geo MT Bold";

		.subject_title {
			font-family: "Roboto Geo Caps";
			font-weight: normal;
			font-size: 18px;
			margin: 0 0 20px;
			padding: 0;
		}

		.subject_theme_title {
			font-family: "Roboto Geo MT Bold";
			font-size: 24px;
			margin: 0;
			padding: 0;
		}
	}
}

.subject_infobox_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: -20px;

	.subject_info_box {
		width: 300px;
		border-right: 1px solid #58607c;

		&:last-child {
			border-right: initial;
		}
	}

	.subject_info_box__title {
		font-family: "Roboto Geo Nus";
		font-size: 12px;
		color: #58607c;
		margin: 0 0 10px;

		height: 30px;

		display: flex;
		justify-content: center;
		align-items: center;

		& > span {
			margin-right: 10px;
			height: 20px;
			background-color: #58607c;
		}

		& > svg {
			margin-right: 5px;
			height: 18px;
		}
	}

	.subject_info_box__content_time {
		width: 200px;
		font-size: 20px;
		margin: 0 auto;
		text-align: right;
		color: #5273e6;
		font-family: "Roboto Geo MT Bold";

		& > span {
			display: block;
			font-family: "Roboto Geo Nus";
			color: #58607c;
			font-size: 10px;
			text-align: right;
		}
	}

	.infoBoxTimer {
		width: 69px;
		margin: 0 auto;
		margin-top: -10px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.time {
			font-family: "Robot Geo Nus";
			font-size: 23px;
			font-weight: bold;
			color: #5273e6;
		}
		.titles {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			.title {
				font-family: "Roboto Geo Nus";
				font-size: 10px;
				color: #58607c;
			}
		}
	}

	.subject_info_box__content_questions {
		color: #5273e6;
		text-align: center;
		font-size: 30px;
		font-family: "Roboto Geo MT Bold";
	}

	.subject_info_box__content_author {
		color: #5273e6;
		font-size: 20px;
		text-align: center;
		font-family: "Roboto Geo MT Bold";
	}
}

.tasks {
	margin: 40px auto 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 10px;

	&_info {
		font-family: "Roboto Geo MT Bold";
		font-size: 14px;
		color: #626a84;
		text-align: center;
	}

	&_input {
		width: 500px;
		height: 60px;
		border-radius: 20px;
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
		text-align: center;
		padding: 0 20px;
		font-family: "Roboto Geo Nus";
		margin: 15px auto 20px;
		border: none;

		&:focus {
			outline: none;
		}
	}

	&_button {
		border-radius: 14px;
		background-color: #5273e6;
		color: white;
		font-family: "Roboto Geo Caps";
		text-align: center;
		width: 250px;
		height: 40px;
		border: none;
		font-size: 17px;
		margin: 20px 0;
		outline: none;
		cursor: pointer;
		transition: all 0.2s;

		&:hover {
			box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
		}
	}
}

// Responsive
@media screen and (max-width: 1200px) {
	.st-assignment-top {
		width: 90%;
		margin: 0 auto;
	}
}

@media screen and (max-width: 700px) {
	.st-assignment-top {
		width: 80%;

		.subject_icon {
			width: 80px;
			height: 90px;
			margin: 0 20px 0 0;
		}

		.right {
			.subject_title {
				font-size: 14px;
				margin: 0 0 15px;
			}

			.subject_theme_title {
				font-size: 16px;
			}
		}
	}

	.subject_infobox_wrapper {
		width: 80%;
		flex-wrap: wrap;
		margin: 0 auto;

		border: 1px solid #e1e1e1;
		border-radius: 10px;
		padding: 20px;

		.subject_info_box {
			border-right: initial;
			width: 50%;
			height: 60px;

			&:first-child {
				border-right: 1px solid #e1e1e1;
			}

			// &:last-child {
			// 	width: 100%;
			// 	margin-top: 30px;
			// }
			&:nth-of-type(3) {
				width: 100%;
				margin-top: 30px;
			}
		}

		.subject_info_box__title {
			font-size: 12px;

			display: flex;
			justify-content: center;
			align-items: center;

			& > span {
				margin-right: 10px;
				height: 20px;
			}
		}

		.subject_info_box__content_time {
			width: 120px;
			font-size: 16px;
			margin: 0 auto;
			text-align: right;

			& > span {
				display: block;
				font-family: "Roboto Geo Nus";
				color: #58607c;
				font-size: 10px;
				text-align: right;
			}
		}

		.subject_info_box__content_questions {
			color: #5273e6;
			text-align: center;
			font-size: 30px;
			font-family: "Roboto Geo MT Bold";
		}

		.subject_info_box__content_author {
			color: #5273e6;
			font-size: 18px;
			text-align: center;
			font-family: "Roboto Geo MT Bold";
		}
	}

	.tasks {
		margin: 30px auto 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		// width: 80%;

		&_info {
			font-family: "Roboto Geo MT Bold";
			font-size: 14px;
			color: #626a84;
			text-align: center;
		}

		&_input {
			width: 100%;
			height: 45px;
		}

		&_button {
			width: 200px;
			height: 40px;
			font-size: 13px;
			margin: 10px 0;
		}
	}
}

@media screen and (max-width: 550px) {
	.subject_info_box__title {
		height: 30px;
		font-size: 10px;
	}

	.subject_info_box:not(:nth-of-type(3)) .subject_info_box__title {
		text-align: center;
		padding: 0 20px;
	}
}

@media screen and (max-width: 440px) {
	.subject_infobox_wrapper {
		width: 100%;
	}

	.subject_info_box__title {
		font-size: 10px;
	}

	.subject_info_box:not(:nth-of-type(3)) .subject_info_box__title {
		padding: 0 10px;
	}

	.subject_infobox_wrapper {
		.subject_info_box__content_time {
			width: 100%;
			font-size: 12px;
			padding-right: 30px;

			& > span {
				font-size: 8px;
			}
		}

		.subject_info_box__content_questions {
			font-size: 16px;
		}

		.subject_info_box__content_author {
			font-size: 14px;
		}
	}
}
@media screen and (max-width: 375px) {
	.subject_infobox_wrapper {
		.subject_info_box__content_time {
			padding-right: 15px;
		}
	}
}

.halfmoon-container {
	min-height: 170px;
	margin-top: -25px;
	@media screen and (max-width: 600px) {
		min-height: 140px;
	}
}

.incorrectCode {
	font-size: 22px;
	font-family: "Roboto Geo Nus";
	margin-top: 50px;
	width: 100%;
	text-align: center;
	color: #595454;
}
