.subjectBoxContainer {
	display: flex;
	justify-content: center;
}
.subjectDescription {
	width: 550px;
	border-radius: 20px;
	display: flex;
	padding: 16px 0px;
	margin: 0px 10px;
	justify-content: center;
	align-items: center;
	background-color: #f9f9f9;
	text-align: center;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	font-family: "Roboto Geo Caps";
	transition: 0.3s;
}
.subjectDescription .subjectAvatar {
	width: 80px;
	height: 80px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	margin-right: 10px;
}
.subjectDescription .subjectName {
	font-weight: bold;
	color: #5273e6;
	font-size: 19px;
	padding-bottom: 10px;
	display: block;
	margin-bottom: 5px;
}
.subjectDescription .subjectGrade {
	font-size: 16px;
	color: #5273e6;
}
.subjectDescription .description {
	display: flex;
	flex-direction: column;
}
.flexCenterContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}
.selectsContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 7px 0;
}
.deleteContainer {
	width: 50px;
	height: 50px;
	border-radius: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	cursor: pointer;
}
.plusContainer {
	width: 50px;
	height: 50px;
	border-radius: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	margin-left: 10px;
	cursor: pointer;
}
.resultsContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 7px 0px 7px;
}
.resultsText {
	font-family: "Roboto Geo Nus";
	font-size: 16px;
	color: white;
	margin-bottom: 8px;
}
.resultsBox {
	width: 26px;
	height: 26px;
	border-radius: 8px;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "Roboto Geo MT Bold";
	font-size: 16px;
	line-height: 1.19;
	color: #4e5468;
	margin-right: 4px;
}
.nextButtonContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 22px;
}
.nextButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 260px;
	height: 60px;
	border-radius: 15px;
	background-color: #5273e6;
	font-family: "Roboto Geo MT Bold";
	color: white;
	cursor: pointer;
}
.breakLine {
	height: 1px;
	width: 100%;
	background-color: #b2bfeb;
	margin: 0;
	padding: 0;
	margin-bottom: 10px;
}
.menuHeaderStyle {
	font-size: 16px;
	padding: 12px;
	color: #5273e6;
	cursor: pointer;
	font-family: "Roboto Geo Caps";
}
.delteButton .st0 {
	fill: "red";
}
