.mainBody {
	max-width: 1100px;
	width: 100%;
	margin: 0 auto;
}

.sendAssignmentContainer {
	display: flex;
	justify-content: center;
	min-height: 40px;
	position: relative;
}

$transitionTime: 0.6s;

.button {
	font-family: "Roboto Geo MT Bold";
	z-index: 20;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	outline: none;
	border: none;

	&.transforming {
		transition:
			all $transitionTime,
			left 0s,
			top 0s,
			height 0s,
			width 0s;
	}
}

.sentIcon {
	transform: rotate(-25deg);
}

.word {
	margin-right: 5px;
}

.workTimeSettingsDiv {
	// display: none;
	box-sizing: border-box;
	.workTimeSettingsButton {
		width: 280px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		border-radius: 20px;
		border: solid 1px #707070;

		margin-bottom: 10px;

		line-height: 50px;

		font-family: "Roboto Geo MT Bold";
		color: #626a84;
		font-size: 20px;

		&:hover {
			cursor: pointer;
			box-shadow: 0px 0px 4px 2px rgb(102, 181, 255);
			border: 1px solid white;
		}

		.settingsIcon {
			width: 25px;
			margin-right: 8px;
			// transform: translate(0, 5px);
			// left: 100%;
			// top: 50px;
			// transform: translate(100%, 0);
		}
	}
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

.workTimeSettingsPopup_background {
	box-sizing: border-box;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 150;
	background-color: #00000054;
	overflow-y: scroll;
	padding-bottom: 5px;

	.workTimeSettingsPopup_box {
		position: absolute;
		width: 760px;
		height: 600px;
		border-radius: 20px;
		background-color: white;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin-bottom: 5px;

		@media screen and (max-height: 700px), (max-width: 800px) {
			transform: translate(calc(-50% + 4px), 0);
			top: 10px;
		}

		@media screen and (max-width: 800px) {
			height: 600px + 250px;

			width: 95vw;
		}

		.popupTopRibbon {
			position: relative;
			height: 50px;
			// border: 1px solid red;

			.closeButton {
				position: absolute;
				right: 9.5px;
				top: 9.5px;
				width: 31px;
				height: 31px;
				background-color: rgb(241, 241, 241);
				border-radius: 50px;
				text-align: center;

				&:hover {
					cursor: pointer;
					background-color: rgb(224, 224, 224);
				}

				.closeIcon {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					height: 15px;
					width: 15px;
					fill: #626a84;
				}
			}
		}

		.popupTitle {
			font-family: "Roboto Geo MT Bold";
			text-align: center;
			font-size: 20px;
			color: #626a84;
			margin-bottom: 30px;
		}

		.popupContent {
			height: 440px;
			margin-top: 5px;
			// border: 1px solid blue;
			padding: 7px;
			// overflow-y: auto;

			@media screen and (max-width: 800px) {
				height: 440px + 250px;
			}

			.indicatorOfDisabled {
				position: absolute;
				width: 100%;
				height: 100%;
				border-radius: 7px;
				top: 0;
				left: 0;
				z-index: 240;
				background-color: #ffffff9f;
				box-shadow: inset 0px 0px 129px -76px rgba(0, 0, 0, 0);

				&.dlIsEnabled {
					display: none;
				}

				&.durIsEnabled {
					display: none;
				}
			}

			.reactSwitch {
				margin-left: 20px;
				transform: translate(0, 4px);
			}
		}

		.confirmButton {
			position: absolute;
			bottom: 10px;
			left: 50%;
			transform: translate(-50%, 0);
			width: 300px;
			height: 50px;
			line-height: 50px;
			border-radius: 15px;
			background-color: #eff1f7;

			font-family: "Roboto Geo MT Bold";
			font-size: 18px;
			text-align: center;
			color: #626a84;
			justify-content: center;
			align-items: center;
			display: flex;

			&:hover {
				cursor: pointer;
				box-shadow: 0px 0px 4px 2px rgb(102, 181, 255);
			}
		}
	}
}

.deadlineDayHourMinute {
	// border: 1px solid green;
	height: 430px;
	float: left;
	padding-right: 10px;
	padding-left: 10px;

	@media screen and (max-width: 800px) {
		float: initial;
		display: block;
		height: 430px + 100px;
	}

	.deadlineTitle {
		text-align: center;
		font-family: "Roboto Geo Nus Bold";
		font-size: 18px;
		color: #626a84;
		margin-bottom: 7px;
	}

	.deadlineIndicator {
		position: relative;
		height: 385px;
		padding-left: 5px;
		padding-right: 5px;
		padding-top: 10px;
		@media screen and (max-width: 800px) {
			height: 385px + 100px;
		}

		.reactModernCalendarDatepicker {
			position: relative;
			float: left;
			font-family: "Open Sans";
			font-weight: 400;
			@media screen and (max-height: 800px) {
				// transform: translate(0, -220px);
				// opacity: 0.5;
			}

			@media screen and (max-width: 800px) {
				float: initial;
				display: block;
				// margin: 0 auto;
				left: 50%;
				transform: translate(-50%, 0);
			}
		}

		.deadlineHourMinute {
			position: relative;
			// top: 110px;
			margin-left: 20px;
			float: left;
			font-family: arial;
			// border: 1px solid blue;
			height: 100px;
			width: 162px;
			padding: 5px;

			@media screen and (max-width: 800px) {
				float: initial;
				display: block;
				margin: auto;
			}
		}
	}
}

.workDurationLimit {
	position: relative;
	// border: 1px solid yellow;
	margin-left: 30px;
	float: left;
	// top: 110px;
	height: 145px;
	width: 161px;

	@media screen and (max-width: 800px) {
		float: initial;
		display: block;
		margin: 0 auto;
	}

	.titleOfLimit {
		text-align: center;
		display: block;
		font-family: "Roboto Geo Nus Bold";
		font-size: 18px;
		color: #626a84;
		margin-bottom: 7px;
	}

	.workDurationIndicator {
		position: relative;
		// border: 1px solid burlywood;
		height: 100px;
		padding-left: 5px;
		padding-right: 5px;
		padding-top: 10px;
		margin-top: 12px;
	}
}

.static,
.floating.transforming {
	.words {
		font-size: 16px;
	}

	&.button {
		color: white;
		background: #5273e6;
		font-size: 19px;
		border-radius: 13px;
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
		padding: 8px 35px;
		&:hover {
			box-shadow: -3px 5px 10px 0 rgba(82, 115, 230, 0.5);
		}
	}
}

.static {
	.word {
		margin-right: 5px;
	}

	.sentIcon {
		margin-right: 20px;
		svg {
			width: 30px;
		}
	}
}

.floating,
.static.transforming {
	.words {
		font-size: 15px;
	}

	&.button {
		color: #5273e6;
		background: white;
		font-size: 19px;
		border-radius: 13px;
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
		padding: 20px 15px;

		&:hover {
			background: #5273e6;
			color: white;
		}
	}
}

.floating {
	.words {
		display: flex;
		flex-direction: column;
		padding-bottom: 20px;
	}

	.sentIcon {
		svg {
			width: 50px;
		}
	}

	&.button {
		flex-direction: column-reverse;
		border-radius: 13px;
		position: fixed;
		right: 10px;
		top: 90px;
	}
}

.heading {
	display: flex;
	justify-content: space-between;
	font-family: Roboto_GEO Mt;
	color: #626a84;
	align-items: center;
	margin-bottom: 20px;

	.title {
		flex: 1;
		font-family: "Roboto Geo MT Bold";
		text-align: left;
		font-size: 20px;
	}

	.numOfQuestions {
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: "Roboto Geo Nus";
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		input[type="number"] {
			-moz-appearance: textfield;
		}
		.questionInputContainer {
			display: flex;
			flex-direction: column;
			align-items: center;

			margin-left: 5px;
			.countInput {
				width: 58px;
				height: 41px;
				border: none;
				font-size: 32px;
				font-weight: bold;
				text-align: center;
				outline: none;
				color: #5273e6;
				margin-left: 10px;
				background: transparent;
			}
		}
		.saveButton {
			position: absolute;
			margin-top: 46px;
			font-size: 18px;
			cursor: pointer;
			font-weight: bold;
			color: #5273e6;
			font-family: "Roboto Geo Caps";
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
		.countError {
			font-family: "Roboto Geo Nus";
			font-size: 16px;
			color: #ef6b85;
			position: absolute;
			margin-top: 48px;
			text-align: center;
		}
	}

	@media screen and (max-width: 800px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.title {
			font-size: 16px;
			text-align: center;
		}
		.questionCounter {
			font-size: 28px;
		}
	}
}

.questionCounter {
	font-size: 32px;
	font-weight: bold;
	text-align: center;
	color: #5273e6;
	margin-left: 10px;
}

.editTitle {
	font-size: 18px;
	text-align: center;
	font-family: "Roboto Geo MT Bold";
	color: #5273e6;
	margin: 0;
}

.closeContainer {
	text-align: right;
	color: #626a84;
	margin-bottom: 10px;
	.close {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		font-family: "Roboto Geo Caps";
		cursor: pointer;
		padding: 5px 0 5px 10px;
	}
}

.qEditHeader {
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;

	@media screen and (max-width: 1400px) {
		// margin-top: 200px;
	}

	@media screen and (max-width: 600px) {
		flex-direction: column-reverse;

		.editTitle {
			margin-bottom: 10px;
		}
	}
}

@media screen and (max-width: 1200px) {
	.mainContainer {
		padding-bottom: 80px;
	}
}

.approvedPopup {
	padding-bottom: 2px;
}

.openFromBottom {
	animation: openFromBottom 0.3s;
}

@keyframes openFromBottom {
	0% {
		margin-top: 100vh;
	}
	100% {
		margin-top: 80px;
	}
}
